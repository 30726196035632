import { isEmpty, reduce, values } from 'lodash'
import { apiRequest, ApiRequestAction } from '../actions/api'
import { setError as setDocumentError, SetErrorAction } from '../actions/document'
import { AnyFieldAction, FieldActionType } from '../actions/field'
import { Middleware } from '../store'

type HandleActions = AnyFieldAction
type DispatchActions = ApiRequestAction | SetErrorAction

export const fieldMiddleware: Middleware<HandleActions, DispatchActions> = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  const state = getState()

  switch (action.type) {
    case FieldActionType.VALIDATE: {
      const { resource, field, value } = action.payload
      const trunkID = state?.session?.organization?.trunkID ?? undefined
      const orgID = state?.session?.organization?.ID ?? undefined
      let url = `/schemas/${resource}/validate/${field}`

      if (trunkID) {
        url += `/${trunkID}`

        if (orgID) {
          url += `/${orgID}`
        }
      }

      dispatch(
        apiRequest({
          url,
          method: 'POST',
          body: value,
          feature: FieldActionType.VALIDATE,
        })
      )
      break
    }

    case FieldActionType.VALIDATE_SUCCESS: {
      const { field: fieldSchema } = action.payload

      switch (fieldSchema.primitive) {
        case 'joinString': {
          // reset all join field errs
          const payload = reduce(
            fieldSchema?.autoComplete?.set ?? [],
            (errs, setInstruction) => ({
              ...errs,
              [setInstruction.to]: undefined,
            }),
            {
              [fieldSchema.key]: undefined,
            }
          )
          dispatch(setDocumentError(payload))
          break
        }

        case 'object': {
          // reset all object field errs
          const errs = reduce(
            fieldSchema.fields,
            (errs: any, field: any) => ({
              ...errs,
              [field.key]: undefined,
            }),
            {}
          )

          const payload = {
            // if every field err is undefined, unset the entire object key
            [fieldSchema.key]: values(errs).every(isEmpty) ? undefined : errs,
          }

          dispatch(setDocumentError(payload))
          break
        }

        default:
          dispatch(
            setDocumentError({
              [fieldSchema.key]: undefined,
            })
          )
      }
      break
    }

    case FieldActionType.VALIDATE_ERROR:
      dispatch(setDocumentError(action?.payload?._inputs ?? []))
      break
  }
}
