import { Settings } from '../types'
import config from '../config'
import { SessionState } from '../reducers/session'

export async function fetchPlatformSettings(session: SessionState): Promise<Settings> {
  if (session?.authorizationToken) {
    return fetch(
      `${config.api ?? 'MISSING API URL'}/organizations/${session.organization?.trunkID}/${session.organization?.trunkID}/platformSettings`,
      {
        headers: {
          Authorization: `${session.authorizationToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((response) => response?.data[0])
      .catch(() => ({}))
  }

  return {} as Settings
}
