import _ from 'lodash'
import { AppState } from '../store'
import { categorySelector } from './location'
import { Schema } from '../schemas'
import { Category } from '../types/Schema'

const parseCategories = (schemas: Schema[]) => (schemas || []).reduce((ac: Category[], cur) => ac.concat(cur.categories || []), [])

// return the first schema in the list as the default schema, used as default landing page after login
export const defaultSchemaSelector = (state: AppState): string => {
  return state?.schemas[0]?.key ?? ''
}

export const schemasSelector = (state: AppState, key: string): Schema => {
  return state?.schemas.find((schema: Schema) => schema.key === key) ?? ({} as Schema)
}

// returns true if provided category is in a list of parsed categories derived from the available schemas
export const isCategoryAvailableSelector = (state: AppState, category: string): boolean =>
  !!parseCategories(state.schemas).find((cat: Category) => cat.key === category)

export const moduleSelector = (state: AppState): Category | undefined => {
  const categories = parseCategories(state.schemas)
  const catKey = categorySelector(state)
  const category = _.find(categories, (c) => c.key === catKey)
  return category || categories[0]
}
