import { AppState } from '../store'

export const documentListSelector = (state: AppState): Array<any> => {
  return state?.documents?.list || []
}

export const documentCountSelector = (state: AppState): number => {
  return state?.documents?.resultCount
}

export const countSelector = (state: AppState): any => {
  return state?.documents?.count ?? -1
}
