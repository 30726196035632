import _ from 'lodash'
import { clearNotification, CREATE_NOTIFICATION } from '../actions/ui'
import { Middleware } from '../store'

export const uiMiddleware: Middleware = ({ dispatch }) => (next) => (action) => {
  next(action)

  switch (action.type) {
    case CREATE_NOTIFICATION:
      setTimeout(() => {
        dispatch(clearNotification())
      }, _.get(action, 'payload.autoHideDuration', 2500))
      break
  }
}
