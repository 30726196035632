import { createSelector } from 'reselect'
import { AppState } from '../store'
import { Enum, Option } from '../types'
import { enumToOptionEnum } from '../util/enums'

export const autocompleteSelector = createSelector(
  (state: AppState) => state && state.autocomplete,
  (autocomplete: any): Option[] => autocomplete.options || []
)

export const assignmentsSelector = (state: AppState): Enum[] => state.autocomplete.assignments ?? []

export const selectAssignmentOptions = (state: AppState): Option[] => {
  return enumToOptionEnum(assignmentsSelector(state))
}
