import { V3Organization } from '../types'

export const V3Organizations = '[v3Organizations]'

export enum V3OrganizationActionTypes {
  GET_ORGANIZATIONS = '[v3Organizations] GET_ORGANIZATIONS',
  GET_ORGANIZATION = '[v3Organizations] GET_ORGANIZATION',
  SET_ORGANIZATIONS = '[v3Organizations] SET_ORGANIZATIONS',
  SET_ORGANIZATION = '[v3Organizations] SET_ORGANIZATION',
  GET_DOWNSTREAM_ORGS = '[v3Organizations] GET_DOWNSTREAM_ORGS',
  GET_UPSTREAM_ORGS = '[v3Organizations] GET_UPSTREAM_ORGS',
  GET_ORG_HIERARCHY = '[v3Organizations] GET_ORG_HIERARCHY',
}

interface GetV3OrganizationsAction {
  type: V3OrganizationActionTypes.GET_ORGANIZATIONS
}

export const getV3Organizations = (): GetV3OrganizationsAction => ({
  type: V3OrganizationActionTypes.GET_ORGANIZATIONS,
})

interface GetV3OrganizationAction {
  type: V3OrganizationActionTypes.GET_ORGANIZATION
  payload: { id: string }
}

export const getV3Organization = (id: string): GetV3OrganizationAction => ({
  type: V3OrganizationActionTypes.GET_ORGANIZATION,
  payload: {
    id,
  },
})

interface GetV3DownstreamOrgsAction {
  type: V3OrganizationActionTypes.GET_DOWNSTREAM_ORGS
  payload: { id: string }
}

export const getV3DownstreamOrgs = (id: string): GetV3DownstreamOrgsAction => ({
  type: V3OrganizationActionTypes.GET_DOWNSTREAM_ORGS,
  payload: {
    id,
  },
})

interface GetV3UpstreamOrgsAction {
  type: V3OrganizationActionTypes.GET_UPSTREAM_ORGS
  payload: { id: string }
}

export const getV3UpstreamOrgs = (id: string): GetV3UpstreamOrgsAction => ({
  type: V3OrganizationActionTypes.GET_UPSTREAM_ORGS,
  payload: {
    id,
  },
})

interface SetV3OrganizationsAction {
  type: V3OrganizationActionTypes.SET_ORGANIZATIONS
  payload: V3Organization[]
}

export const setV3Organizations = (payload: V3Organization[]): SetV3OrganizationsAction => ({
  type: V3OrganizationActionTypes.SET_ORGANIZATIONS,
  payload,
})

interface SetV3OrganizationAction {
  type: V3OrganizationActionTypes.SET_ORGANIZATION
  payload: V3Organization
}

export const setV3Organization = (payload: V3Organization): SetV3OrganizationAction => ({
  type: V3OrganizationActionTypes.SET_ORGANIZATION,
  payload,
})

interface GetV3OrgHierarchyAction {
  type: V3OrganizationActionTypes.GET_ORG_HIERARCHY
}

export const getV3OrgHierarchy = (): GetV3OrgHierarchyAction => ({
  type: V3OrganizationActionTypes.GET_ORG_HIERARCHY,
})

export type V3OrganizationActions =
  | GetV3OrganizationsAction
  | GetV3OrganizationAction
  | GetV3DownstreamOrgsAction
  | GetV3UpstreamOrgsAction
  | GetV3OrgHierarchyAction
  | SetV3OrganizationsAction
  | SetV3OrganizationAction
