import { apiRequest, API_SUCCESS } from '../actions/api'
import { GET_ORGS, ORGS, setOrgs } from '../actions/organization'
import { Middleware } from '../store'

export const orgsMiddelware: Middleware = ({ dispatch }) => (next) => (action) => {
  next(action)

  switch (action.type) {
    case GET_ORGS:
      dispatch(
        apiRequest({
          url: `/context?namePrefix=${action.payload}`,
          method: 'GET',
          feature: ORGS,
        })
      )
      break

    case `${ORGS} ${API_SUCCESS}`:
      dispatch(setOrgs(action.payload.data))
      break
  }
}
