import { SessionState } from 'reducers/session'
import config from '../config'
import { Settings } from '../types'
import { getLocalStorage } from './localStorage'

declare global {
  interface Window {
    // Defined by apps/admin/public/deps.x.x.x.js
    PCTracking: typeof PCTracking
  }
}

/**
 * TODO expand this from the source. Maybe rip it from the lib that pc clickstream was forked from.
 */
export declare class PCTracking {
  static client?: PCTracking
  constructor(opts: { host?: string })
  static ready(fn: () => void): void
  static listenTo(what: Record<string, (e: Event) => void>): void
  static helpers: {
    getDomNodePath(e?: EventTarget | null): string
    getBrowserProfile(): {}
    getDomNodeProfile(el: Element): {}
    getUniqueId(): string
  }
  static utils: {
    timer(): {
      value(): number
    }
  }
  extendEvents(fn: (a: any, b: any) => {}): void
  recordEvent(name: string, meta: {}, fn?: (err?: {}, res?: {}) => void): void
  initAutoTracking(opts: {}): void
}

// The time that the app was first rendered
const pageLoadTime = new Date()
// The time since the last 'pageviews' event was invoked. Should be reset manually whenever there's a page change
let lastPageChange = new Date()

/**
 * Enable PC Clickstream Tracking, listen to global events, and set default tracking data
 * Additional usage docs here: https://github.com/tampajohn/keen-tracking.js
 */
export function initTracking() {
  PCTracking.ready(() => {
    PCTracking.client = new PCTracking({
      host: 'pc.clickstream.events',
    })

    // Collect all button click events
    PCTracking.listenTo({
      // Just button clicks for now. Listening to `a` clicks causes the whole app to reload every time the user clicks a sidenav link.
      'click button, button *': (e) => {
        const target = e.target as Element
        PCTracking.client?.recordEvent('click', {
          element: PCTracking.helpers.getDomNodeProfile(target),
        })
      },
    })

    PCTracking?.client?.extendEvents(trackingData)
  })
}

/**
 * Invoke this whenever a route changes to track it in PC Clickstream
 */
export function trackPageView() {
  // Wrap in a ready() because sometimes this function is invoked before PCTracking is done setting itself up
  window.PCTracking.ready(() => {
    window.PCTracking.client?.recordEvent('pageviews', {}, (err) => {
      if (!err) {
        // Reset the 'time on page' counter
        lastPageChange = new Date()
      }
    })
  })
}

export function trackEvent(name: string, payload?: {}) {
  window.PCTracking.ready(() => {
    window.PCTracking.client?.recordEvent(name, payload ?? {})
  })
}

/**
 * trackingData injects platformSettings feature flags into default tracking data sent to pc click stream
 */
export function trackingData(platformSettings?: Settings): any {
  const loadId = PCTracking.helpers.getUniqueId()

  const browserProfile: { description?: string } = PCTracking.helpers.getBrowserProfile()
  const timeOnPageMs = new Date().getTime() - lastPageChange.getTime()
  const data = {
    app: 'Command Center',
    build: config.build,
    local_time_full: new Date().toISOString(),
    featureFlags: {
      analyticsSettings: platformSettings?.analyticsSettings,
      enableV3: platformSettings?.enableV3,
      enableFocalPoint: platformSettings?.enableFocalPoint,
    },
    locale: getLocalStorage(config.languageKey),
    url: { full: window.location.href, host: window.location.hostname, path: window.location.pathname, qs: window.location.search },
    tech: { profile: browserProfile },
    user: {},
    organization: {},
    page: {
      load_id: loadId,
      title: document ? document.title : null,
      description: browserProfile.description,
      time_on_page: Math.round(timeOnPageMs / 1000),
      time_on_page_ms: timeOnPageMs,
      time_since_first_load: new Date().getTime() - pageLoadTime.getTime(),
    },
  }

  const session: SessionState | null = getLocalStorage(config.sessionKey)
  if (session) {
    data.user = { ...session.user, uuid: session.user.ID }
    data.organization = session.organization
  }

  return data
}
