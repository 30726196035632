import { GetOptionsArgs, ReferenceField } from '../types/ReferenceField'
import { Enum, Option, Organization } from '../types'
import { Schema } from '../schemas'

export enum AutocompleteActionType {
  GET_AUTOCOMPLETE = '[autocomplete] Fetch',
  GET_AUTOCOMPLETE_SUCCESS = '[autocomplete] Fetch API_SUCCESS',
  SET_AUTOCOMPLETE = '[autocomplete] Set',
  GET_ASSIGNMENTS = '[autocomplete] Fetch Assignments',
  GET_ASSIGNMENTS_SUCCESS = '[autocomplete] Fetch Assignments API_SUCCESS',
  SET_ASSIGNMENTS = '[autocomplete] Set Assignments',
}

interface GetAutoCompleteAction {
  type: AutocompleteActionType.GET_AUTOCOMPLETE
  payload: {
    field: ReferenceField
    params: GetOptionsArgs
  }
}

export const getAutoComplete = (field: ReferenceField, params: GetOptionsArgs): GetAutoCompleteAction => ({
  type: AutocompleteActionType.GET_AUTOCOMPLETE,
  payload: {
    field,
    params,
  },
})

interface GetAutocompleteSuccessAction {
  type: AutocompleteActionType.GET_AUTOCOMPLETE_SUCCESS
  payload: Schema[] | Organization[]
}

interface SetAutoCompleteAction {
  type: AutocompleteActionType.SET_AUTOCOMPLETE
  payload: Option[]
}

export const setAutoComplete = (options: Option[]): SetAutoCompleteAction => ({
  type: AutocompleteActionType.SET_AUTOCOMPLETE,
  payload: options,
})

interface GetAssignmentsAction {
  type: AutocompleteActionType.GET_ASSIGNMENTS
  payload: {
    resource: string
    documentID: string
    value: string
  }
}

export const getAssignments = (value: string, resource: string, documentID: string): GetAssignmentsAction => ({
  type: AutocompleteActionType.GET_ASSIGNMENTS,
  payload: {
    value,
    resource,
    documentID,
  },
})

interface GetAssignmentsApiSuccessAction {
  type: AutocompleteActionType.GET_ASSIGNMENTS_SUCCESS
  payload: Enum[]
}

interface SetAssignmentsAction {
  type: AutocompleteActionType.SET_ASSIGNMENTS
  payload: Enum[]
}

export const setAssignments = (assignments: Enum[]): SetAssignmentsAction => ({
  type: AutocompleteActionType.SET_ASSIGNMENTS,
  payload: assignments,
})

export type AnyAutoCompleteAction =
  | SetAutoCompleteAction
  | GetAutoCompleteAction
  | SetAssignmentsAction
  | GetAssignmentsAction
  | GetAssignmentsApiSuccessAction
  | GetAutocompleteSuccessAction
