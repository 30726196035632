import fs from 'file-saver'
import { apiRequest, API_SUCCESS } from '../actions/api'
import { EXPORT, EXPORT_DOCS } from '../actions/export'
import urlBuilder from '../documents/urlBuilder'
import { categorySelector } from '../selectors/location'
import { Middleware } from '../store'

export const exportMiddleware: Middleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  switch (action.type) {
    case EXPORT_DOCS:
      const { url, params } = urlBuilder(getState())
      params.export = true
      delete params.limit
      delete params.skip

      dispatch(
        apiRequest({
          url,
          params,
          method: 'GET',
          feature: EXPORT,
          responseType: 'blob',
        })
      )
      break

    case `${EXPORT} ${API_SUCCESS}`:
      const filename = categorySelector(getState())
      fs.saveAs(action.payload, `${filename}.zip`)
      break
  }
}
