import { AppState } from '../store'

export const categorySelector = (state: AppState): string => state?.location?.payload?.category ?? ''

export const resourceSelector = (state: AppState): string => state?.location?.payload?.resource ?? ''

export const siteIDSelector = (state: AppState): any => state?.location?.payload?.siteID ?? ''

export const querySelector = (state: AppState): any => state?.location?.query ?? {}

export const documentIDSelector = (state: AppState): string => state?.location?.payload?.id ?? ''

export const pathnameSelector = (state: AppState) => state?.location?.pathname ?? ''

export const selectLocationType = (state: AppState) => state.location.type

export const selectPrevLocationType = (state: AppState): string => {
  return state.location.prev.type
}

export const selectPrevCategory = (state: AppState): string => {
  return state.location.prev.payload.category
}
