import enUS from '../i18n/en-US.json'
import { i18nKey, i18nMap, i18nSelectorMap } from '../reducers/i18n'
import { AppState } from '../store'
import { Enum } from '../types'

const warnOnce: any = {}

// TODO: Replace I18n with i18nSelectorMap everywhere in code (there's a LOT of places, and those places
//  should likely just use usei18nSelector())
export type I18n = i18nSelectorMap

export const localeSelector = (state: AppState): string => {
  return state.i18n?.locale ?? 'en-US'
}

export const selectAvailableLocales = (state: AppState): Enum[] => {
  return state.i18n.availableLocales
}

export const i18n = (state: AppState): i18nSelectorMap => (key: i18nKey): string => {
  const localeValue = state?.i18n?.translations[key]
  if (localeValue) {
    return localeValue
  }

  const currentLocale = localeSelector(state)
  const warnKey = `${currentLocale}-${key}`
  if (!warnOnce[warnKey]) {
    console.info(`could not find key '${key}' for locale '${currentLocale}'`)
    warnOnce[warnKey] = 1
  }
  return (enUS as i18nMap)?.[key] ?? ''
}

export const selecti18n = (state: AppState, key: i18nKey): string => {
  const i18nState = state.i18n
  let localeValue = i18nState.translations[key]
  if (localeValue) {
    return localeValue
  }
  const currentLocale = localeSelector(state)
  const warnKey = `${currentLocale}-${key}`
  if (!warnOnce[warnKey]) {
    console.warn(`could not find key '${key}' for locale '${currentLocale}'`)
    warnOnce[warnKey] = 1
  }
  localeValue = enUS[key as i18nKey]
  if (!localeValue && currentLocale !== 'en-US') {
    console.warn(`could not fallback to key '${key}' for en-US locale`)
    warnOnce[`en-US-${key}`] = 1
  }
  return localeValue ?? ''
}
