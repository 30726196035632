import { Audit } from '../types'

export type Resource = {
  ID: string
  audit?: Audit
  locale?: string
  localeID?: string
  organizationID?: string
  trunkID?: string
}

export const isResource = (doc: any): doc is Resource => {
  return (doc as Resource).ID !== undefined
}

export type DuplicableResource = Resource & {
  duplicatedFromID?: string
  duplicatedFromLocaleID?: string
}

export const isDuplicate = (doc: Resource): doc is DuplicableResource => {
  return (doc as DuplicableResource).duplicatedFromID !== undefined
}

export type StatusResource = Resource & {
  status: string
}

export const hasStatus = (doc: Resource): doc is StatusResource => {
  return (doc as StatusResource).status !== undefined
}

export type ExternalIDResource = Resource & {
  externalID: string
}

export const hasExternalID = (doc: Resource): doc is ExternalIDResource => {
  return (doc as ExternalIDResource).externalID !== undefined
}

export type SlugResource = Resource & {
  slug: string
  pcTitle?: string
  name?: string
}

export const hasSlug = (doc: Resource): doc is SlugResource => {
  return doc.ID !== '' && (doc as SlugResource).slug !== undefined
}

export function getSlugableField(doc: SlugResource): string {
  return doc.pcTitle || doc.name || ''
}

export const getResourceTitle = (resource: Resource): string => {
  if (!resource) {
    return ''
  }
  const { pcTitle, name, ID } = resource as any
  let title = ''
  if (pcTitle) {
    title = pcTitle
  } else if (name) {
    title = name
  } else if (ID) {
    title = ID
  }
  if (title) {
    title = title.trim()
  }
  return title
}
