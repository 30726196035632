import { Field } from '../schemas'
import { ApiErrorPayload } from './api'

export enum FieldActionType {
  SET = '[field] Set',
  SET_SUCCESS = '[field] Set API_SUCCESS',
  VALIDATE = '[field] Validate',
  VALIDATE_ERROR = '[field] Validate API_ERROR',
  VALIDATE_SUCCESS = '[field] Validate API_SUCCESS',
}

export type ValidateSuccessPayload = {
  field: Field
}

export type SetFieldAction = {
  type: FieldActionType.SET
  payload: {
    fieldSchema: Field
    field: string
    value: any
    skipValidation: boolean
  }
}

export const setField = (fieldSchema: Field, field: string, value: any, skipValidation: any): SetFieldAction => {
  return {
    type: FieldActionType.SET,
    payload: {
      fieldSchema,
      field,
      value,
      skipValidation,
    },
  }
}

export type ValidateFieldAction = {
  type: FieldActionType.VALIDATE
  payload: {
    resource: string
    field: string
    value: any
  }
}

export const validateField = (resource: string, field: string, value: any): ValidateFieldAction => ({
  type: FieldActionType.VALIDATE,
  payload: {
    resource,
    field,
    value,
  },
})

type ValidateSuccessAction = {
  type: FieldActionType.VALIDATE_SUCCESS
  payload: ValidateSuccessPayload
}

type ValidateErrorAction = {
  type: FieldActionType.VALIDATE_ERROR
  payload: ApiErrorPayload
}

export type AnyFieldAction = SetFieldAction | ValidateFieldAction | ValidateErrorAction | ValidateSuccessAction
