import { Field, Schema } from './index'
import { i18nSelectorMap } from '../reducers/i18n'
import { Enum, LeadStatusToStage } from '../types'
import { deduplicateEnumsByValue } from '../util/enums'

export const V3LeadsCategory = 'v3/leads'

export const getV3Schemas = (
  i18n: i18nSelectorMap,
  statusEnums: Enum[],
  leadTypeEnums: Enum[],
  stageEnumDefaults: Enum[],
  currentStages: string[],
  leadStatusToStage: LeadStatusToStage[]
): Schema[] => {
  const mockV3LeadsSchema = getV3LeadsMockSchema(i18n, statusEnums, leadTypeEnums, stageEnumDefaults, currentStages, leadStatusToStage)
  return [createV3LeadsAPISchemaFromMock(i18n('leads.detail.section.content.label'), mockV3LeadsSchema)]
}

export const createV3LeadsAPISchemaFromMock = (label: string, schema: Schema): Schema => {
  return {
    key: V3LeadsCategory,
    label: label,
    categories: [
      {
        key: V3LeadsCategory,
        label: label,
        schemas: [schema],
        noMultiContext: false,
        icon: 'leads',
      },
    ],
  } as Schema
}

export const getV3LeadsMockSchema = (
  i18n: i18nSelectorMap,
  statusEnums: Enum[],
  leadTypeEnums: Enum[],
  stageEnumDefaults: Enum[],
  currentStages: string[],
  leadStatusToStage: LeadStatusToStage[]
): Schema => {
  const label = i18n('leads.detail.section.content.label')
  const currentStatusesAvailableEnums = getStatusEnumMappingState(statusEnums, currentStages, leadStatusToStage)
  return {
    resource: V3LeadsCategory,
    key: V3LeadsCategory,
    label: label,
    fields: [
      createV3FieldMock('TypeLabel', i18n('leads.originformid.label'), leadTypeEnums),
      createV3FieldMock('Stage', i18n('leads.stage.label'), stageEnumDefaults),
      createV3FieldMock('Status', i18n('leads.list.column.status'), currentStatusesAvailableEnums),
      createV3FieldMock('IsTestLead', i18n('leads.isTestLead.label'), [
        {
          value: 'true',
          label: 'True',
          disabled: false,
        },
        {
          value: 'false',
          label: 'False',
          disabled: false,
        },
      ]),
      createV3FieldMock('CreatedAt', i18n('leads.list.column.createdAt'), []),
    ],
  } as Schema
}

const createV3FieldMock = (key: string, label: string, enums: Enum[]): Field => {
  let presentation = 'select'
  let sortOrder = 1
  switch (key) {
    case 'CreatedAt':
      presentation = 'dateRange'
      sortOrder = 99
      break
  }
  return ({
    key: key,
    label: label,
    filterConfig: {
      presentation: presentation,
      sortOrder: sortOrder,
      isFilter: true,
      singleSelect: false,
      defaultValues: enums.length > 0 ? enums.map((e) => e.value) : [],
      additionalOptions: [],
    },
    restrictions: {
      enums: enums,
    },
  } as unknown) as Field
}

const getStatusEnumMappingState = (currentStatusValues: Enum[], stageSelections: string[], leadStatusToStage: LeadStatusToStage[]): Enum[] => {
  if (!stageSelections || stageSelections.length === 0) {
    // Return an array of the current Enum items as-is
    return deduplicateEnumsByValue(currentStatusValues)
  }
  // Verifying valid Status Mappings are taking here if a stage is selected
  const statusFromStageMappings: string[] = []
  for (const mapping of leadStatusToStage) {
    if (stageSelections.some((s) => s === mapping.stage)) {
      statusFromStageMappings.push(mapping.status)
    }
  }

  const validStatusMappings = currentStatusValues.filter((cs) => {
    return statusFromStageMappings.indexOf(cs.value) > -1
  })

  // De-dup the list here
  return deduplicateEnumsByValue(validStatusMappings)
}
