import { Scope, V2Response } from '../types'

export const DOCS = '[docs]'

export enum DocumentsActionType {
  GET_DOCUMENTS = '[docs] Fetch',
  SET_DOCUMENTS = '[docs] Set',
  SET_SCOPE = '[docs] Set Scope',
  UPDATE_DOCUMENTS = '[docs] Update',
}

interface GetDocumentsAction {
  type: DocumentsActionType.GET_DOCUMENTS
}

export const getDocuments = (): GetDocumentsAction => ({
  type: DocumentsActionType.GET_DOCUMENTS,
})

interface SetDocumentsAction {
  type: DocumentsActionType.SET_DOCUMENTS
  payload: V2Response
}

export const setDocuments = (payload: V2Response): SetDocumentsAction => ({
  type: DocumentsActionType.SET_DOCUMENTS,
  payload,
})

export const clearDocuments = (): SetDocumentsAction => ({
  type: DocumentsActionType.SET_DOCUMENTS,
  payload: ({
    data: [],
  } as unknown) as V2Response,
})

interface SetScopeAction {
  type: DocumentsActionType.SET_SCOPE
  payload: Scope
}

export const setScope = (scope: Scope): SetScopeAction => ({
  type: DocumentsActionType.SET_SCOPE,
  payload: scope,
})

interface UpdateDocumentList {
  type: DocumentsActionType.UPDATE_DOCUMENTS
}

export const updateDocumentList = (): UpdateDocumentList => ({
  type: DocumentsActionType.UPDATE_DOCUMENTS,
})
