import { Nullable } from '../types'
import config from '../config'
import * as Sentry from '@sentry/react'

const sentryTagStorageKey = 'localStorageKey'

function getLocalStorage<T>(key: string): Nullable<T> {
  const item: string | null = window.localStorage.getItem(key)
  if (!item) return null

  try {
    const ret = JSON.parse(item)
    if (ret && typeof ret === 'object') {
      return ret
    }
  } catch (e) {}

  // Non-JSON objects just need to be returned as the generic type (a string)
  return (item as unknown) as T
}

// setLocalStorage sets data into local storage by the provided key.
// It wraps the call to setItem with Sentry error handling, so it should be used whenever possible to set
// local storage data.
function setLocalStorage<T>(key: string, value: T) {
  try {
    if (typeof value === 'string') {
      window.localStorage.setItem(key, value)
      return
    }

    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTag(sentryTagStorageKey, key)
      // We don't want to log session info, only filters and any other context
      if (key !== config.sessionKey) {
        scope.setContext('data', {
          value: value,
        })
      }
      Sentry.captureException(e)
    })
    console.warn('could not set data into local storage:', key, e)
  }
}

function removeLocalStorage(key: string) {
  window.localStorage.removeItem(key)
}

export { removeLocalStorage, getLocalStorage, setLocalStorage }
