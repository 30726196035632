import { AnalyticsState } from '../reducers/analytics'
import { i18nKey } from '../reducers/i18n'
import { selecti18n } from './i18n'
import { AppState } from '../store'
import { AnalyticsSettings } from '../types'

export const analyticsSelector = (state: AppState): AnalyticsState => {
  return state?.analytics
}

export const analyticsSettingsSelector = (state: AppState): AnalyticsSettings => {
  return state?.settings.analyticsSettings
}

export const analyticsTitleSelector = (state: AppState): string => {
  const dashboardType = state?.analytics?.dashboardType ?? ''

  if (!dashboardType) {
    return selecti18n(state, 'nav.labels.analytics')
  }

  const key = `nav.labels.analytics.${dashboardType}` as i18nKey

  const translation = selecti18n(state, key)

  if (!translation) {
    return selecti18n(state, 'nav.labels.analytics')
  }

  return translation
}
