import _ from 'lodash'
import { LocationState } from 'redux-first-router'
import {
  apiRequest,
  API_SUCCESS,
  ArchitectActionType,
  getComponents,
  getPages,
  getSite,
  hideLoader,
  rendererRequest,
  RENDERER_ERROR,
  RENDERER_SUCCESS,
  renderPage,
  setComponents,
  setForm,
  setHtml,
  setPage,
  setPages,
  setSite,
  showLoader,
} from '../actions'
import { formatRenderError } from '../architect'
import { architectPageSelector, sessionReadySelector, siteSelector } from '../selectors'
import { Middleware } from '../store'
import { Component, Page } from '../types'

export const architectMiddleware: Middleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  const state = getState()
  const site = siteSelector(state)
  switch (action.type) {
    // GET PAGES
    case ArchitectActionType.GET_PAGES:
      if (sessionReadySelector(state)) {
        dispatch(getSite(action.payload))
        dispatch(
          rendererRequest({
            url: '/pages',
            method: 'GET',
            feature: ArchitectActionType.GET_PAGES,
            params: {
              siteID: action.payload,
            },
          })
        )
      } else {
        setTimeout(() => {
          dispatch(getPages(action.payload))
        }, 250)
      }
      break

    case `${ArchitectActionType.GET_PAGES} ${RENDERER_SUCCESS}`:
      dispatch(setPages(action.payload))
      break

    // SITE
    case ArchitectActionType.GET_SITE:
      dispatch(
        apiRequest({
          url: `/sites/${action.payload}`,
          method: 'GET',
          feature: ArchitectActionType.GET_SITE,
        })
      )
      break

    case `${ArchitectActionType.GET_SITE} ${API_SUCCESS}`:
      dispatch(setSite(action.payload.data))
      dispatch(getComponents())
      break

    // SET PAGES
    case ArchitectActionType.SET_PAGES: // Load Home page when architect loads initial root page
      const pages: Page[] = action.payload
      const currentPage: Page = architectPageSelector(getState())
      const location: LocationState = getState().location
      const currentRouterLocationPageID = location.payload?.pageID ?? null

      if (pages.length > 1) {
        if (!currentPage.ID && currentRouterLocationPageID === null) dispatch(setPage(pages[0].ID))
        if (currentPage.ID && currentRouterLocationPageID === null) break // prevents unnecessary actions being fired.
        if (currentPage.ID !== currentRouterLocationPageID && currentRouterLocationPageID !== null) dispatch(setPage(currentRouterLocationPageID))
      }
      break

    // SET PAGE
    case ArchitectActionType.SET_PAGE:
      dispatch(renderPage())
      break

    // RENDER PAGE
    case ArchitectActionType.RENDER_PAGE:
      const matchSiteID = window.location.search.match(/dataSiteID=([^&]+)/),
        siteID = matchSiteID && matchSiteID.length > 1 ? matchSiteID[1] : undefined
      dispatch(showLoader(ArchitectActionType.RENDER_PAGE))
      dispatch(
        rendererRequest({
          url: `/pages/${architectPageSelector(state).ID}/render`,
          method: 'GET',
          feature: ArchitectActionType.RENDER_PAGE,
          params: {
            siteID,
          },
        })
      )
      break

    case `${ArchitectActionType.RENDER_PAGE} ${RENDERER_SUCCESS}`:
      dispatch(setHtml(action.payload.html))
      dispatch(hideLoader(ArchitectActionType.RENDER_PAGE))
      break

    case `${ArchitectActionType.RENDER_PAGE} ${RENDERER_ERROR}`:
      dispatch(setHtml(formatRenderError(action.payload)))
      dispatch(hideLoader(ArchitectActionType.RENDER_PAGE))
      break

    // FORMS
    case ArchitectActionType.GET_FORM:
      const page = architectPageSelector(state)
      const url = action.payload.url ?? `/pages/${page.ID}/settings`
      dispatch(
        rendererRequest({
          url,
          method: 'GET',
          feature: ArchitectActionType.GET_FORM,
        })
      )
      break

    case `${ArchitectActionType.GET_FORM} ${RENDERER_SUCCESS}`:
      dispatch(setForm(action.payload.form))
      break

    case ArchitectActionType.SUBMIT_FORM:
      dispatch(showLoader(ArchitectActionType.SUBMIT_FORM))
      dispatch(
        rendererRequest({
          url: action.payload.url,
          method: action.payload.method,
          body: action.payload.data,
          feature: ArchitectActionType.SUBMIT_FORM,
        })
      )
      break

    case `${ArchitectActionType.SUBMIT_FORM} ${RENDERER_SUCCESS}`:
      dispatch(getPages(site.ID))
      dispatch(renderPage())
      dispatch(setForm(''))
      dispatch(hideLoader(ArchitectActionType.SUBMIT_FORM))
      break

    // SECTIONS
    case ArchitectActionType.REORDER_SECTION:
      dispatch(
        rendererRequest({
          url: `/pages/${action.payload.pageID}/sections/${action.payload.sectionID}/reorder/${action.payload.index}`,
          method: 'PUT',
          feature: ArchitectActionType.REORDER_SECTION,
        })
      )
      break

    case `${ArchitectActionType.REORDER_SECTION} ${RENDERER_SUCCESS}`:
      dispatch(renderPage())
      break

    case ArchitectActionType.ADD_SECTION:
      dispatch(
        rendererRequest({
          url: `/pages/${action.payload.pageID}/sections/new`,
          method: 'POST',
          body: {
            index: action.payload.index,
          },
          feature: ArchitectActionType.ADD_SECTION,
        })
      )
      break

    case `${ArchitectActionType.ADD_SECTION} ${RENDERER_SUCCESS}`:
      dispatch(getPages(site.ID))
      break

    case ArchitectActionType.DELETE_SECTION:
      dispatch(
        rendererRequest({
          url: `/pages/${action.payload.pageID}/sections/${action.payload.sectionID}`,
          method: 'DELETE',
          feature: ArchitectActionType.DELETE_SECTION,
        })
      )
      break

    case `${ArchitectActionType.DELETE_SECTION} ${RENDERER_SUCCESS}`:
      dispatch(getPages(site.ID))
      break

    case `${ArchitectActionType.DELETE_SECTION} ${RENDERER_ERROR}`:
      dispatch(getPages(site.ID))
      break

    // COMPONENTS
    case ArchitectActionType.GET_COMPONENTS:
      dispatch(
        rendererRequest({
          url: '/components',
          method: 'GET',
          feature: ArchitectActionType.GET_COMPONENTS,
          params: {
            trunkID: site.trunkID,
          },
        })
      )
      break

    case `${ArchitectActionType.GET_COMPONENTS} ${RENDERER_SUCCESS}`:
      const components = _.chain(action.payload.categories)
        .values()
        .flatten()
        .uniqBy((c: Component) => c.HID)
        .sortBy((c: Component) => c.name)
        .value()

      dispatch(setComponents(components))
      break

    case ArchitectActionType.PUBLISH_PAGE:
      dispatch(showLoader(ArchitectActionType.PUBLISH_PAGE))
      dispatch(
        rendererRequest({
          url: `/pages/${action.payload}/publish`,
          method: 'PUT',
          feature: ArchitectActionType.PUBLISH_PAGE,
        })
      )
      break

    case `${ArchitectActionType.PUBLISH_PAGE} ${RENDERER_SUCCESS}`:
      dispatch(hideLoader(ArchitectActionType.PUBLISH_PAGE))
      break
  }
}
