import { Enum, Option, OptionType } from '../types'

export const deduplicateEnumsByValue = (inputArray: Enum[]): Enum[] => {
  return inputArray.reduce((accumulator: Enum[], currentValue) => {
    const existingItem = accumulator.find((item) => item.value === currentValue.value)

    if (!existingItem) {
      accumulator.push(currentValue)
    }

    return accumulator
  }, [])
}

export const enumToOptionEnum = (enums: Enum[]): Option[] => {
  if (enums.length === 0) return []
  return enums.map((e: Enum) => {
    return {
      ...e,
      type: OptionType.Enum,
    }
  })
}
