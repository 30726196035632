import { AppState } from '../store'
import { LeadStatusToStage, Settings } from '../types'

export const analyticsEnabledSelector = (state: AppState): boolean => state?.settings?.analyticsSettings?.enableAnalytics ?? false

export const siteAnalyticsEnabledSelector = (state: AppState): boolean => state?.settings?.analyticsSettings?.enableSiteAnalytics ?? false

export const campaignAnalyticsEnabledSelector = (state: AppState): boolean => state?.settings?.analyticsSettings?.enableCampaignAnalytics ?? false

export const locatorAnalyticsEnabledSelector = (state: AppState): boolean => state?.settings?.analyticsSettings?.enableLocatorAnalytics ?? false

export const focalPointEnabledSelector = (state: AppState): boolean => state?.settings?.enableFocalPoint ?? false

export const mapBoxEnabledSelector = (state: AppState): boolean => state?.settings?.resourceSettings?.useMapBox ?? false

export const primaryLocaleSelector = (state: AppState): string => state?.settings?.primaryLocale ?? ''

export const settingsSelector = (state: AppState): Settings => state?.settings ?? null

export const selectV3EnabledSetting = (state: AppState): boolean => state.settings.enableV3

export const selectLeadStatusToStageSetting = (state: AppState): LeadStatusToStage[] => {
  return state.settings.leadStatusToStage
}
