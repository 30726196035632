import { apiRequest, AutocompleteActionType, setAssignments, setAutoComplete } from '../actions'
import { wipDocumentSelector } from '../selectors'
import { Middleware } from '../store'
import { Schema } from '../schemas'
import { Option, OptionType, Organization, ReferenceFieldValueDoc } from '../types'

export const autocompleteMiddleware: Middleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  switch (action.type) {
    case AutocompleteActionType.GET_AUTOCOMPLETE: {
      const { field } = action.payload
      const { resource, textField } = field.autoComplete!
      const { trunkID, organizationID, value } = action.payload.params
      const url = '/autocomplete'
      const doc = wipDocumentSelector(getState())
      dispatch(
        apiRequest({
          url,
          method: 'GET',
          feature: AutocompleteActionType.GET_AUTOCOMPLETE,
          params: {
            resource,
            trunkID,
            organizationID,
            match: textField,
            prefix: value,
            ...(doc && doc.locale && doc.locale.length
              ? {
                  locale: doc.locale,
                }
              : {}),
          },
        })
      )
      break
    }

    case AutocompleteActionType.GET_AUTOCOMPLETE_SUCCESS: {
      const state = getState()
      const field = state.autocomplete.referenceField
      if (!field) {
        break
      }

      const options: Option[] = []
      if (field.autoComplete?.resource && field.autoComplete.resource === 'organizations') {
        const payload = action.payload as Organization[]
        payload.forEach((opt) => {
          options.push({
            type: OptionType.Organization,
            label: opt.name,
            secondaryLabel: `${opt.trunkOrganizationName !== opt.name ? opt.trunkOrganizationName : ''} ${opt.type}`,
            value: opt,
          })
        })
      } else {
        const payload = action.payload as Schema[]
        payload.forEach((opt) => {
          const labelKey = field && field.autoComplete && field.autoComplete.textField
          const label = labelKey ? opt[labelKey as keyof Schema] : ''
          options.push({
            type: OptionType.ReferenceField,
            label: label,
            value: (opt as unknown) as ReferenceFieldValueDoc,
          })
        })
      }
      dispatch(setAutoComplete(options))
      break
    }

    case AutocompleteActionType.GET_ASSIGNMENTS:
      dispatch(
        apiRequest({
          url: `/assignments?resource=${action.payload.resource}&resourceID=${action.payload.documentID}&prefix=${action.payload.value}`,
          method: 'GET',
          feature: AutocompleteActionType.GET_ASSIGNMENTS,
        })
      )
      break

    case AutocompleteActionType.GET_ASSIGNMENTS_SUCCESS:
      dispatch(setAssignments(action.payload))
      break
  }
}
