import { Schema } from '../schemas'

export const API_REQUEST: 'API_REQUEST' = 'API_REQUEST'
export const API_SUCCESS: 'API_SUCCESS' = 'API_SUCCESS'
export const API_ERROR: 'API_ERROR' = 'API_ERROR'

export type PaginationHeaders = {
  'pagination-offset': string
  'pagination-limit': string
  'pagination-total': string
}

type Methods = 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE'

export interface ApiRequestAction {
  type: string
  payload?: Body
  meta: {
    method: Methods
    url: string
    responseType?: string
    params?: object
    feature: string
    resource: string
    scope?: string
    // asOwnerOverride can be passed to override the As-Owner header in calls to apiRequest
    asOwnerOverride?: string
  }
}

export type ApiSuccessPayload<T = any> = {
  ID: string
  data: T
  schema: Schema
}

export type ApiErrorPayload = {
  _inputs?: any
}

type Body = object | any[] | null

type Request = {
  method: Methods
  feature: string
  body?: Body
  url: string
  params?: object
  responseType?: string
  resource?: string
  scope?: string
  // asOwnerOverride can be passed to override the As-Owner header in calls to apiRequest
  asOwnerOverride?: string
}

export const apiRequest = ({
  feature,
  body,
  method,
  url,
  params,
  responseType,
  resource = '',
  scope,
  asOwnerOverride,
}: Request): ApiRequestAction => ({
  type: `${feature} ${API_REQUEST}`,
  payload: body,
  meta: {
    method,
    url,
    feature,
    params,
    responseType,
    resource,
    scope,
    asOwnerOverride,
  },
})

interface ApiSuccessAction {
  type: string
  payload: object | any[] | null
  meta: {
    feature: string
    resource: string
  }
  headers: Record<string, string>
}

export const apiSuccess = (data: any, feature: string, resource: string, headers: any): ApiSuccessAction => ({
  type: `${feature} ${API_SUCCESS}`,
  payload: data,
  meta: {
    feature,
    resource,
  },
  headers,
})

export const apiError = (error: any, feature: any) => ({
  type: `${feature} ${API_ERROR}`,
  payload: error,
  meta: {
    feature,
  },
})
