import { SessionState } from 'reducers/session'
import { Organization } from '../types'

export const SESSION = '[session]'

export enum SessionActionTypes {
  LOGIN = '[session] LOGIN',
  LOGOUT = '[session] LOGOUT',
  SET_SESSION = '[session] SET',
  SET_FOCUSED_ORG = '[session] Set Focused Org',
  CLEAR_SESSION = '[session] Clear',
  SET_LOGIN_ERROR = '[session] Set Login Error',
  SET_PASSWORD_RESET_ERROR = '[session] Set Password Reset Error',
  SET_REDIRECT = '[session] Set Redirect',
  APPLY_CONTEXT = '[session] Apply Context',
  SET_CONTEXT = '[session] Set Context',
  APPLY_CONTEXT_SUCCESS = '[session] Apply Context API_SUCCESS',
  API_SUCCESS = '[session] API_SUCCESS',
  API_ERROR = '[session] API_ERROR',
}

export type SessionAction =
  | { type: SessionActionTypes.LOGIN; payload: { email: string; password: string } }
  | { type: SessionActionTypes.SET_SESSION; payload: SessionState }
  | { type: SessionActionTypes.SET_FOCUSED_ORG; payload: Organization }
  | { type: SessionActionTypes.LOGOUT }
  | { type: SessionActionTypes.CLEAR_SESSION }
  | { type: SessionActionTypes.SET_LOGIN_ERROR; payload: string }
  | { type: SessionActionTypes.SET_PASSWORD_RESET_ERROR; payload: string }
  | { type: SessionActionTypes.SET_REDIRECT; payload: string }
  | { type: SessionActionTypes.APPLY_CONTEXT; payload: Partial<Organization> }
  | { type: SessionActionTypes.SET_CONTEXT; payload: {} }

export const login = (email: string, password: string): SessionAction => ({
  type: SessionActionTypes.LOGIN,
  payload: {
    email,
    password,
  },
})

export const setSession = (session: SessionState): SessionAction => ({
  type: SessionActionTypes.SET_SESSION,
  payload: session,
})

export const setFocusedOrg = (org: Organization): SessionAction => ({
  type: SessionActionTypes.SET_FOCUSED_ORG,
  payload: org,
})

export const logout = (): SessionAction => ({
  type: SessionActionTypes.LOGOUT,
})

export const clearSession = (): SessionAction => ({
  type: SessionActionTypes.CLEAR_SESSION,
})

export const setLoginError = (error: string): SessionAction => ({
  type: SessionActionTypes.SET_LOGIN_ERROR,
  payload: error,
})

export const clearPasswordResetError = (): SessionAction => ({
  type: SessionActionTypes.SET_PASSWORD_RESET_ERROR,
  payload: '',
})

export const setPasswordResetError = (error: string): SessionAction => ({
  type: SessionActionTypes.SET_PASSWORD_RESET_ERROR,
  payload: error,
})

export const setRedirect = (redirectAction: string): SessionAction => ({
  type: SessionActionTypes.SET_REDIRECT,
  payload: redirectAction,
})

export const applyContext = (org: Organization): SessionAction => ({
  type: SessionActionTypes.APPLY_CONTEXT,
  payload: org,
})

export const setContext = (context: {}): SessionAction => ({
  type: SessionActionTypes.SET_CONTEXT,
  payload: context,
})
