import { Sort } from '../types/Sort'

export enum SortActionType {
  CLEAR = '[sort] Clear',
  SET = '[sort] Set',
}

type ClearSortAction = {
  type: SortActionType.CLEAR
}

export const clearSort = (): ClearSortAction => ({
  type: SortActionType.CLEAR,
})

type SetSortAction = {
  sort: Sort
  type: SortActionType.SET
}

export const setSort = (sort: Sort): SetSortAction => ({
  type: SortActionType.SET,
  sort,
})

export type AnySortAction = ClearSortAction | SetSortAction
