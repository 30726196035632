import { countSelector } from './documents'
import { AppState } from '../store'

export const hasNextPageSelector = (state: AppState): any => {
  const skip = skipSelector(state)
  const limit = limitSelector(state)
  const documentCount = countSelector(state)
  const nextSkip = skip + limit
  const nextPageCount = documentCount - nextSkip > limit ? limit : documentCount - nextSkip
  return nextPageCount
}

export const skipSelector = (state: AppState): number => {
  return state?.pagination?.skip ?? 0
}

export const limitSelector = (state: AppState): number => {
  return state?.pagination?.limit ?? 100
}

export const pagePaginationSelector = (state: AppState): number => {
  return state?.pagination?.page ?? 0
}
