import { AppState } from 'store'
import { IDFromV2 } from '../util'
import { Scope } from '../types'

export type ScopeHeaders = {
  'As-Owner': string
  'Owner-Scope': Scope
}

// scopeHeadersSelector returns headers for scoping v3 requests to a specific Organization & filter type
export const scopeHeadersSelector = (state: AppState, ownerScope?: Scope, asOwnerOverride?: string): ScopeHeaders => {
  if (!ownerScope) {
    ownerScope = scopeSelector(state)
  }

  const asOwner = IDFromV2(state?.session?.organization?.ID)

  const headers: ScopeHeaders = {
    'Owner-Scope': ownerScope,
    'As-Owner': '',
  }

  switch (ownerScope) {
    case Scope.IncludeDescendants:
    case Scope.Focused:
      headers['As-Owner'] = asOwner
  }

  // AB#12017 - SM 6/30/2023
  // If an Owner override is present, use it explicitly.
  // See SAVE_LEAD in middleware/v3Leads for documentation on why this happens.
  if (asOwnerOverride && asOwnerOverride.length > 0) {
    headers['As-Owner'] = asOwnerOverride
  }

  return headers
}

export const scopeSelector = (state: AppState): Scope => {
  return state?.documents?.scope ?? Scope.IncludeDescendants
}
