import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { APIError, Option, OptionType } from '../../types';
import Button from '../Button/Button';
import Select from '../Select/Select';
import TextField from '../TextField/TextField';
import { SessionState } from 'reducers/session';
import { RefreshToast } from '../RefreshToast/RefreshToast';
import config from '../../config';
import './Login.scss';
import { useAvailableLocaleOptions, useI18nSelector, useI18nDispatch, useI18nState } from '../../v3/context/i18n';
import { resetDateFilters, setLocalStorage } from '../../util';
import { i18nActionTypes } from '../../actions';
type RootLoginProps = {
  email?: string;
  error?: string;
};
export const RootLogin = ({
  email: propsEmail,
  error: propsError
}: RootLoginProps) => {
  const [email, setEmail] = useState<string>(propsEmail ?? '');
  const [error, setError] = useState<string>(propsError ?? '');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const {
    locale
  } = useI18nState();
  const availableLocales = useAvailableLocaleOptions();
  const i18n = useI18nSelector();
  const i18nDispatch = useI18nDispatch();
  const location = useLocation();
  const {
    from
  } = location.state || {
    from: {
      pathname: '/'
    }
  };
  const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    axios({
      url: `${config.api}/authenticate/credentials`,
      method: 'POST',
      data: JSON.stringify({
        email,
        password
      })
    }).then((res: AxiosResponse<SessionState | APIError>) => {
      if (res.status === 200) {
        setLocalStorage(config.sessionKey, (res.data as SessionState));
        // AB#8587 Lead list date filters should be reset on every user session
        resetDateFilters();
        // reloading the window; this allows the app to re-fetch the session from storage and load into desktop or mobile views
        history.replace(from);
      } else {
        setError((res.data as APIError)?._message ?? '');
      }
    }).catch((err: AxiosError) => {
      setError(err.message);
    });
  };
  const handleHelpClick = () => {
    window.open('https://powerchord.atlassian.net/servicedesk/customer/portals', '_blank');
  };
  return <div className="LoginScreen" data-sentry-component="RootLogin" data-sentry-source-file="RootLogin.tsx">
      <div className="Login">
        <form className="form">
          <div className="logo" />
          {error ? <div className="error">
              <p>{i18n('auth.error')}</p>
              <p>{error}</p>
            </div> : null}
          <TextField label={i18n('auth.emailLabel')} onInput={(value: string) => setEmail(value)} type="email" value={email} testID={'login-email'} data-sentry-element="TextField" data-sentry-source-file="RootLogin.tsx" />
          <TextField label={i18n('auth.passwordLabel')} onInput={(value: string) => setPassword(value)} type="password" value={password} testID={'login-password'} data-sentry-element="TextField" data-sentry-source-file="RootLogin.tsx" />
          <Select dataCIKey={'locale'} label={i18n('locale.language')} options={availableLocales} value={availableLocales.filter((v: any): boolean => locale === v.value)} onChange={(opts: Option[]) => {
          const locale = opts[0];
          if (locale.type !== OptionType.Enum) return;
          i18nDispatch({
            type: i18nActionTypes.SET_LOCALE,
            locale: locale.value
          });
        }} data-sentry-element="Select" data-sentry-source-file="RootLogin.tsx" />
          <div className="buttons">
            <div className="helpers">
              <Button className="forgotPassword" onClick={() => history.push('/forgot-password')} data-sentry-element="Button" data-sentry-source-file="RootLogin.tsx">
                {i18n('auth.forgotPassword')}
              </Button>
              <Button className="help" onClick={handleHelpClick} data-sentry-element="Button" data-sentry-source-file="RootLogin.tsx">
                {i18n('auth.signInHelp')}
              </Button>
            </div>
            <div className="actions">
              <Button className="submit" id="submit" variant="contained" color="primary" disableElevation onClick={onSubmit} type="submit" data-sentry-element="Button" data-sentry-source-file="RootLogin.tsx">
                {i18n('auth.cta.signIn')}
              </Button>
            </div>
          </div>
        </form>
      </div>
      <RefreshToast data-sentry-element="RefreshToast" data-sentry-source-file="RootLogin.tsx" />
    </div>;
};