import { Enum, Lead, LeadType, Option, QueriedLead, V3Organization } from '../types'
import { i18nKey, i18nSelectorMap } from '../reducers/i18n'

const productKeys: string[] = ['productName', 'variant', 'vehicleModel', 'vehicleMake']

export function getLeadName(lead: Lead): string {
  if (lead.DisplayName) {
    return lead.DisplayName
  }

  const content = lead.Content
  if (!content) {
    return ''
  }

  let name = ''

  try {
    if (content['name']) {
      name = content['name']
    }

    if (name === '') {
      const fn = content['firstName'] ?? content['first_name'] ?? ''
      const ln = content['lastName'] ?? content['last_name'] ?? ''

      if (fn !== '') {
        name = fn
      }

      if (fn === '' && ln !== '') {
        name = ln
      }

      if (name !== '' && ln !== '') {
        name += ' ' + ln
      }
    }

    for (let i = 0; i < productKeys.length; i++) {
      const value = content[productKeys[i]] ?? ''
      if (value.length) {
        name += ' ' + value
        break
      }
    }
  } catch (err) {}

  return name
}

export const getLeadBrandName = (lead: Lead, v3Orgs: V3Organization[]): string => {
  if (!lead.Organization) return ''

  const brandName = lead.Organization.Name ?? ''

  if (lead.Organization.TrunkID === '' || v3Orgs.length === 0) {
    return brandName
  }

  const trunk = v3Orgs.find((org) => {
    return org.ID === lead.Organization!.TrunkID
  })
  if (!trunk) {
    return brandName
  }

  return trunk.Name ?? brandName
}

export const marshalQueriedLeadToLead = (ql: QueriedLead): Lead => {
  // Items marked with 'Placeholder' are not present in a QueriedLead, but are required by a Lead.
  // These fields should not be used in places where we are handling QueriedLeads - if we ever
  // neeed to, then the backend query result must be updated!
  //
  // Some of these fields may actually be optional - ex: the V3Organization may have too many required fields
  return {
    ID: ql.id,
    CreatedAt: ql.createdAt,
    UpdatedAt: ql.updatedAt,
    TypeLabel: ql.typeLabel,
    TypeKey: ql.typeKey,
    Status: ql.status,
    Stage: ql.stage,
    Content: ql.content,
    IsTestLead: ql.isTestLead,
    DisplayName: ql.displayName,
    Organization: {
      ID: ql.orgID,
      Name: ql.orgName, // Placeholder
      Status: '', // Placeholder
      TrunkID: ql.trunkID,
      TrunkName: ql.trunkName,
    },
  }
}

export const marshalQueriedLeadsToLeads = (qls: QueriedLead[]): Lead[] => {
  return qls.map((ql) => {
    return marshalQueriedLeadToLead(ql)
  })
}

export const convertLeadTypesToEnums = (leadTypes: LeadType[]): Enum[] => {
  return leadTypes.map((lt) => ({ label: lt.typeLabel, value: lt.typeLabel, disabled: false }))
}

export const getLeadStatusLabel = (i18n: i18nSelectorMap, status: string | undefined, statusOptions: Option[]): string => {
  return getStatusOrStageLabel(i18n, status, statusOptions, false)
}

export const getLeadStageLabel = (i18n: i18nSelectorMap, stage: string | undefined, stageOptions: Option[]): string => {
  return getStatusOrStageLabel(i18n, stage, stageOptions, true)
}

const getStatusOrStageLabel = (i18n: i18nSelectorMap, value: string | undefined, options: Option[], isStage: boolean): string => {
  if (!value || value === '') {
    return ''
  }

  let i18nNamespace = 'status'
  if (isStage) {
    i18nNamespace = 'stage'
  }

  // We want to maintain localized labels wherever possible
  const i18nLabel = i18n(`leads.${i18nNamespace}.enum.${value}.label` as i18nKey)
  if (i18nLabel !== '') {
    return i18nLabel
  }

  // Find the status/stage by value in the provided options array and return the label for this if found.
  const statusOrStageFromOptions = options.find((op) => op.value === value)
  if (statusOrStageFromOptions && statusOrStageFromOptions.label !== '') {
    return statusOrStageFromOptions.label
  }

  // Fallback to returning the provided value string
  return value
}
