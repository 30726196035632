export enum Environment {
  Production = 'production',
  QA = 'qa',
  Development = 'dev',
  Workstation = 'workstation',
}

export const Environments: Environment[] = [Environment.Workstation, Environment.Development, Environment.QA, Environment.Production]
declare global {
  interface Window {
    pcEnv: Record<string, string>
  }
}

const config = {
  api: getEnv('REACT_APP_API'),
  v3Api: getEnv('REACT_APP_V3_API'),
  renderer: getEnv('REACT_APP_RENDERER'),
  environment: getEnv('REACT_APP_ENV') as Environment,
  mapboxToken: getEnv('REACT_APP_MAPBOX_TOKEN'),
  build: getEnv('REACT_APP_UI_BUILD'),
  debugSentry: process.env.REACT_APP_SENTRY_DEBUG ? process.env.REACT_APP_SENTRY_DEBUG.toLowerCase() === 'true' : false,
  sessionKey: 'pc:admin:auth',
  filtersKey: 'pc:filters',
  languageKey: 'language',
  logLevel: 'all',
  // Used to identify different iterations of filters so we can handle transitions
  filterVersionKey: 'pc:filters:version',
  filterVersionCurrent: '2',
}

function getEnv(envVar: string): string {
  let ret: string | undefined
  if (process.env.NODE_ENV !== 'production' && envVar === 'REACT_APP_MAPBOX_TOKEN') {
    ret = 'dummy_token'
  } else if (process.env.NODE_ENV !== 'production' && process.env[envVar]) {
    ret = process.env[envVar]
  } else if (window.pcEnv) {
    ret = window.pcEnv[envVar]
  }
  if (!ret) {
    throw new Error(`Missing environment variable: ${envVar}. Make sure you add environment variables to apps/admin/.env!`)
  }
  return ret
}

export default config
