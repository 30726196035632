import { AppState } from '../store'
import { Enum, Lead, LeadQueryRequest, LeadType, Sort } from '../types'
import { limitSelector, skipSelector } from './pagination'
import { scopeSelector } from './scope'
import { searchTextSelector } from './search'
import { convertLeadTypesToEnums } from '../util'
import { differenceWith, isEqual } from 'lodash'
import {
  selectDateRangeFilterOptionValuesByKey,
  selectSelectedFilterOptionBoolsByKeyClean,
  selectSelectedFilterOptionStringsByKeyClean,
} from './filters'

export const v3LeadsListSelector = (state: AppState): Lead[] => {
  return state.v3Leads.leads
}

export const v3LeadSelector = (state: AppState): Lead => {
  return state.v3Leads.lead
}

export const selectInitialV3Lead = (state: AppState): Lead => {
  return state.v3Leads.initialLead
}

// The PATCH endpoint for V3 Leads only takes a small subset of fields
export const selectPartialV3LeadForPatch = (state: AppState): Partial<Lead> => {
  const wip = v3LeadSelector(state)
  const original = selectInitialV3Lead(state)

  const validFields = ['ID', 'IsTestLead', 'Status', 'Notes']

  const patchable: Partial<Lead> = {}
  for (const [key, value] of Object.entries(wip)) {
    if (validFields.indexOf(key) === -1) {
      continue
    }

    // Always return the ID
    if (key === 'ID') {
      patchable[key] = value as string
      continue
    }

    switch (key) {
      case 'IsTestLead':
        if (original['IsTestLead'] !== wip['IsTestLead']) {
          patchable['IsTestLead'] = value as boolean
        }
        break
      case 'Status':
        if (original['Status'] !== wip['Status']) {
          patchable['Status'] = value as string
        }
        break
      case 'Notes':
        const noteDiff = differenceWith(wip['Notes'] ?? [], original['Notes'] ?? [], isEqual)
        if (noteDiff.length > 0) {
          patchable['Notes'] = noteDiff
        }
    }
  }

  return patchable
}

export const selectLeadTypes = (state: AppState): LeadType[] => {
  return state.v3Leads.leadTypes
}

export const selectLeadTypeEnums = (state: AppState): Enum[] => {
  const leadTypes = selectLeadTypes(state)
  return convertLeadTypesToEnums(leadTypes)
}

export const v3LeadSortSelector = (state: AppState): Sort => {
  return state.v3Leads.sort ?? {}
}

export const selectUnsavedV3LeadChanges = (state: AppState): boolean => {
  return JSON.stringify(selectInitialV3Lead(state)) !== JSON.stringify(v3LeadSelector(state))
}

export function v3LeadQueryRequestConfigSelector(state: AppState): LeadQueryRequest {
  const params = new URLSearchParams(state?.location?.query as Record<string, string>)
  const sortParts = params.get('_order')?.split(' ')
  const sortField = sortParts?.length === 2 ? sortParts[0] : undefined
  const sortDirection = sortParts?.length === 2 ? sortParts[1] : undefined
  const [dateLT, dateGT] = selectDateRangeFilterOptionValuesByKey(state, 'CreatedAt')
  return {
    orgScope: scopeSelector(state),
    limit: limitSelector(state),
    offset: skipSelector(state),
    isTestLead: selectSelectedFilterOptionBoolsByKeyClean(state, 'IsTestLead'),
    status: selectSelectedFilterOptionStringsByKeyClean(state, 'Status'),
    stage: selectSelectedFilterOptionStringsByKeyClean(state, 'Stage'),
    typeLabel: selectSelectedFilterOptionStringsByKeyClean(state, 'TypeLabel'),
    search: searchTextSelector(state) || undefined,
    createdAtStart: new Date(dateGT * 1000).toISOString(),
    createdAtEnd: new Date(dateLT * 1000).toISOString(),
    sortField,
    sortDirection,
  }
}
