export enum FlagActionTypes {
  SET_FLAG = '[flags] Set Flag',
  SET_FLAGS = '[flags] Set All',
  CALCULATE_FLAGS = '[flags] Calculate',
}

export type FlagActions =
  | { type: FlagActionTypes.SET_FLAG; payload: { key: string; on: boolean } }
  | { type: FlagActionTypes.SET_FLAGS; payload: Record<string, boolean> }
  | { type: FlagActionTypes.CALCULATE_FLAGS }

export const setFlags = (flags: Record<string, boolean>): FlagActions => ({
  type: FlagActionTypes.SET_FLAGS,
  payload: flags,
})

export const setFlag = (key: string, on: boolean): FlagActions => ({
  type: FlagActionTypes.SET_FLAG,
  payload: {
    key,
    on,
  },
})

export const calculateFlags = (): FlagActions => ({
  type: FlagActionTypes.CALCULATE_FLAGS,
})
