import { API_SUCCESS, apiRequest, GET_MEMBERSHIPS, MEMBERSHIPS, setMemberships } from '../actions'
import { Middleware } from '../store'

export const membershipsMiddleware: Middleware = ({ dispatch }) => (next) => (action) => {
  next(action)

  switch (action.type) {
    case GET_MEMBERSHIPS:
      dispatch(
        apiRequest({
          url: `/userAndMemberships/${action.payload}`,
          method: 'GET',
          feature: MEMBERSHIPS,
        })
      )
      break

    case `${MEMBERSHIPS} ${API_SUCCESS}`:
      dispatch(setMemberships(action.payload.data?.memberships))
      break
  }
}
