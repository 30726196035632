import React from 'react';
import { useHistory } from 'react-router-dom';
import { useI18nSelector } from 'v3/context/i18n';
import Button from '../Button/Button';
import './OneClick.scss';
type OneClickProps = {
  leadID: string;
};
const OneClickSuccess = (props: OneClickProps) => {
  const history = useHistory();
  const i18n = useI18nSelector();
  return <div className="OneClick" data-sentry-component="OneClickSuccess" data-sentry-source-file="OneClickSuccess.tsx">
      <div className="Content">
        <div className="form">
          <div className="logo" />
          <span className="message">{i18n('leads.oca.success.message')}</span>
          <div className="buttons">
            <Button className="view-lead"
          // ?from param is for clickstream tracking
          onClick={() => history.push(`/v3/leads/${props.leadID}?from=ocaSuccess`)} variant="contained" color="primary" disableElevation data-sentry-element="Button" data-sentry-source-file="OneClickSuccess.tsx">
              {i18n('leads.oca.button.label')}
            </Button>
          </div>
        </div>
      </div>
    </div>;
};
export default OneClickSuccess;