import { always, ifElse, not } from 'ramda'

const formatLocalTime = ifElse(not, always(''), (timestamp: any) =>
  timestamp.toLocaleTimeString([], {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  })
)

export default formatLocalTime
