import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import config from '../../config';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import { useI18nSelector } from '../../v3/context/i18n';
import './Login.scss';
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [sent, setSent] = useState(false);
  const history = useHistory();
  const i18n = useI18nSelector();
  const handleInputChange = (value: string) => {
    setEmail(value);
  };
  const handleSubmit = () => {
    axios({
      url: `${config.api}/credentials/forgot`,
      method: 'POST',
      data: {
        email
      }
    }).then((res: AxiosResponse) => {
      if (res.status === 200) {
        setError('');
        setSent(true);
      } else {
        setError(i18n('auth.forgotPassword.error'));
      }
    }).catch((_err: AxiosError) => {
      setError(i18n('auth.forgotPassword.error'));
    });
  };
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };
  const handleMoreHelp = () => {
    window.open('https://powerchord.atlassian.net/servicedesk/customer/kb/view/723222633', '_blank');
  };
  const renderMessage = () => {
    const labels: any = {
      forgotIntro: i18n('auth.forgot.introduction'),
      resetLinkSent: i18n('auth.help.resetLinkSent')
    };
    let message = labels.forgotIntro;
    let className = 'message';
    if (sent) {
      message = labels.resetLinkSent;
      className = 'attention';
    }
    return <p className={className} data-sentry-component="renderMessage" data-sentry-source-file="ForgotPassword.tsx">{message}</p>;
  };
  const labels: any = {
    ctaLoginHere: i18n('auth.cta.reset.login'),
    moreHelp: i18n('auth.moreHelp'),
    password: i18n('auth.passwordLabel'),
    passwordConfirm: i18n('auth.passwordConfirmation'),
    resetIntro: i18n('auth.reset.introduction'),
    resetSuccessful: i18n('auth.reset.successful'),
    submit: i18n('auth.cta.sendEmail'),
    email: i18n('auth.emailLabel'),
    goBack: i18n('auth.cta.goBack'),
    cancel: i18n('global.action.labels.cancel')
  };
  return <div className="LoginScreen" data-sentry-component="ForgotPassword" data-sentry-source-file="ForgotPassword.tsx">
      <div className="Login">
        <div className="form">
          <div className="logo" />
          {renderMessage()}
          {sent ? null : <TextField error={error} label={labels.email} onInput={handleInputChange} onKeyDown={handleKeyDown} type="email" />}
          <div className="buttons">
            <Button className="moreHelp" onClick={handleMoreHelp} data-sentry-element="Button" data-sentry-source-file="ForgotPassword.tsx">
              {labels.moreHelp}
            </Button>
            <Button className="cancel" onClick={() => history.push('/login')} variant="contained" color={sent ? 'primary' : 'default'} disableElevation data-sentry-element="Button" data-sentry-source-file="ForgotPassword.tsx">
              {sent ? labels.goBack : labels.cancel}
            </Button>
            {sent ? null : <Button className="submit" variant="contained" color="primary" disableElevation onClick={handleSubmit}>
                {labels.submit}
              </Button>}
          </div>
        </div>
      </div>
    </div>;
};
export default ForgotPassword;