export const SHOW_MODAL: '[ui] Show Modal' = '[ui] Show Modal'
export const SHOW_MODAL_V2: '[ui] Show Modal V2' = '[ui] Show Modal V2'
export const HIDE_MODAL: '[ui] Hide Modal' = '[ui] Hide Modal'
export const SHOW_LOADER: '[ui] Show Loader' = '[ui] Show Loader'
export const HIDE_LOADER: '[ui] Hide Loader' = '[ui] Hide Loader'
export const CREATE_NOTIFICATION: '[ui] Create Notification' = '[ui] Create Notification'
export const CLEAR_NOTIFICATION: '[ui] Clear Notification' = '[ui] Clear Notification'

interface ShowLoaderAction {
  type: typeof SHOW_LOADER
  payload: string
}

export const showLoader = (entity: string): ShowLoaderAction => ({
  type: SHOW_LOADER,
  payload: entity,
})

interface HideLoaderAction {
  type: typeof HIDE_LOADER
  payload: string
}

export const hideLoader = (entity: string): HideLoaderAction => ({
  type: HIDE_LOADER,
  payload: entity,
})

type Notification = {}

export interface CreateNotificationAction {
  type: typeof CREATE_NOTIFICATION
  payload: Notification
}

export const createNotification = (notification: Notification): CreateNotificationAction => ({
  type: CREATE_NOTIFICATION,
  payload: notification,
})

interface ClearNotificationAction {
  type: typeof CLEAR_NOTIFICATION
  payload?: string
}

export const clearNotification = (id?: string): ClearNotificationAction => ({
  type: CLEAR_NOTIFICATION,
  payload: id,
})

export type ModalBody = object | Array<any> | null

export type Modal = {
  body: ModalBody
  buttons: object[]
  callback?: Function
}

interface ShowModalAction {
  type: typeof SHOW_MODAL
  payload: Modal | { modal: Modal }
}

export const showModal = (buttons: object[], body: ModalBody, callback?: Function): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: {
    body,
    buttons,
    callback,
  },
})

export interface HideModalAction {
  type: typeof HIDE_MODAL
}

export const hideModal = (): HideModalAction => ({
  type: HIDE_MODAL,
})

export enum ModalType {
  TextArea,
  Confirm,
  Acknowledge,
}

export type ModalProps = {
  title: string
  body: string
  type: ModalType
  helpText?: string
  callback?: Function
}

export interface ShowModalV2Action {
  type: typeof SHOW_MODAL_V2
  payload: ModalProps & { callback?: Function }
}

export const showModalV2 = (modalProps: ModalProps, callback?: Function): ShowModalV2Action => ({
  type: SHOW_MODAL_V2,
  payload: {
    ...modalProps,
    callback,
  },
})

export type UIActionTypes =
  | ShowLoaderAction
  | HideLoaderAction
  | CreateNotificationAction
  | ClearNotificationAction
  | ShowModalAction
  | HideModalAction
  | ShowModalV2Action
