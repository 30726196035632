import { Ownership, ActionDoc } from '../../types/Doc'

export enum ActionType {
  WebhookHTTPAction = 'action.webhook.http',
  NotificationEmailAction = 'action.notification.email',
  NotificationSMSAction = 'action.notification.sms',
  LeadResponseEmailAction = 'action.lead.response.email',
  SayHelloAction = 'action.say.hello',
  Unspecified = '',
}

export enum CachedActionIDs {
  DefaultLeadReminderEmail = '6c43409e-5509-4422-8342-1ac45c7a9019',
}

export type Content = {
  LocalizedTemplates?: LocalizedTemplates
  Type: string
  URL?: string
}

type ContentKeys = keyof Content

export type LocalizedTemplates = {
  'en-US': string
}

export type Action = ActionDoc &
  Ownership & {
    ID?: string
    Type: ActionType
    Content?: Content
    Name?: string
    Description?: string
    ApplyToTree: boolean
  }

export type ActionKeys = keyof Action | `Content.${ContentKeys}`

export type ActionToUpdate = {
  action: Action
  url: string
  method: 'POST' | 'PUT' | 'DELETE'
  createAssociation?: boolean
  preloadedAssociationID?: string
}
