import { find } from 'lodash'
import { createSelector } from 'reselect'
import { Field, Locale, Schema } from '../schemas'
import { getListableFields } from '../schemas/listableFields'
import { AppState } from '../store'

export const schemaSelector = (state: AppState): Schema => state.schema

export const listableFieldsSelector = (state: AppState): any => {
  // @ts-ignore strange ramda linting err
  return getListableFields(state.schema, {})
}

export const localesSelector = (state: AppState): Locale[] => state?.schema?.availableLocales ?? []

export const shouldModerateSelector = (state: AppState): boolean => state?.schema?.shouldModerate ?? false

export const modifiedCountSelector = (state: AppState): number => state?.schema?.modifiedCount ?? 0

export const pendingApprovalSelector = (state: AppState): boolean => state?.schema?.modifiedCount > 0 ?? false

export const fieldSchemaSelector = (fieldKey: string) =>
  createSelector(schemaSelector, (schema: Schema) => find(schema.fields, (field: Field) => field.key === fieldKey))
