import { AppState } from '../store'
import { Organization, Scope, User } from '../types'
import { scopeHeadersSelector } from './scope'
import { ScopeHeaders } from '../selectors/scope'
import { localeSelector } from './i18n'

export type AuthHeaders = ScopeHeaders & {
  'Content-Type': string
  'Accept-Language': string
  Authorization: string
  OrganizationID: string
}

export const authHeaderSelector = (state: AppState, scope?: Scope, asOwnerOverride?: string): AuthHeaders => {
  const token = state?.session?.authorizationToken ?? ''
  const orgID = state?.session?.organization?.ID ?? ''
  const locale = localeSelector(state)

  if (!isValidSession(state)) {
    return {} as AuthHeaders
  }

  const headers: AuthHeaders = {
    'Content-Type': 'application/json',
    'Accept-Language': locale,
    Authorization: token,
    OrganizationID: orgID,
    ...scopeHeadersSelector(state, scope, asOwnerOverride),
  }

  return headers
}

export const authRequiredSelector = (state: AppState): boolean => !state?.session?.authorizationToken

export const sessionReadySelector = (state: AppState): boolean => {
  const hasToken = !!state?.session?.authorizationToken
  const hasSchemas = state?.schemas?.length > 0
  return hasToken && hasSchemas
}

export const focusedOrgSelector = (state: AppState): Organization | undefined => state?.session?.organization

export const userSelector = (state: AppState): User => state.session.user

export const isValidSession = (state: AppState): boolean => {
  const date = new Date()
  const token = state?.session?.authorizationTokenExpiration
  if (token) {
    return new Date(token) > date
  }
  return false
}

export const loginErrorSelector = (state: AppState): string => state?.session?.error ?? ''

export const passwordResetErrorSelector = (state: AppState): string => state?.session?.error ?? ''

export const redirectSelector = (state: AppState): any => state?.session?.redirect

export const roleSelector = (state: AppState): any => state?.session?.context?.role ?? ''
