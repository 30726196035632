import {
  apiRequest,
  GET_SCHEMAS,
  GET_SCHEMAS_SUCCESS,
  getFilters,
  LIST_PAGE,
  loadHome,
  LOGIN_PAGE,
  Menu,
  RENDER_MENU,
  SCHEMAS,
  setMenu,
  setSchemas,
} from '../actions'
import parseModules from '../schemas/parseModules'
import { flagSelector, membershipSelector, selectAnyFlag } from '../selectors'
import { I18n, i18n as i18nSelector } from '../selectors/i18n'
import { AppState, Middleware } from '../store'

const appendMenuItems = (state: AppState, groups: any, i18n: I18n) => {
  const menuItems: Menu = [
    {
      isAllowed: true,
      category: 'helpCenter',
      key: 'helpCenter',
      label: i18n('nav.labels.helpCenter'),
      weblink: 'https://powerchord.atlassian.net/servicedesk/customer/portals',
      presentation: 'webLink',
      items: [],
      sortOrder: 99,
    },
  ]

  const isArchitectUser = flagSelector(state, 'architect')
  const canViewRulesAdmin = selectAnyFlag(state, 'ruleAdmins', 'ruleDevs')
  const memberships = membershipSelector(state)

  if (canViewRulesAdmin) {
    menuItems.unshift({
      isAllowed: true,
      category: 'v3/platform/rules',
      key: 'rules',
      label: i18n('nav.labels.rules.platformSystem'),
      weblink: '',
      presentation: '',
      items: [],
    })
  }
  if (isArchitectUser || canViewRulesAdmin || HasRulesManagementMembership(memberships)) {
    // @todo turn these into a grouped menu item
    menuItems.unshift({
      isAllowed: true,
      category: 'v3/rules',
      key: 'rules',
      label: i18n('nav.labels.rules.customOrganization'),
      weblink: '',
      presentation: '',
      items: [],
    })
  }

  return groups.concat(menuItems)
}

//TODO add membership check here
function HasRulesManagementMembership(memberships: any): boolean {
  return false
}

export const schemasMiddleware: Middleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  const state = getState()

  switch (action.type) {
    case GET_SCHEMAS:
      dispatch(
        apiRequest({
          method: 'GET',
          // AB#3674 scope schemas request to focused organization
          url: `/schemas?${state.session?.organization?.ID ? 'organizationID=' + state.session.organization?.ID : ''}`,
          feature: SCHEMAS,
        })
      )
      break

    case GET_SCHEMAS_SUCCESS: {
      const schemas = action.payload
      dispatch(setSchemas(schemas))
      if (LIST_PAGE === state?.location?.type) {
        dispatch(getFilters())
      }
      // re-route to home page on login, after the schemas have loaded
      if (LOGIN_PAGE === state?.location?.type) {
        dispatch(loadHome())
      }
      break
    }

    case RENDER_MENU: {
      const i18n = i18nSelector(state)
      const modules = parseModules(state.schemas)
      const menu = appendMenuItems(state, modules, i18n)
      dispatch(setMenu(menu))
      break
    }
  }
}
