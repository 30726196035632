import { AnyAction, Middleware } from 'redux'
import { SET_LOCAL_STORAGE } from '../actions/storage'
import { setLocalStorage } from '../util'

export const storageMiddleware: Middleware = () => (next: Function) => (action: AnyAction) => {
  next(action)

  switch (action.type) {
    case SET_LOCAL_STORAGE:
      setLocalStorage(action.payload.key, action.payload.value)
      break
  }
}
