import { Query } from 'redux-first-router' // typechecking for router
import { Scope } from '../types'
import { V3LeadsCategory } from '../schemas/v3Mocks'

export const PAGE = '[page]'
export const LOGIN_PAGE = '[page] Login'
export const EDITOR_PAGE = '[page] Editor'
export const LIST_PAGE = '[page] List'
export const ACCOUNT_PAGE = '[page] Account'
export const SECURITY_PAGE = '[page] Security'
export const ARCHITECT_APP = '[page] Architect'
export const HOME_PAGE = '[page] Home'
export const FORGOT_PASSWORD = '[page] Forgot Password'
export const RESET_PASSWORD = '[page] RESET_PASSWORD'
export const OLD_SB_ROUTE = '[page] OLD_SB_ROUTE'
export const ANALYTICS_PAGE = '[page] Analytics'
export const LEADS_LIST = '[page] Leads List'
export const LEAD_DETAILS = '[page] Leads Detail'
export const TRIGGERS_LIST = '[page] Triggers List'
export const TRIGGER_DETAILS = '[page] Trigger Detail'

export enum EditorMode {
  DUPLICATE = 'duplicate',
  TRANSLATE = 'translate',
}

export interface LoadLoginAction {
  type: typeof LOGIN_PAGE
}

export const loadLogin = (): LoadLoginAction => ({
  type: LOGIN_PAGE,
})

interface LoadHomeAction {
  type: typeof HOME_PAGE
}

export const loadHome = (): LoadHomeAction => ({
  type: HOME_PAGE,
})

export interface LoadEditorAction {
  type: typeof EDITOR_PAGE
  payload: {
    category: string
    resource: string
    id: string
    locale?: string
    localeID?: string
    mode?: string
  }
}

export const loadEditor = (category: string, resource: string, id: string): LoadEditorAction => ({
  type: EDITOR_PAGE,
  payload: {
    category,
    resource,
    id,
  },
})

export const loadEditorNew = (category: string, resource: string): LoadEditorAction => ({
  type: EDITOR_PAGE,
  payload: {
    category,
    resource,
    id: 'new',
  },
})

export const loadTranslator = (category: string, resource: string, id: string, localeID?: string, locale?: string): LoadEditorAction => ({
  type: EDITOR_PAGE,
  payload: {
    category,
    resource,
    id,
    locale,
    localeID,
    mode: EditorMode.TRANSLATE,
  },
})

export const loadDuplicator = (category: string, resource: string, id: string): LoadEditorAction => ({
  type: EDITOR_PAGE,
  payload: {
    category,
    resource,
    id,
    mode: EditorMode.DUPLICATE,
  },
})

export interface LoadListAction {
  type: typeof LIST_PAGE
  payload: {
    category: string
  }
}

export const loadList = (category: string): LoadListAction => ({
  type: LIST_PAGE,
  payload: {
    category,
  },
})

interface LoadAccountAction {
  type: typeof ACCOUNT_PAGE
}

export const loadAccount = (): LoadAccountAction => ({
  type: ACCOUNT_PAGE,
})

interface LoadSecurityAction {
  type: typeof SECURITY_PAGE
}

export const loadSecurity = (): LoadSecurityAction => ({
  type: SECURITY_PAGE,
})

export interface LoadForgotPasswordAction {
  type: typeof FORGOT_PASSWORD
}

export const loadForgotPassword = (): LoadForgotPasswordAction => ({
  type: FORGOT_PASSWORD,
})

export type LoadArchitectAction = {
  type: typeof ARCHITECT_APP
  payload: loadArchitectPayload
}

export type loadArchitectPayload = {
  siteID: string
  pages?: 'pages'
  pageID?: string
  query?: Query
}

export const loadArchitect = (payload: loadArchitectPayload): LoadArchitectAction => ({
  type: ARCHITECT_APP,
  payload,
})

type LoadAnalyticsAction = {
  type: typeof ANALYTICS_PAGE
  dashboardType: string
}

type LoadPageAction = LoadListAction | LoadAnalyticsAction | LoadLeadsListAction

export const loadAnalytics = (dashboardType: string): LoadAnalyticsAction => ({
  type: ANALYTICS_PAGE,
  dashboardType,
})

export const loadDefaultPage = (category: string): LoadPageAction => {
  switch (category) {
    case '':
      // default to leads category
      return loadLeadsList()

    case 'analytics':
      return loadAnalytics(category)

    default:
      return loadList(category)
  }
}

interface LoadLeadsListAction {
  type: typeof LEADS_LIST
  query?: Query
  payload: {
    category: string
    resource: string
  }
}

export const loadLeadsList = (query?: Query): LoadLeadsListAction => {
  return {
    type: LEADS_LIST,
    query,
    payload: {
      category: V3LeadsCategory,
      resource: 'leads',
    },
  }
}

interface LoadLeadDetailsAction {
  type: typeof LEAD_DETAILS
  payload: {
    category: string
    id: string
  }
}

export const loadLeadDetail = (id: string): LoadLeadDetailsAction => ({
  type: LEAD_DETAILS,
  payload: {
    category: V3LeadsCategory,
    id,
  },
})

interface LoadTriggersListAction {
  type: typeof TRIGGERS_LIST
  payload: {
    isPlatform?: string
  }
}

export const loadTriggersList = (scope: Scope): LoadTriggersListAction => {
  const isPlatform = scope === Scope.PlatformSystem ? 'platform' : undefined
  return {
    type: TRIGGERS_LIST,
    payload: {
      isPlatform: isPlatform,
    },
  }
}

interface LoadTriggerDetailsAction {
  type: typeof TRIGGER_DETAILS
  payload: {
    isPlatform?: string
    id: string
  }
}

export const loadTriggerDetail = (scope: Scope, id: string): LoadTriggerDetailsAction => {
  const isPlatform = scope === Scope.PlatformSystem ? 'platform' : undefined
  return {
    type: TRIGGER_DETAILS,
    payload: {
      isPlatform: isPlatform,
      id: id,
    },
  }
}
