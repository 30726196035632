import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { API_REQUEST, apiError, apiSuccess, hideLoader, showLoader } from '../actions'
import config from '../config'
import { authHeaderSelector } from '../selectors'
import { Middleware } from '../store'
import * as Sentry from '@sentry/react'

const API = config.api
const cancel: any = {}

export type RequestConfig = AxiosRequestConfig & {
  payload?: any
  type: string
}

export const doRequest = async <T>(config: RequestConfig): Promise<AxiosResponse<T> | undefined> => {
  const { headers, method, payload, params, responseType, type, url } = config

  if (!url) {
    return Promise.reject('invalid url')
  }

  if (cancel[type]) {
    cancel[type](`${type} cancelled (url: ${url})`)
  }

  let fullUrl: string

  if (url.indexOf('http') === 0) {
    fullUrl = url
  } else {
    fullUrl = API + url
  }

  return axios({
    url: fullUrl,
    method,
    data: payload || 'boolean' === typeof payload || payload === '' ? JSON.stringify(payload) : null,
    headers,
    params,
    responseType,
    cancelToken: new axios.CancelToken(function (c: any) {
      cancel[type] = c
    }),
  })
    .then((response) => {
      cancel[type] = null
      return response
    })
    .catch((error: AxiosError) => {
      cancel[type] = null
      Sentry.captureException(error, {
        tags: {
          source: 'doRequest',
          statusCode: error.code,
          type: config.type,
        },
        contexts: {
          error: {
            message: error.message,
          },
          request: {
            url: fullUrl,
            method: method,
            config: config,
          },
        },
      })
      // handle axios cancellations more gracefully
      if (axios.isCancel(error)) {
        return undefined
      }

      if (error?.message) {
        console.info(error.message)
      }

      console.error('API ERROR: ', error)
      throw error
    })
}

export const apiMiddleware: Middleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  if (action.type.includes(API_REQUEST)) {
    const { feature, resource } = action.meta

    dispatch(showLoader(feature))

    doRequest({
      ...action.meta,
      headers: authHeaderSelector(getState(), action?.meta?.scope, action?.meta?.asOwnerOverride),
      payload: action.payload,
      type: action.type,
    })
      .then((response) => {
        if (!response) {
          return
        }
        if (401 === response.status) {
          dispatch(apiError(response.data, feature))
        } else if (response.status > 299) {
          dispatch(apiError(response.data, feature))
          dispatch(hideLoader(feature))
        } else {
          dispatch(apiSuccess(response.data, feature, resource, response.headers))
          dispatch(hideLoader(feature))
        }
      })
      .catch((error: AxiosError) => {
        if (error.response && error.response.data) {
          dispatch(apiError(error.response.data, feature))
          dispatch(hideLoader(feature))
        } else {
          dispatch(apiError(error.response, feature))
          dispatch(hideLoader(feature))
        }
      })
  }
}
