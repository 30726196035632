import config, { Environment } from '../config'

export type FocalPoint = {
  x: number
  y: number
}

export function toImgixURL(url: string): string {
  const { environment } = config

  switch (environment) {
    case Environment.Production:
      return url.replace('s3.amazonaws.com/clients.powerchord.io', 'powerchord.imgix.net')

    default:
      // do not replace environment in url because
      // of s3 urls in the wrong environments
      // such as clients-dev showing up in qa
      return url.replace('s3.amazonaws.com/clients-', '').replace('.powerchord.io', '-powerchord.imgix.net')
  }
}

export const toImgixFocalDebug = (url: string, fp: FocalPoint): string => {
  if (!url) {
    return ''
  }
  url = toImgixURL(url)
  return `${url}?fp-x=${fp.x}&fp-y=${fp.y}&fp-debug=true`
}

export const toImgixThumbnail = (url: string): string => {
  url = toImgixURL(url)
  return `${url}?fit=original&auto=compress,format&h=160&w=160`
}
