import React, { useState, useRef } from 'react';
import { Button as MaterialButton, Popper, MenuList, MenuItem, Paper, Grow, LinearProgress, Theme, ClickAwayListener, makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ArrowDropDown } from '@material-ui/icons';
import { ButtonProps } from '@material-ui/core/Button';
type Props = ButtonProps & {
  options?: any[] | null;
  loading?: boolean;
  buttontext?: string;
};
const useStyles = makeStyles((theme: Theme) => createStyles({
  arrow: {
    width: theme.spacing(1),
    minWidth: theme.spacing(1)
  },
  root: {
    position: 'relative'
  },
  progress: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    borderRadius: '0 0 5px 5px'
  }
}));
const Button = (props: Props) => {
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const {
    children,
    options,
    onClick,
    loading,
    className,
    ...rest
  } = props;
  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }
  function handleClose(_event: any) {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //   return;
    // }

    setOpen(false);
  }
  if (options) {
    return <div>
        <MaterialButton {...rest} ref={anchorRef} onClick={handleToggle}>
          {rest.buttontext}
          <ArrowDropDown />
        </MaterialButton>
        <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
          {({
          TransitionProps,
          placement
        }) => <Grow {...TransitionProps} style={{
          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
        }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {options.map((option: any) => {
                  return <MenuItem key={option.value} onClick={() => {
                    onClick && onClick(option.value);
                  }}>
                          {option.label}
                        </MenuItem>;
                })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>}
        </Popper>
      </div>;
  }
  return <div className={clsx(classes.root, className)} data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      <MaterialButton data-ci={`button-${children}`} {...rest} onClick={onClick} disableElevation data-sentry-element="MaterialButton" data-sentry-source-file="Button.tsx">
        {children}
      </MaterialButton>
      {loading && <LinearProgress className={classes.progress} />}
    </div>;
};
export default Button;