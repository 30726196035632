export enum SearchActionType {
  CLEAR = '[search] Clear',
  SET = '[search] Set',
}

type ClearSearchAction = {
  delayed: boolean
  refetch: boolean
  type: SearchActionType.CLEAR
}

export const clearSearch = (refetch = true): ClearSearchAction => ({
  delayed: false,
  type: SearchActionType.CLEAR,
  refetch,
})

type SetSearchAction = {
  delayed: boolean
  refetch: boolean
  text: string
  type: SearchActionType.SET
}

export const setSearch = (text: string, refetch = true, delayed = true): SetSearchAction => ({
  type: SearchActionType.SET,
  delayed,
  refetch,
  text,
})

export type AnySearchAction = SetSearchAction | ClearSearchAction
