import { Component, HttpMethod, Page, Site } from '../types'

type Form = 'siteSettings'

export enum ArchitectActionType {
  ARCHITECT = '[architect]',
  GET_PAGES = `[architect] Fetch Pages`,
  SET_PAGES = `[architect] Set Pages`,
  SET_PAGE = `[architect] Set Page`,
  RENDER_PAGE = `[architect] Render Page`,
  SET_HTML = `[architect] Set HTML`,
  GET_SITE = `[architect] Fetch Site`,
  SET_SITE = `[architect] Set Site`,
  GET_FORM = `[architect] Fetch Form`,
  SET_FORM = `[architect] Set Form`,
  SUBMIT_FORM = `[architect] Submit Form`,
  REORDER_SECTION = `[architect] Reorder Section`,
  TOGGLE_VIEWPORT = `[architect] Toggle Viewport`,
  ADD_PAGE = `[architect] Add Page`,
  EDIT_SECTION = `[architect] Edit Section`,
  ADD_SECTION = `[architect] Add Section`,
  DELETE_SECTION = `[architect] Delete Section`,
  GET_COMPONENTS = `[architect] Get Components`,
  SET_COMPONENTS = `[architect] Set Components`,
  PUBLISH_PAGE = `[architect] Publish Page`,
}

export const forms: Record<string, Form> = {
  siteSettings: 'siteSettings',
}

type GetPagesAction = {
  type: ArchitectActionType.GET_PAGES
  payload: string
}

export const getPages = (siteID: string): GetPagesAction => ({
  type: ArchitectActionType.GET_PAGES,
  payload: siteID,
})

type SetPagesAction = {
  type: ArchitectActionType.SET_PAGES
  payload: Page[]
}

export const setPages = (pages: Page[]): SetPagesAction => ({
  type: ArchitectActionType.SET_PAGES,
  payload: pages,
})

type SetPageAction = {
  type: ArchitectActionType.SET_PAGE
  payload: string
}

export const setPage = (pageID: string): SetPageAction => ({
  type: ArchitectActionType.SET_PAGE,
  payload: pageID,
})

type RenderPageAction = {
  type: ArchitectActionType.RENDER_PAGE
}

export const renderPage = (): RenderPageAction => ({
  type: ArchitectActionType.RENDER_PAGE,
})

type SetHtmlAction = {
  type: ArchitectActionType.SET_HTML
  payload: string
}

export const setHtml = (html: string): SetHtmlAction => ({
  type: ArchitectActionType.SET_HTML,
  payload: html,
})

type GetSiteAction = {
  type: ArchitectActionType.GET_SITE
  payload: string
}

export const getSite = (id: string): GetSiteAction => ({
  type: ArchitectActionType.GET_SITE,
  payload: id,
})

type SetSiteAction = {
  type: ArchitectActionType.SET_SITE
  payload: Site
}

export const setSite = (site: Site): SetSiteAction => ({
  type: ArchitectActionType.SET_SITE,
  payload: site,
})

type GetFormAction = {
  type: ArchitectActionType.GET_FORM
  payload: Form
}

export const getForm = (form: Form): GetFormAction => ({
  type: ArchitectActionType.GET_FORM,
  payload: form,
})

type ArchitectFetchAction = {
  type: ArchitectActionType.GET_FORM
  payload: {
    url: string
  }
}

export const getComponentSettings = (pageID: string, sectionID: string, areaID: string, componentHID: string): ArchitectFetchAction => ({
  type: ArchitectActionType.GET_FORM,
  payload: {
    url: `/pages/${pageID}/sections/${sectionID}/areas/${areaID}/components/${componentHID}`,
  },
})

export const getPageSettings = (pageID: string): ArchitectFetchAction => ({
  type: ArchitectActionType.GET_FORM,
  payload: {
    url: `/pages/${pageID}`,
  },
})

export const addPage = (siteID: string): ArchitectFetchAction => ({
  type: ArchitectActionType.GET_FORM,
  payload: {
    url: `/pages/new?siteID=${siteID}`,
  },
})

export const editSection = (pageID: string, sectionID: string): ArchitectFetchAction => ({
  type: ArchitectActionType.GET_FORM,
  payload: {
    url: `/pages/${pageID}/sections/${sectionID}`,
  },
})

export const placeComponent = (pageID: string, sectionID: string, areaID: string, HID: string): ArchitectFetchAction => ({
  type: ArchitectActionType.GET_FORM,
  payload: {
    url: `/pages/${pageID}/sections/${sectionID}/areas/${areaID}/components/new?hid=${HID}`,
  },
})

type SetFormAction = {
  type: ArchitectActionType.SET_FORM
  payload: string
}

export const setForm = (form: string): SetFormAction => ({
  type: ArchitectActionType.SET_FORM,
  payload: form,
})

type SubmitFormAction = {
  type: ArchitectActionType.SUBMIT_FORM
  payload: {
    url: string
    method: HttpMethod
    data: FormData
  }
}

export const submitForm = (url: string, method: HttpMethod, data: FormData): SubmitFormAction => ({
  type: ArchitectActionType.SUBMIT_FORM,
  payload: {
    url,
    method,
    data,
  },
})

type ReorderSectionAction = {
  type: ArchitectActionType.REORDER_SECTION
  payload: {
    pageID: string
    sectionID: string
    index: number
  }
}

export const reorderSection = (pageID: string, sectionID: string, index: number): ReorderSectionAction => ({
  type: ArchitectActionType.REORDER_SECTION,
  payload: {
    pageID,
    sectionID,
    index,
  },
})

type ToggleViewportAction = {
  type: ArchitectActionType.TOGGLE_VIEWPORT
  payload: boolean
}

export const toggleViewport = (showMobile: boolean): ToggleViewportAction => ({
  type: ArchitectActionType.TOGGLE_VIEWPORT,
  payload: showMobile,
})

type AddSectionAction = {
  type: ArchitectActionType.ADD_SECTION
  payload: {
    pageID: string
    index: number
  }
}

export const addSection = (pageID: string, index: number): AddSectionAction => ({
  type: ArchitectActionType.ADD_SECTION,
  payload: {
    pageID,
    index,
  },
})

type DeleteSectionAction = {
  type: ArchitectActionType.DELETE_SECTION
  payload: {
    pageID: string
    sectionID: string
  }
}

export const deleteSection = (pageID: string, sectionID: string): DeleteSectionAction => ({
  type: ArchitectActionType.DELETE_SECTION,
  payload: {
    pageID,
    sectionID,
  },
})

type GetComponentsAction = {
  type: ArchitectActionType.GET_COMPONENTS
}

export const getComponents = (): GetComponentsAction => ({
  type: ArchitectActionType.GET_COMPONENTS,
})

type SetComponentsAction = {
  type: ArchitectActionType.SET_COMPONENTS
  payload: Component[]
}

export const setComponents = (components: Component[]): SetComponentsAction => ({
  type: ArchitectActionType.SET_COMPONENTS,
  payload: components,
})

type PublishPageAction = {
  type: ArchitectActionType.PUBLISH_PAGE
  payload: string
}

export const publishPage = (pageID: string): PublishPageAction => ({
  type: ArchitectActionType.PUBLISH_PAGE,
  payload: pageID,
})

export type AnyArchitectAction =
  | GetPagesAction
  | SetPagesAction
  | SetPageAction
  | RenderPageAction
  | SetHtmlAction
  | GetSiteAction
  | SetSiteAction
  | GetFormAction
  | ArchitectFetchAction
  | SetFormAction
  | SubmitFormAction
  | ReorderSectionAction
  | ToggleViewportAction
  | AddSectionAction
  | DeleteSectionAction
  | GetComponentsAction
  | SetComponentsAction
  | PublishPageAction
