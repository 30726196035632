export enum PaginationActionType {
  NEXT_PAGE = '[pagination] Next',
  PREV_PAGE = '[pagination] Prev',
  SET_PAGE = '[pagination] Set Page',
  SET_SKIP = '[pagination] Set Skip',
  SET_LIMIT = '[pagination] Set Limit',
  SET_TOTAL = '[pagination] Set Total',
  RESET_PAGINATION = '[pagination] Reset',
}

type NextPageAction = {
  type: PaginationActionType.NEXT_PAGE
}

export const nextPage = (): NextPageAction => ({
  type: PaginationActionType.NEXT_PAGE,
})

type PreviousPageAction = {
  type: PaginationActionType.PREV_PAGE
}

export const prevPage = (): PreviousPageAction => ({
  type: PaginationActionType.PREV_PAGE,
})

type SetPaginationPageAction = {
  type: PaginationActionType.SET_PAGE
  payload: number
}

export const setPaginationPage = (page: number): SetPaginationPageAction => ({
  type: PaginationActionType.SET_PAGE,
  payload: page,
})

type SetSkipAction = {
  type: PaginationActionType.SET_SKIP
  payload: number
}

export const setSkip = (skipCount: number): SetSkipAction => ({
  type: PaginationActionType.SET_SKIP,
  payload: skipCount,
})

type SetLimitAction = {
  type: PaginationActionType.SET_LIMIT
  payload: number
}

export const setLimit = (limit: number): SetLimitAction => ({
  type: PaginationActionType.SET_LIMIT,
  payload: limit,
})

type SetTotalAction = {
  type: PaginationActionType.SET_TOTAL
  payload: number
}

export const setTotal = (total: number): SetTotalAction => ({
  type: PaginationActionType.SET_TOTAL,
  payload: total,
})

type ResetPaginationAction = {
  type: PaginationActionType.RESET_PAGINATION
}

export const resetPagination = (): ResetPaginationAction => ({
  type: PaginationActionType.RESET_PAGINATION,
})

export type AnyPaginationAction =
  | NextPageAction
  | PreviousPageAction
  | SetPaginationPageAction
  | SetSkipAction
  | SetLimitAction
  | SetTotalAction
  | ResetPaginationAction
