import { apiRequest, API_SUCCESS } from '../actions/api'
import { setAutoComplete } from '../actions/autocomplete'
import { GET_TAGS } from '../actions/tags'
import { Middleware } from '../store'

export const tagsMiddleware: Middleware = ({ dispatch }) => (next) => (action) => {
  next(action)

  switch (action.type) {
    case GET_TAGS:
      dispatch(
        apiRequest({
          url: '/tagFilters',
          method: 'GET',
          params: {
            prefix: action.payload,
          },
          feature: GET_TAGS,
        })
      )
      break

    case `${GET_TAGS} ${API_SUCCESS}`:
      dispatch(setAutoComplete(action.payload))
      break
  }
}
