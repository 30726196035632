import { ContentConfig, ContentConfigResponse, Lead, LeadQueryResponse, LeadType, Patchable } from '../types'

export const LeadsFormsDocumentCategory = 'formDocuments'

export enum V3LeadActionTypes {
  GET_LEAD = '[v3leads] GET_LEAD',
  GET_LEAD_SUCCESS = '[v3leads] GET_LEAD API_SUCCESS',
  GET_LEAD_ERROR = '[v3leads] GET_LEAD API_ERROR',
  GET_LEADS = '[v3leads] GET_LEADS',
  GET_LEADS_SUCCESS = '[v3leads] GET_LEADS API_SUCCESS',
  SET_LEADS = '[v3leads] SET_LEADS',
  SET_LEAD = '[v3leads] SET_LEAD',
  UPDATE_LEAD = '[v3leads] UPDATE_LEAD',
  SAVE_LEAD = '[v3leads] SAVE_LEAD',
  SAVE_LEAD_SUCCESS = '[v3leads] SAVE_LEAD API_SUCCESS',
  RELOAD_LEAD = '[v3leads] RELOAD_LEAD',
  RELOAD_LEAD_SUCCESS = '[v3leads] RELOAD_LEAD API_SUCCESS',
  SET_CONTENT_CONFIGS = '[v3leads] SET_CONTENT_CONFIGS',
  SET_LEAD_TYPES = '[v3leads] SET_LEAD_TYPES',
  GET_CONTENT_CONFIGS = '[v3leads] GET_CONTENT_CONFIGS',
  GET_CONTENT_CONFIGS_SUCCESS = '[v3leads] GET_CONTENT_CONFIGS API_SUCCESS',
  GET_LEAD_TYPES = '[v3leads] GET_LEAD_TYPES',
  GET_LEAD_TYPES_SUCCESS = '[v3leads] GET_LEAD_TYPES API_SUCCESS',
  EXPORT_LEADS = `[v3leads] EXPORT_LEADS`,
  EXPORT_LEADS_SUCCESS = `[v3leads] EXPORT_LEADS API_SUCCESS`,
}

interface GetV3LeadAction {
  type: V3LeadActionTypes.GET_LEAD
  payload: string
}

export const getV3Lead = (id: string): GetV3LeadAction => ({
  type: V3LeadActionTypes.GET_LEAD,
  payload: id,
})

interface GetV3LeadSuccessAction {
  type: V3LeadActionTypes.GET_LEAD_SUCCESS
  payload: Lead
}

interface GetV3LeadErrorAction {
  type: V3LeadActionTypes.GET_LEAD_ERROR
  payload: {
    message: string
  }
}

interface GetV3LeadsAction {
  type: V3LeadActionTypes.GET_LEADS
}

export const getV3Leads = (): GetV3LeadsAction => ({
  type: V3LeadActionTypes.GET_LEADS,
})

interface GetV3LeadsSuccessAction {
  type: V3LeadActionTypes.GET_LEADS_SUCCESS
  payload: LeadQueryResponse
}

interface SetV3LeadAction {
  type: V3LeadActionTypes.SET_LEAD
  payload: Lead
}

export const setV3Lead = (payload: Lead): SetV3LeadAction => ({
  type: V3LeadActionTypes.SET_LEAD,
  payload,
})

interface SetV3LeadsAction {
  type: V3LeadActionTypes.SET_LEADS
  payload: Lead[]
}

export const setV3Leads = (payload: Lead[]): SetV3LeadsAction => ({
  type: V3LeadActionTypes.SET_LEADS,
  payload,
})

interface UpdateV3LeadAction {
  type: V3LeadActionTypes.UPDATE_LEAD
  payload: Lead
}

export const updateV3Lead = (payload: Lead): UpdateV3LeadAction => ({
  type: V3LeadActionTypes.UPDATE_LEAD,
  payload,
})

export interface SaveV3LeadAction {
  type: V3LeadActionTypes.SAVE_LEAD
  payload: Patchable<Lead>
}

export const saveV3Lead = (payload: Patchable<Lead>): SaveV3LeadAction => ({
  type: V3LeadActionTypes.SAVE_LEAD,
  payload,
})

interface SaveV3LeadSuccessAction {
  type: V3LeadActionTypes.SAVE_LEAD_SUCCESS
  payload: Lead
}

interface SetV3ContentConfigs {
  type: V3LeadActionTypes.SET_CONTENT_CONFIGS
  contentConfigs: ContentConfig[]
}

export const setV3ContentConfigs = (contentConfigs: ContentConfig[]): SetV3ContentConfigs => ({
  type: V3LeadActionTypes.SET_CONTENT_CONFIGS,
  contentConfigs,
})

interface SetV3LeadTypes {
  type: V3LeadActionTypes.SET_LEAD_TYPES
  leadTypes: LeadType[]
}

export const setV3LeadTypes = (leadTypes: LeadType[]): SetV3LeadTypes => ({
  type: V3LeadActionTypes.SET_LEAD_TYPES,
  leadTypes,
})

interface ReloadV3LeadAction {
  type: V3LeadActionTypes.RELOAD_LEAD
  payload: string
}

export const reloadV3Lead = (id: string): ReloadV3LeadAction => ({
  type: V3LeadActionTypes.RELOAD_LEAD,
  payload: id,
})

interface ReloadV3LeadSuccessAction {
  type: V3LeadActionTypes.RELOAD_LEAD_SUCCESS
  payload: Lead
}

export interface GetContentConfigsAction {
  type: V3LeadActionTypes.GET_CONTENT_CONFIGS
  trunkID: string
}

export const getContentConfigs = (trunkID: string): GetContentConfigsAction => ({
  type: V3LeadActionTypes.GET_CONTENT_CONFIGS,
  trunkID: trunkID,
})

export interface GetContentConfigsSuccessAction {
  type: V3LeadActionTypes.GET_CONTENT_CONFIGS_SUCCESS
  payload: ContentConfigResponse
}

export interface GetLeadTypesAction {
  type: V3LeadActionTypes.GET_LEAD_TYPES
}

export const getLeadTypes = (): GetLeadTypesAction => ({
  type: V3LeadActionTypes.GET_LEAD_TYPES,
})

export interface GetLeadTypesSuccessAction {
  type: V3LeadActionTypes.GET_LEAD_TYPES_SUCCESS
  payload: LeadType[]
}

export interface ExportLeadsAction {
  type: V3LeadActionTypes.EXPORT_LEADS
}
export const exportLeads = (): ExportLeadsAction => ({
  type: V3LeadActionTypes.EXPORT_LEADS,
})

export interface ExportLeadsSuccessAction {
  type: V3LeadActionTypes.EXPORT_LEADS_SUCCESS
  payload: string
  meta: {
    feature: string
  }
}

export type V3LeadActions =
  | GetV3LeadAction
  | GetV3LeadSuccessAction
  | GetV3LeadErrorAction
  | GetV3LeadsAction
  | GetV3LeadsSuccessAction
  | SetV3ContentConfigs
  | SetV3LeadTypes
  | SetV3LeadAction
  | SetV3LeadsAction
  | SaveV3LeadAction
  | SaveV3LeadSuccessAction
  | UpdateV3LeadAction
  | ReloadV3LeadAction
  | ReloadV3LeadSuccessAction
  | GetContentConfigsAction
  | GetContentConfigsSuccessAction
  | GetLeadTypesAction
  | GetLeadTypesSuccessAction
  | ExportLeadsAction
  | ExportLeadsSuccessAction
