export enum TriggerConditionType {
  StatusInOrNotIn = 'StatusInOrNotIn',
  CreatedBeforeOrAfter = 'CreatedBeforeOrAfter',
  CreatedAtElapsed = 'CreatedAtElapsed',
  ViewCount = 'ViewCount',
  DaysInStatus = 'DaysInStatus',
  TriggerCountLimit = 'TriggerCountLimit',
  DaysBetweenReminders = 'DaysBetweenReminders',
  NotifyParentOrgs = 'NotifyParentOrgs',
}

export enum Operator {
  Equals = '=',
  NotEquals = '!=',
  NotEqualsANSI = '<>',
  LessThan = '<',
  LessThanOrEqual = '<=',
  GreaterThan = '>',
  GreaterThanOrEqual = '>=',
  In = 'IN',
  NotIn = 'NOT IN',
  Before = 'BEFORE',
  After = 'AFTER',
  Trigger = 'TRIGGER',
}

export type ValidConditionValue = string | string[] | boolean | number

export type APITriggerCondition = {
  Required: boolean
  Type: TriggerConditionType
  Operator?: string
} & {
  [k in APITriggerConditionDataKeys]?: ValidConditionValue
}

export type APITriggerConditionDataKeys =
  | 'Data'
  | 'Status'
  | 'DaysInStatus'
  | 'TriggerCount'
  | 'DaysBetweenReminders'
  | 'NotifyParentOrgs'
  | 'Elapsed'
  | 'Count'
  | 'Unknown'

export type UITriggerCondition = {
  required: boolean
  type: TriggerConditionType
  operator?: string

  dataKey: APITriggerConditionDataKeys
  value?: ValidConditionValue

  i18n: string
  helpText?: string

  forceDisabled?: boolean
}

export type UITriggerConditionKeys = keyof Pick<UITriggerCondition, 'required' | 'type' | 'operator'> | APITriggerConditionDataKeys
