import { setError } from 'actions/document'
import axios, { AxiosError } from 'axios'
import {
  apiRequest,
  calculateFlags,
  clearSession,
  getContentConfigs,
  getLeadTypes,
  getMemberships,
  getV3OrgHierarchy,
  renderMenu,
  SESSION,
  SessionActionTypes,
  setContext,
  setFiltersOnOrgSwitch,
  setLoginError,
  setSchemas,
  setSession,
} from '../actions'
import config from '../config'
import { focusedOrgSelector, i18n, roleSelector, schemaSelector, selectV3EnabledSetting, userSelector } from '../selectors'
import { Middleware } from '../store'
import { getV3Schemas } from '../schemas/v3Mocks'
import * as Sentry from '@sentry/react'
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../util'
import { SessionState } from '../reducers/session'

// reloadToLogin clears session storage, and uses location.replace
// to avoid persisting any in-memory app state
export const reloadToLogin = () => {
  removeLocalStorage(config.sessionKey)
  window.location.replace('/login')
}

export const sessionMiddleware: Middleware = ({ dispatch, getState }) => (next) => async (action) => {
  next(action)

  switch (action.type) {
    case SessionActionTypes.LOGIN:
      dispatch(
        apiRequest({
          method: 'POST',
          url: '/authenticate/credentials',
          feature: SESSION,
          body: action.payload,
        })
      )
      break

    case SessionActionTypes.SET_FOCUSED_ORG: {
      let session: SessionState | null = getLocalStorage(config.sessionKey)
      if (!session) {
        session = {} as SessionState
      }
      session.organization = action.payload
      setLocalStorage(config.sessionKey, session)
      const schema = schemaSelector(getState())
      const response = await axios({
        url: `${config.api}/context`,
        method: 'PUT',
        data: {
          contexts: [
            {
              organizationID: action?.payload?.ID ?? '',
              trunkID: (action?.payload?.trunkID || action?.payload?.ID) ?? '',
            },
          ],
        },
      }).catch((err: AxiosError) => {
        setError(err)
      })
      // There's a ton of weirdness and timing issues with the state when doing v2/v3 switching, so it's easier to just reload
      window.location.reload()
      break
    }

    case SessionActionTypes.API_SUCCESS:
      dispatch(setSession(action.payload))
      break

    case SessionActionTypes.API_ERROR:
      dispatch(setLoginError(action?.payload?._message ?? i18n(getState())('auth.error')))
      // clear session on 401
      if (401 === action?.payload?.response?.status) {
        // if not on login page, redirect to it
        if (window.location.pathname !== '/login') {
          reloadToLogin()
        } else {
          dispatch(clearSession())
        }
      }
      break

    case SessionActionTypes.CLEAR_SESSION:
      removeLocalStorage(config.sessionKey)
      break

    case SessionActionTypes.SET_SESSION: {
      const state = getState()

      if (selectV3EnabledSetting(state)) {
        // PD-601 while this *could* be done in preloaded state or otherwise,
        // doing so here allows us to reference an i18nSelectorMap instead of the I18nState itself,
        // which is more in line with the rest of the codebase.
        // All enum arrays are blank - we don't need them yet and will populate them later.
        const schemas = [...state.schemas, ...getV3Schemas(i18n(state), [], [], [], [], [])]
        dispatch(setSchemas(schemas))
        dispatch(getV3OrgHierarchy())
        dispatch(getContentConfigs(action.payload.organization.trunkID))
        dispatch(getLeadTypes())
      }

      // set focused org for filters when session is loaded/set
      dispatch(setFiltersOnOrgSwitch(action?.payload?.organization?.ID))
      dispatch(calculateFlags())
      dispatch(getMemberships(action?.payload?.user?.ID))
      const user = userSelector(state)
      if (user) {
        Sentry.setUser({
          id: user.ID,
          email: user.email,
          username: user.fullName,
        })
      }
      const role = roleSelector(state)
      const additionalContext: Record<string, unknown> = {}
      if (role) {
        additionalContext['role'] = role
      }
      const org = focusedOrgSelector(state)
      if (org) {
        additionalContext['org'] = {
          id: org?.ID,
          name: org?.name,
          trunkID: org?.trunkID,
          trunkName: org?.trunkOrganizationName,
        }
      }
      if (Object.keys(additionalContext).length > 0) {
        Sentry.setContext('user-info', additionalContext)
      }
      setLocalStorage(config.sessionKey, action.payload)
      dispatch(renderMenu())
      break
    }

    case SessionActionTypes.LOGOUT:
      reloadToLogin()
      break

    case SessionActionTypes.APPLY_CONTEXT:
      dispatch(
        apiRequest({
          url: '/context',
          method: 'PUT',
          feature: SessionActionTypes.APPLY_CONTEXT,
          body: {
            contexts: [
              {
                organizationID: action?.payload?.ID ?? '',
                trunkID: (action?.payload?.trunkID || action?.payload?.ID) ?? '',
              },
            ],
          },
        })
      )
      break

    case SessionActionTypes.APPLY_CONTEXT_SUCCESS:
      dispatch(setContext(action?.payload?.contexts[0] ?? {}))
      break
  }
}
