import {
  API_ERROR,
  API_SUCCESS,
  apiRequest,
  DOCS,
  DocumentsActionType,
  getCreateableSchemas,
  getDocuments,
  getV3Leads,
  LEADS_LIST,
  PaginationActionType,
  setDocuments,
  setSchema,
  setSchemaCategory,
  showLoader,
  updateDocumentList,
} from '../actions'
import { digestDocumentsForList } from '../documents/digestEnums'
import urlBuilder from '../documents/urlBuilder'
import { Middleware } from '../store'
import { schemaSelector, selectLocationType } from '../selectors'

export const docsMiddleware: Middleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  switch (action.type) {
    case DocumentsActionType.GET_DOCUMENTS: {
      const org = getState().session.organization
      if (!org) {
        return
      }

      dispatch(setSchemaCategory(getState().location?.payload?.category))
      const locationType = selectLocationType(getState())
      // PD-601 Rerouting v3 requests here instead of scattered throughout the codebase
      switch (locationType) {
        case LEADS_LIST:
          dispatch(getV3Leads())
          break
        default:
          dispatch(showLoader(DOCS))
          const schema = schemaSelector(getState())
          const url = urlBuilder(getState())
          dispatch(
            apiRequest({
              url: url.url,
              method: 'GET',
              params: url.params,
              feature: DOCS,
              resource: schema.resource,
            })
          )
      }
      break
    }

    case DocumentsActionType.UPDATE_DOCUMENTS: {
      const locationType = selectLocationType(getState())
      // PD-601 Rerouting v3 requests here instead of scattered throughout the codebase
      switch (locationType) {
        case LEADS_LIST:
          dispatch(getV3Leads())
          break
        default:
          dispatch(showLoader(DOCS))
          const schema = schemaSelector(getState())
          const url = urlBuilder(getState())
          dispatch(
            apiRequest({
              url: url.url,
              method: 'GET',
              params: url.params,
              feature: DOCS,
              resource: schema.resource,
            })
          )
      }
      break
    }

    case DocumentsActionType.SET_SCOPE: {
      dispatch(getDocuments())
      break
    }

    case PaginationActionType.SET_PAGE:
    case PaginationActionType.SET_LIMIT:
    case PaginationActionType.NEXT_PAGE:
    case PaginationActionType.PREV_PAGE: {
      dispatch(updateDocumentList())
      break
    }

    case `${DOCS} ${API_SUCCESS}`:
      dispatch(
        setDocuments({
          ...action.payload,
          data: digestDocumentsForList(action.payload.schema, action.payload.data),
        })
      )

      dispatch(setSchema(action.payload.schema))
      dispatch(getCreateableSchemas(action.payload.schema.resource))
      break

    case `${DOCS} ${API_ERROR}`:
      break
  }
}
