import { cloneDeep as clone, find } from 'lodash'
import { DuplicableResource, hasExternalID, hasSlug, hasStatus, Resource } from '.'
import { Schema } from '../schemas'
import { duplicator as navbars } from './navbar/duplicator'

export type DuplicableSchema = Schema & {
  isDuplicable: true
}

export type Duplicator<T> = (source: T, schema: DuplicableSchema) => T

// defaultDuplicator can ideally handle the majority of consistent
// resource type duplication logic, for outliers define customDuplicators
// see /resource/navbar/duplicator for an implementation example
const defaultDuplicator: Duplicator<DuplicableResource> = (source, schema) => {
  const dupe = clone<DuplicableResource>(source)
  dupe.duplicatedFromID = source.ID
  dupe.duplicatedFromLocaleID = source.localeID
  dupe.ID = ''
  if (hasStatus(dupe)) {
    const status = find(schema.fields, (field) => 'status' === field.key)
    if (status) {
      dupe.status = status.defaultValue
    }
  }
  if (hasExternalID(dupe)) {
    dupe.externalID = ''
  }
  if (hasSlug(dupe)) {
    dupe.slug = ''
  }
  dupe.localeID = undefined
  delete dupe.audit
  return dupe
}

type Duplicators = {
  [k: string]: Duplicator<any>
}

// customDuplicators is a registry for more specialized
// duplicator funcs by resource name
const customDuplicators: Duplicators = {
  navbars,
}

// duplicateResource returns a duplicated resource
// with ID, status etc modifications
export const duplicateResource = (document: any, schema: Schema): DuplicableResource => {
  if (!isDuplicableSchema(schema)) {
    throw new Error(`resource is not duplicable: ${schema.resource}`)
  }
  const customResourceDuplicator = customDuplicators[schema.resource]
  if (!customResourceDuplicator) {
    return defaultDuplicator(document, schema)
  }
  return customResourceDuplicator(document, schema)
}

function isDuplicableSchema(schema: Schema): schema is DuplicableSchema {
  return schema.isDuplicable
}

export const duplicateWithLocaleID = (source: Resource, schema: Schema): Resource => {
  const dupe = defaultDuplicator(source, schema as DuplicableSchema)

  dupe.duplicatedFromID = undefined
  dupe.duplicatedFromLocaleID = undefined

  dupe.localeID = source.localeID

  return dupe
}
