// IDFromV2 transposes a V2 ID into a V3 ID
// works with 00000000-0000-0000-0000-000000000000
export function IDFromV2(id: string): string {
  if (!id) {
    return ''
  }

  if (id.length === 32) {
    return id
  }

  // pad the left portion of the V2 ID with the appropriate amount of zeros
  const v3 = new Array(32 - id.length).fill(0).concat(id.split(''))

  // slice the padded array into it's component parts, joining each individual slice to create a string
  // then, join each array element of strings with '-' to create the V3 ID structure
  return [v3.slice(0, 8).join(''), v3.slice(8, 12).join(''), v3.slice(12, 16).join(''), v3.slice(16, 20).join(''), v3.slice(20).join('')].join('-')
}
