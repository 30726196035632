import { i18nActionTypes } from '../actions/i18n'
import { i18nMap } from '../reducers/i18n'
import { Middleware } from '../store'

// importLanguageJSON will attempt to import the language or throw an err
export const importLanguageJSON = async (filename: string): Promise<i18nMap> => {
  try {
    const module = await import(`../i18n/${filename}`)
    return module.default as i18nMap
  } catch (err) {
    console.warn(`unable to load language '${filename}'`)
    throw err
  }
}

export const i18nMiddleware: Middleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  switch (action.type) {
    case i18nActionTypes.SET_LOCALE:
      // Note SM 06-20-24
      //  This is a variant of a long-standing hack to reload all API resources
      //  with the correct locale.
      //  Simply - its easiest to reload the window, which will trigger the translations being
      //  loaded alongside the correct API data.
      //  This is only needed on desktop once logged in, as the mobile experience and login screen
      //  do not require these schemas.
      window.location.reload()
  }
}
