import { Environment, Environments } from './config'
import { FlagConfigs } from './flags/flags'

// This is defunct, but not being removed for now (See CSSEditor component
const allowCSSFramework: string[] = []

// People who should be able to at least see every piece of functionality.
// Either devs that work with the full stack or management that needs to know the full state of the product
// at any given time.
const allowAll = [
  'amiller@powerchordsystem.com',
  'mvandiest@powerchordsystem.com',
  'rsauer@powerchord.com',
  'rsauer@powerchordsystem.com',
  'smiles@powerchordsystem.com',
]

// Same as allowAll but only for non-production environments - mostly Team Blue devs.
// This basically just limits visibility to Default Rules, since we don't want them accidentally edited.
const allowAllNonProd = [
  ...allowAll,
  'clichtenberger@powerchordsystem.com',
  'crivera@powerchordsystem.com',
  'dsmolen@powerchordsystem.com',
  'jcoss@powerchordsystem.com',
  'jmostyn@powerchordsystem.com',
  'lucyfernandes@powerchordsystem.com',
  'mvisich@powerchordsystem.com',
  'tmctesterson@powerchordsystem.com',
  // Team Orange QA, occasionally needs access to help out
  'dhartwell@powerchordsystem.com',
  'esibal@powerchordsystem.com',
  'hgraham@powerchordsystem.com',
]

// People who need elevated access to view Architect and pages within
const allowArchitect = [...allowAllNonProd, 'bdundas@powerchordsystem.com', 'jhillegas@powerchordsystem.com', 'kcline@powerchordsystem.com']

const internalDomains = ['powerchord.com', 'powerchord.io', 'powerchordsystem.com']

const flags: FlagConfigs = {
  architect: {
    users: allowArchitect,
  },
  internal: {
    domains: internalDomains,
  },
  cssFramework: {
    users: allowCSSFramework,
  },
  localeSwitcher: {
    environments: Environments,
  },
  languageToggle: {
    environments: Environments,
  },
  ruleAdmins: {
    users: allowAll,
  },
  ruleDevs: {
    users: allowAllNonProd,
    environments: Environments.filter((env) => env !== Environment.Production),
  },
}

export default flags
