import { Doc, Ownership, Scope } from '.'

export enum ResourceType {
  LeadCreatedType = 'leads',
  OrganizationType = 'organizations',
  MobilePhoneType = 'mobilephone',
  LeadModifiedType = 'lead-modifications',
  LeadReminders = 'lead-reminders',
}

export enum RuleEngineActionType {
  WebhookHTTPActionType = 'action.webhook.http',
  NotificationEmailActionType = 'action.notification.email',
  LeadResponseEmailActionType = 'action.lead.response.email',
  SayHelloActionType = 'action.say.hello',
}

type RuleEngineCommon<T = {}> = Doc & {
  Content: T
  Description: string
  Name: string
  Type: RuleEngineActionType
}

export type RuleEngineEmail = RuleEngineCommon<RuleEngineEmailContent> & {
  Type: RuleEngineActionType.NotificationEmailActionType | RuleEngineActionType.LeadResponseEmailActionType
}

export type RuleEngineAction = RuleEngineEmail

export enum RuleEngineTriggerFlag {
  LeadResponderEmailEnabled = 'LeadResponderEmailEnabled',
  SMSNotificationsEnabled = 'SMSNotificationsEnabled',
}

export enum RuleEngineTriggerType {
  LeadCreatedTriggerType = 'lead.created',
  LeadReassignedTriggerType = 'lead.reassigned',
  LeadRemindersTrigger = 'lead.stale',
  LeadModifiedTrigger = 'lead.modified',
  OrganizationMobilePhoneAddedTriggerType = 'organization.mobilephone.added',
  OrganizationStatusActivatedTriggerType = 'organization.status.activated',
  OrganizationStatusDeactivatedTriggerType = 'organization.status.deactivated',
}

export enum RuleStatusType {
  Active = 'active',
  Inactive = 'inactive',
}

export type RuleEngineTrigger = Doc &
  Ownership & {
    Actions: RuleEngineAction[]
    Flags: RuleEngineTriggerFlag
    Conditions: unknown[]
    Description?: string
    Executions: number
    LastProcessed: Date
    Name?: string
    ResourceType: ResourceType
    Type: RuleEngineTriggerType
    Status: RuleStatusType
    Cronjob: boolean
    CreatedAt: string
    EffectiveDate: string
  }

type RuleEngineEmailContent = {
  DefaultLocale: string
  EmailContent: Record<string, string>
  Emails: string[]
  TemplateTitle: string
  LocalizedTemplates: Record<string, string>
  Type: RuleEngineActionType
}

export interface RuleQueryRequest {
  orgScope: Scope
  limit?: number
  offset?: number
  isTestLead?: boolean[]
  status?: string[]
  typeLabel?: string[]
  search?: string
  createdAtStart?: string
  createdAtEnd?: string
  sortField?: string
  sortDirection?: string
}

// JSON Response body for v3 rule query endpoint (POST /rule/query)
export interface RuleQueryResponse {
  limit: number
  offset: number
  total: number
  data: QueriedRule[]
}

// Rule as represented from the v3 rule query endpoint (POST /rule/query).
// It includes only the data we need for the rules list, including some org/trunk info
export interface QueriedRule {
  id: string
  readOnly: string
  createdAt: string
  updatedAt: string
  ownerID: string
  name?: string
  resourceType: string
  type: string
  actions?: RuleEngineAction[]
  conditions?: unknown[]
  executions: number
  lastProcessed: string
}
