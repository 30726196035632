import _ from 'lodash'

const reorderSection = (pages: any, data: any): any => {
  return pages.map((page: any) => {
    if (page.ID === data.pageID) {
      const to = data.index
      const from = _.findIndex(page.sections, (s: any) => s.ID === data.sectionID)
      // We need to make a copy of the array so we can reorder it without mutating
      // application state
      const arrCopy = page.sections.slice(0)
      arrCopy.splice(to, 0, arrCopy.splice(from, 1)[0])
      return {
        ...page,
        sections: arrCopy,
      }
    }
    return page
  })
}

export default reorderSection
