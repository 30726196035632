import { Action } from 'redux'
import { ApiErrorPayload } from './api'
import { APIFilter, FilterField, Option } from '../types'

export enum FiltersActionType {
  GET = '[filters] Fetch',
  GET_ERROR = '[filters] Fetch API_ERROR',
  GET_SUCCESS = `[filters] Fetch API_SUCCESS`,
  SET = '[filters] Set',
  SET_FOCUSED_ORG_FILTERS = `[filters] Set Focused Org Filters`,
  UPDATE = '[filters] Update',
  UPDATE_ERROR = '[filters] Update API_ERROR',
  UPDATE_SUCCESS = '[filters] Update API_SUCCESS',
  UPDATE_FOCUSED_FILTER = '[filters] Update Focused Filter',
  SET_FETCHED_WITH = '[filters] Set Fetched With',
  SET_TO_FETCHED_WITH = '[filters] Reset to FetchedWith',
}

export type SetFetchedWithAction = {
  type: FiltersActionType.SET_FETCHED_WITH
  fetchedWith: FilterField[]
}

export type SetToFetchedWithAction = {
  type: FiltersActionType.SET_TO_FETCHED_WITH
}

export type SetFiltersAction = {
  type: FiltersActionType.SET
  resource: string
  filters: APIFilter[]
}

export const setFilters = (resource: string, filters: APIFilter[]): SetFiltersAction => ({
  type: FiltersActionType.SET,
  resource,
  filters,
})

export type SetOrgSwitchFiltersAction = {
  type: FiltersActionType.SET_FOCUSED_ORG_FILTERS
  id: string
}

export const setFiltersOnOrgSwitch = (id: string) => ({
  type: FiltersActionType.SET_FOCUSED_ORG_FILTERS,
  id,
})

export type GetFiltersAction = {
  type: FiltersActionType.GET
}

export const getFilters = (): GetFiltersAction => ({
  type: FiltersActionType.GET,
})

export type UpdateFilterAction = {
  type: FiltersActionType.UPDATE
  key: string
  resource: string
  value: Option[]
  multiSelect: boolean
}

export const updateFilter = (resource: string, key: string, value: Option[], multiSelect: boolean): UpdateFilterAction => ({
  type: FiltersActionType.UPDATE,
  key,
  resource,
  value,
  multiSelect,
})

export type UpdateFocusedFilterAction = {
  type: FiltersActionType.UPDATE_FOCUSED_FILTER
  focusedFilter: string | undefined
}

export const updateFocusedFilter = (label: string | undefined): UpdateFocusedFilterAction => ({
  type: FiltersActionType.UPDATE_FOCUSED_FILTER,
  focusedFilter: label,
})

type FiltersAction = Action<FiltersActionType>

type ApiErrorAction = FiltersAction & {
  type: FiltersActionType.GET_ERROR | FiltersActionType.UPDATE_ERROR
  payload: ApiErrorPayload
}

type ApiSuccessAction = FiltersAction & {
  type: FiltersActionType.GET_SUCCESS | FiltersActionType.UPDATE_SUCCESS
  payload: APIFilter[]
  meta: {
    feature: string
    resource: string
  }
}

export type AnyFiltersAction =
  | GetFiltersAction
  | SetFiltersAction
  | SetOrgSwitchFiltersAction
  | UpdateFilterAction
  | ApiErrorAction
  | ApiSuccessAction
  | UpdateFocusedFilterAction
  | SetFetchedWithAction
  | SetToFetchedWithAction
