import { Action } from 'redux'
import { Resource } from '../resource'
import { ApiErrorPayload, ApiSuccessPayload } from './api'

export const DOC = '[doc]'

export enum DocumentActionType {
  CLEAR = '[doc] Clear',
  CLOSE_TRANSLATOR = '[doc] Close Translator',
  DELETE = '[doc] Delete',
  DELETE_ERROR = '[doc] Delete API_ERROR',
  DELETE_SUCCESS = '[doc] Delete API_SUCCESS',
  DENY_CHANGES = '[doc] Deny Changes',
  DENY_CHANGES_ERROR = '[doc] Deny Changes API_ERROR',
  DENY_CHANGES_SUCCESS = '[doc] Deny Changes API_SUCCESS',
  DRAFT = '[doc] Draft',
  DRAFT_ERROR = '[doc] Draft API_ERROR',
  DRAFT_SUCCESS = '[doc] Draft API_SUCCESS',
  ERROR = '[doc] Error',
  GET = '[doc] Fetch',
  GET_ERROR = '[doc] Fetch API_ERROR',
  GET_SUCCESS = '[doc] Fetch API_SUCCESS',
  GENERATE_SLUG = '[doc] Generate Slug',
  GENERATE_SLUG_ERROR = '[doc] Generate Slug API_ERROR',
  GENERATE_SLUG_SUCCESS = '[doc] Generate Slug API_SUCCESS',
  LOAD_TRANSLATOR = '[doc] Load Translator',
  PUBLISH_CHANGES = '[doc] Publish Changes',
  PUBLISH_CHANGES_ERROR = '[doc] Publish Changes API_ERROR',
  PUBLISH_CHANGES_SUCCESS = '[doc] Publish Changes API_SUCCESS',
  SAVE = '[doc] Save',
  SAVE_ERROR = '[doc] Save API_ERROR',
  SAVE_SUCCESS = '[doc] Save API_SUCCESS',
  SAVE_USER = '[doc] Save User',
  SAVE_USER_ERROR = '[doc] Save User API_ERROR',
  SAVE_USER_SUCCESS = '[doc] Save User API_SUCCESS',
  SUBMIT_CHANGES = '[doc] Submit Changes',
  SUBMIT_CHANGES_ERROR = '[doc] Submit Changes API_ERROR',
  SUBMIT_CHANGES_SUCCESS = '[doc] Submit Changes API_SUCCESS',
  SET = '[doc] Set',
  SET_DUPLICATE = '[doc] Set Duplicate',
  SET_TRANSLATE = '[doc] Set Translate',
  UPDATE_DOCUMENT = '[doc] Update',
}

type DocumentAction = Action<DocumentActionType>

type ApiSuccessAction = DocumentAction & {
  type:
    | DocumentActionType.DELETE_SUCCESS
    | DocumentActionType.DENY_CHANGES_SUCCESS
    | DocumentActionType.DRAFT_SUCCESS
    | DocumentActionType.GENERATE_SLUG_SUCCESS
    | DocumentActionType.GET_SUCCESS
    | DocumentActionType.PUBLISH_CHANGES_SUCCESS
    | DocumentActionType.SAVE_SUCCESS
    | DocumentActionType.SAVE_USER_SUCCESS
    | DocumentActionType.SUBMIT_CHANGES_SUCCESS
  payload: ApiSuccessPayload
}

type ApiErrorAction = DocumentAction & {
  type:
    | DocumentActionType.DELETE_ERROR
    | DocumentActionType.DENY_CHANGES_ERROR
    | DocumentActionType.DRAFT_ERROR
    | DocumentActionType.GENERATE_SLUG_ERROR
    | DocumentActionType.GET_ERROR
    | DocumentActionType.PUBLISH_CHANGES_ERROR
    | DocumentActionType.SAVE_ERROR
    | DocumentActionType.SAVE_USER_ERROR
    | DocumentActionType.SUBMIT_CHANGES_ERROR
  payload: ApiErrorPayload
}

type ClearDocumentAction = DocumentAction & {
  type: DocumentActionType.CLEAR
}

export const clearDocument = (): ClearDocumentAction => ({
  type: DocumentActionType.CLEAR,
})

type CloseTranslatorAction = DocumentAction & {
  type: DocumentActionType.CLOSE_TRANSLATOR
}

export const closeTranslator = (): CloseTranslatorAction => ({
  type: DocumentActionType.CLOSE_TRANSLATOR,
})

type DeleteDocumentAction = DocumentAction & {
  type: DocumentActionType.DELETE
  id: string
}

export const deleteDocument = (id: string): DeleteDocumentAction => ({
  type: DocumentActionType.DELETE,
  id,
})

type DenyChangesAction = DocumentAction & {
  type: DocumentActionType.DENY_CHANGES
  documentID: string
  denialReason: string
}

export const denyChanges = (documentID: string, denialReason: string): DenyChangesAction => ({
  type: DocumentActionType.DENY_CHANGES,
  documentID,
  denialReason,
})

type DraftDocumentAction = DocumentAction & {
  type: DocumentActionType.DRAFT
}

export const draftDocument = (): DraftDocumentAction => ({
  type: DocumentActionType.DRAFT,
})

type GenerateSlugAction = DocumentAction & {
  type: DocumentActionType.GENERATE_SLUG
  slug: string
  name: string
}

export const generateSlug = (slug: string, name: string): GenerateSlugAction => ({
  type: DocumentActionType.GENERATE_SLUG,
  slug,
  name,
})

type GetDocumentAction = DocumentAction & {
  type: DocumentActionType.GET
  id: string
  duplicatedFrom?: string
}

export const getDocument = (id: string, duplicatedFrom?: string): GetDocumentAction => ({
  type: DocumentActionType.GET,
  id,
  duplicatedFrom,
})

type LoadDocumentTranslatorAction = DocumentAction & {
  type: DocumentActionType.LOAD_TRANSLATOR
}

export const loadDocumentTranslator = (): LoadDocumentTranslatorAction => ({
  type: DocumentActionType.LOAD_TRANSLATOR,
})

type PublishChangesAction = DocumentAction & {
  type: DocumentActionType.PUBLISH_CHANGES
}

export const publishChanges = (): PublishChangesAction => ({
  type: DocumentActionType.PUBLISH_CHANGES,
})

type SaveDocumentAction = DocumentAction & {
  type: DocumentActionType.SAVE
}

export const saveDocument = (): SaveDocumentAction => ({
  type: DocumentActionType.SAVE,
})

type SetDocumentAction = DocumentAction & {
  type: DocumentActionType.SET
  document: any
}

export const setDocument = (document: any): SetDocumentAction => ({
  type: DocumentActionType.SET,
  document,
})

type SetDuplicateAction = DocumentAction & {
  type: DocumentActionType.SET_DUPLICATE
  duplicatedFrom: Resource
}

export const setDuplicate = (duplicatedFrom: Resource): SetDuplicateAction => ({
  type: DocumentActionType.SET_DUPLICATE,
  duplicatedFrom,
})

export type SetErrorAction = DocumentAction & {
  type: DocumentActionType.ERROR
  error: object
}

export const setError = (error: object): SetErrorAction => ({
  type: DocumentActionType.ERROR,
  error,
})

type SetTranslateAction = DocumentAction & {
  type: DocumentActionType.SET_TRANSLATE
  translatedFrom: Resource
}

export const setTranslate = (translatedFrom: Resource): SetTranslateAction => ({
  type: DocumentActionType.SET_TRANSLATE,
  translatedFrom,
})

type SubmitChangesAction = DocumentAction & {
  type: DocumentActionType.SUBMIT_CHANGES
}

export const submitChanges = (): SubmitChangesAction => ({
  type: DocumentActionType.SUBMIT_CHANGES,
})

type UpdateDocumentAction = DocumentAction & {
  type: DocumentActionType.UPDATE_DOCUMENT
  document: any
}

export const updateDocument = (document: any): UpdateDocumentAction => ({
  type: DocumentActionType.UPDATE_DOCUMENT,
  document,
})

export type AnyDocumentAction =
  | ApiErrorAction
  | ApiSuccessAction
  | ClearDocumentAction
  | CloseTranslatorAction
  | DeleteDocumentAction
  | DenyChangesAction
  | DraftDocumentAction
  | GenerateSlugAction
  | GetDocumentAction
  | LoadDocumentTranslatorAction
  | PublishChangesAction
  | SaveDocumentAction
  | SetDocumentAction
  | SetDuplicateAction
  | SetErrorAction
  | SetTranslateAction
  | SubmitChangesAction
  | UpdateDocumentAction
