import { Middleware } from '../store'
import {
  API_ERROR,
  API_SUCCESS,
  apiRequest,
  getV3DownstreamOrgs,
  getV3Organization,
  getV3UpstreamOrgs,
  hideLoader,
  setV3Organization,
  setV3Organizations,
  showLoader,
  V3OrganizationActionTypes,
} from '../actions'
import config from '../config'
import { IDFromV2 } from '../util'

export const v3OrganizationsMiddleware: Middleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  const state = getState()
  const org = state?.session?.organization
  if (!org) {
    return
  }

  switch (action.type) {
    case V3OrganizationActionTypes.GET_ORGANIZATIONS: {
      dispatch(showLoader(V3OrganizationActionTypes.GET_ORGANIZATIONS))
      dispatch(
        apiRequest({
          url: `${config.v3Api}/organization`,
          method: 'GET',
          feature: V3OrganizationActionTypes.GET_ORGANIZATIONS,
        })
      )
      break
    }

    case V3OrganizationActionTypes.GET_ORGANIZATION: {
      dispatch(showLoader(V3OrganizationActionTypes.GET_ORGANIZATION))
      dispatch(
        apiRequest({
          url: `${config.v3Api}/organization/${action.payload.id}`,
          method: 'GET',
          feature: V3OrganizationActionTypes.GET_ORGANIZATION,
        })
      )
      break
    }

    // GET_ORG_HIERARCHY dispatches calls to retrieve organizations in the hierarchy of the focused org
    case V3OrganizationActionTypes.GET_ORG_HIERARCHY: {
      const { session } = state
      dispatch(getV3Organization(IDFromV2(session.organization.ID)))
      break
    }

    case V3OrganizationActionTypes.GET_DOWNSTREAM_ORGS: {
      dispatch(showLoader(V3OrganizationActionTypes.GET_DOWNSTREAM_ORGS))
      dispatch(
        apiRequest({
          url: `${config.v3Api}/organization/${action.payload.id}/downstream`,
          method: 'GET',
          feature: V3OrganizationActionTypes.GET_DOWNSTREAM_ORGS,
        })
      )
      break
    }

    case V3OrganizationActionTypes.GET_UPSTREAM_ORGS: {
      dispatch(showLoader(V3OrganizationActionTypes.GET_UPSTREAM_ORGS))
      dispatch(
        apiRequest({
          url: `${config.v3Api}/organization/${action.payload.id}/upstream`,
          method: 'GET',
          feature: V3OrganizationActionTypes.GET_UPSTREAM_ORGS,
        })
      )
      break
    }

    case `${V3OrganizationActionTypes.GET_DOWNSTREAM_ORGS} ${API_SUCCESS}`: {
      dispatch(hideLoader(V3OrganizationActionTypes.GET_DOWNSTREAM_ORGS))
      dispatch(setV3Organizations(action.payload))
      break
    }

    case `${V3OrganizationActionTypes.GET_UPSTREAM_ORGS} ${API_SUCCESS}`: {
      dispatch(hideLoader(V3OrganizationActionTypes.GET_UPSTREAM_ORGS))
      dispatch(setV3Organizations(action.payload))
      break
    }

    case `${V3OrganizationActionTypes.GET_ORGANIZATIONS} ${API_SUCCESS}`: {
      dispatch(hideLoader(V3OrganizationActionTypes.GET_ORGANIZATIONS))
      dispatch(setV3Organizations(action.payload))
      break
    }

    case `${V3OrganizationActionTypes.GET_ORGANIZATION} ${API_SUCCESS}`: {
      dispatch(hideLoader(V3OrganizationActionTypes.GET_ORGANIZATION))
      dispatch(setV3Organization(action.payload))
      if (action.payload.TrunkID !== '') {
        dispatch(getV3UpstreamOrgs(action.payload?.ID))
      }
      dispatch(getV3DownstreamOrgs(action.payload?.ID))
      break
    }

    case `${V3OrganizationActionTypes.GET_DOWNSTREAM_ORGS} ${API_ERROR}`: {
      dispatch(hideLoader(V3OrganizationActionTypes.GET_DOWNSTREAM_ORGS))
      break
    }

    case `${V3OrganizationActionTypes.GET_UPSTREAM_ORGS} ${API_ERROR}`: {
      dispatch(hideLoader(V3OrganizationActionTypes.GET_UPSTREAM_ORGS))
      break
    }

    case `${V3OrganizationActionTypes.GET_ORGANIZATIONS} ${API_ERROR}`: {
      dispatch(hideLoader(V3OrganizationActionTypes.GET_ORGANIZATIONS))
      break
    }

    case `${V3OrganizationActionTypes.GET_ORGANIZATION} ${API_ERROR}`: {
      dispatch(hideLoader(V3OrganizationActionTypes.GET_ORGANIZATION))
      break
    }
  }
}
