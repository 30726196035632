import { AppState } from '../store'
import { ContentConfig, Enum, Option } from '../types'
import { getLeadStageEnums, getLeadStatusEnums } from '../util/contentConfig'
import { i18n } from './i18n'
import { enumToOptionEnum } from '../util/enums'

export const selectContentConfigs = (state: AppState): ContentConfig[] => {
  return state.v3Leads.contentConfigs
}

export const selectLeadStatusEnums = (state: AppState, trimDefaults: boolean): Enum[] => {
  const configs = selectContentConfigs(state)
  return getLeadStatusEnums(i18n(state), configs, trimDefaults)
}

export const selectLeadStageEnums = (state: AppState): Enum[] => {
  return getLeadStageEnums(i18n(state))
}

export const selectLeadStatusOptions = (state: AppState, trimDefaults: boolean): Option[] => {
  const statusEnums = selectLeadStatusEnums(state, trimDefaults)
  return enumToOptionEnum(statusEnums)
}

export const selectLeadStageOptions = (state: AppState): Option[] => {
  return enumToOptionEnum(selectLeadStageEnums(state))
}
