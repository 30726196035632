import { AnalyticsState } from '../reducers/analytics'
import { APIError } from '../types'

export const ANALYTICS = '[analytics]'
export const SET_ANALYTICS = `${ANALYTICS} Set`
export const GET_ANALYTICS = `${ANALYTICS} Fetch`
export const ERROR_ANALYTICS = `${ANALYTICS} Error`

export const getAnalytics = (dashboardType: string): any => ({
  type: GET_ANALYTICS,
  dashboardType,
})

export const setAnalytics = (analytics: AnalyticsState): any => ({
  type: SET_ANALYTICS,
  payload: analytics,
})

export const setAnalyticsError = (error: APIError): any => ({
  type: ERROR_ANALYTICS,
  payload: error._message,
})
