import { User } from './User'
import { Organization } from './Organization'
import { Schema } from '../schemas'

export type APIError = {
  _code: string
  _message: string
  _inputs: Record<string, string[]>
  _fault: boolean
}

export type RendererError = {
  Code: string
  HttpStatusCode: number
  Message: string
}

export enum Scope {
  All = 'all',
  Focused = 'focused',
  IncludeDescendants = 'includeDescendants',
  PlatformSystem = 'platformSystem',
}

export type V2Response<T = Document> = {
  data: T[]
  limit: number
  resultCount: number
  schema: Schema
  skip: number
  totalCount: number
}

export type AuthResponse = {
  authorizationToken: string
  authorizationTokenExpiration: Date
  user: User
  organization: Organization
}

export type AnalyticsResponse = {
  embedURL: string
}

export type CreateableSchemasResponse = {
  resources: Partial<Schema>[]
}
