import { Schema } from '../schemas'

export enum SchemaActionType {
  Set = '[schema] Set',
  SetCategory = '[schema] Set Category',
}

export type SetSchemaAction = {
  type: SchemaActionType.Set
  schema: Schema
}

export const setSchema = (schema: Schema): SetSchemaAction => ({
  type: SchemaActionType.Set,
  schema,
})

export type SetSchemaCategoryAction = {
  type: SchemaActionType.SetCategory
  category: string
}

export const setSchemaCategory = (category: string): SetSchemaCategoryAction => ({
  type: SchemaActionType.SetCategory,
  category,
})

export type AnySchemaAction = SetSchemaAction | SetSchemaCategoryAction
