import { every, get, isArray } from 'lodash'
import { ModalProps } from '../actions'
import { AppState } from '../store'

export const showModalSelector = (state: AppState): any => {
  return (state && state.ui && state.ui.showModal) || false
}

export const modalSelector = (state: AppState): any => {
  return (state && state.ui && state.ui.modal) || ''
}

export const modalCallbackSelector = (state: AppState): any => {
  return (state && state.ui && state.ui.modalCallback) || ''
}

export const loadingSelector = (state: AppState, entity: string | Array<string>): boolean => {
  const loaders = get(state, 'ui.loaders', {})

  if (isArray(entity)) {
    // return true if every entity is not done loading
    return !every(entity, (e: any) => !loaders[e])
  }
  return loaders[entity]
}

export const notificationSelector = (state: AppState): any => {
  return get(state, 'ui.notifications', [])
}

export const v2ModalSelector = (state: AppState): ModalProps | null => {
  return get(state, 'ui.v2Modal', null)
}
