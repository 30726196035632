import { Schema } from '../schemas'
import { API_SUCCESS } from './api'

export const SCHEMAS = '[schemas]'
export const GET_SCHEMAS = `${SCHEMAS} Fetch`
export const SET_SCHEMAS = `${SCHEMAS} Set`
export const GET_SCHEMAS_SUCCESS = `${SCHEMAS} ${API_SUCCESS}`
export const RENDER_MENU = 'RENDER_MENU'

export const renderMenu = () => ({
  type: RENDER_MENU,
})

export const getSchemas = () => ({
  type: GET_SCHEMAS,
})

export const setSchemas = (schemas: Schema[]) => ({
  type: SET_SCHEMAS,
  payload: schemas,
})
