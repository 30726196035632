import { AnyAction } from 'redux'

export const STORAGE = '[storage]'
export const SET_LOCAL_STORAGE = `${STORAGE} Set Local`

export const setLocalStorage = (key: string, value: string): AnyAction => ({
  type: SET_LOCAL_STORAGE,
  payload: {
    key,
    value,
  },
})
