import { V3Organization } from '../types'
import { AppState } from '../store'

export const V3OrganizationsSelector = (state: AppState): V3Organization[] => {
  return state.v3Organizations.organizations
}

export const selectV3OrgsLoaded = (state: AppState): boolean => {
  return state.v3Organizations.organizations.length > 0
}

export const selectActiveV3Orgs = (state: AppState): V3Organization[] => {
  return state.v3Organizations.organizations.filter((org) => {
    return org.Status.toLowerCase() === 'active'
  })
}
