import { Doc, Ownership } from '../../types'
import { Action, ActionKeys } from './Action'
import { APITriggerCondition, UITriggerCondition, UITriggerConditionKeys } from './Condition'

export enum ResourceTypeType {
  LeadsCreated = 'leads',
  Organizations = 'organizations',
  LeadsModified = 'lead-modifications',
}

export enum TriggerType {
  LeadCreatedTrigger = 'lead.created',
  LeadReassignedTrigger = 'lead.reassigned',
  LeadRemindersTrigger = 'lead.stale',
  LeadModifiedTrigger = 'lead.modified',
  OrganizationMobilePhoneAddedTriggerType = 'organization.mobilephone.added',
  OrganizationStatusActivatedTrigger = 'organization.status.activated',
  OrganizationStatusDeactivatedTrigger = 'organization.status.deactivated',
}

// TODO: where was this intended to be used again?
export const ResourceTypeByTriggerType = new Map([
  [TriggerType.LeadCreatedTrigger, ResourceTypeType.LeadsCreated],
  [TriggerType.LeadReassignedTrigger, ResourceTypeType.LeadsCreated],
  [TriggerType.LeadRemindersTrigger, ResourceTypeType.LeadsCreated],
  [TriggerType.LeadModifiedTrigger, ResourceTypeType.LeadsModified],
])

export type Trigger = Doc &
  Ownership & {
    Actions: Action[]
    Description?: string
    Type: TriggerType
    Conditions?: UITriggerCondition[] | APITriggerCondition[]
    CreatedAt?: string
    Executions?: number
    OwnerID: string
    ID: string
    LastProcessed?: string
    ReadOnly?: boolean
    Name?: string
    // TODO: This shouldn't be null, it can't be in the DB
    ResourceType?: ResourceTypeType
    Status: boolean
    EffectiveDate: string
  }

export type TriggerKeys = keyof Trigger | `Conditions.${UITriggerConditionKeys}` | `Actions.${ActionKeys}`

export type TriggerUIErrors = Partial<Record<TriggerKeys, string | undefined>>
