import { ApiSuccessPayload } from '.'
import { Settings, UserSettings } from '../types'

export const SETTINGS = '[settings]'

export enum SettingsActionTypes {
  GET_SETTINGS = '[settings] GET',
  SET_SETTINGS = '[settings] SET',
  GET_SETTINGS_SUCCESS = '[settings] API_SUCCESS',

  GET_USER_SETTINGS = '[user settings] GET',
  SET_USER_SETTINGS = '[user settings] SET',
  GET_USER_SETTINGS_SUCCESS = '[user settings] API_SUCCESS',
  PATCH_USER_SETTINGS_SUCCESS = '[doc] Save User API_SUCCESS',
}

export type SettingsAction = { type: SettingsActionTypes.SET_SETTINGS; payload: Settings } | { type: SettingsActionTypes.GET_SETTINGS }

export const getSettings = () => ({
  type: SettingsActionTypes.GET_SETTINGS,
})

export const setSettings = (settings: Settings) => ({
  type: SettingsActionTypes.SET_SETTINGS,
  payload: settings,
})

export type UserSettingsAction =
  | { type: SettingsActionTypes.SET_USER_SETTINGS; payload: UserSettings }
  | { type: SettingsActionTypes.PATCH_USER_SETTINGS_SUCCESS; payload: ApiSuccessPayload<UserSettings> }
  | { type: SettingsActionTypes.GET_USER_SETTINGS }

export const getUserSettings = () => ({
  type: SettingsActionTypes.GET_USER_SETTINGS,
})

export const setUserSettings = (settings: UserSettings) => ({
  type: SettingsActionTypes.SET_USER_SETTINGS,
  payload: settings,
})
