import {
  AnyFiltersAction,
  apiRequest,
  FiltersActionType,
  getDocuments,
  hideLoader,
  LEAD_DETAILS,
  LEADS_LIST,
  LoadListAction,
  setFilters,
  setSchemas,
  showLoader,
  TRIGGER_DETAILS,
  TRIGGERS_LIST,
  updateDocumentList,
} from '../actions'
import {
  focusedOrgSelector,
  i18n,
  moduleSelector,
  selectFilterOptionStringsByKey,
  selecti18n,
  selectLeadStatusToStageSetting,
  selectLeadTypeEnums,
  selectV3EnabledSetting,
} from '../selectors'
import { AppState, Middleware } from '../store'
import { getV3LeadAPIFiltersFromMockSchema } from '../util'
import { selectLeadStageEnums, selectLeadStatusEnums } from '../selectors/contentConfig'
import { Schema } from '../schemas'
import { createV3LeadsAPISchemaFromMock, getV3LeadsMockSchema, V3LeadsCategory } from '../schemas/v3Mocks'

type HandleActions = AnyFiltersAction | LoadListAction

export const filtersMiddleware: Middleware<HandleActions> = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  const handleV3Leads = (state: AppState) => {
    const mockV3LeadsSchema = getV3LeadsMockSchema(
      i18n(state),
      selectLeadStatusEnums(state, false),
      selectLeadTypeEnums(state),
      selectLeadStageEnums(state),
      selectFilterOptionStringsByKey(state, 'Stage', false),
      selectLeadStatusToStageSetting(state)
    )

    const newV3LeadsSchema = createV3LeadsAPISchemaFromMock(selecti18n(state, 'leads.detail.section.content.label'), mockV3LeadsSchema)

    const updatedSchemas = state.schemas.reduce((acc: Schema[], curr: Schema) => {
      if (curr.key !== V3LeadsCategory) {
        acc.push(curr)
        return acc
      }
      acc.push(newV3LeadsSchema)
      return acc
    }, [])

    dispatch(setSchemas(updatedSchemas))

    // Reevaluate the filters with updated stageEnums
    // Mock an API success with our filter result
    dispatch({
      type: FiltersActionType.GET_SUCCESS,
      payload: getV3LeadAPIFiltersFromMockSchema(mockV3LeadsSchema),
      meta: {
        feature: FiltersActionType.GET,
        resource: V3LeadsCategory,
      },
    })
  }

  switch (action.type) {
    case FiltersActionType.GET: {
      dispatch(showLoader(FiltersActionType.GET))

      switch (getState().location.type) {
        case TRIGGER_DETAILS:
        case TRIGGERS_LIST:
          // V3 resources with no filters (yet)
          dispatch(hideLoader(FiltersActionType.GET))
          break
        case LEAD_DETAILS:
        case LEADS_LIST:
          // skip fetching filters (prevent firing requests to v3 API) if v3 not enabled for org on org switch
          if (!selectV3EnabledSetting(getState())) {
            dispatch(hideLoader(FiltersActionType.GET))
            break
          }
          handleV3Leads(getState())

          break
        default:
          const resource = moduleSelector(getState())?.schemas?.[0]?.resource
          if (resource) {
            const organization = focusedOrgSelector(getState())
            const filterFragment = `/${resource}/filters`
            const url = organization?.trunkID ? `/organizations/${organization.trunkID}/${organization.ID}${filterFragment}` : filterFragment
            dispatch(
              apiRequest({
                url,
                method: 'GET',
                feature: FiltersActionType.GET,
                resource: resource,
              })
            )
          }
      }
      break
    }

    case FiltersActionType.GET_SUCCESS:
      // Any mocked filters won't hit the API middleware, catch that with this hideLoader call.
      dispatch(hideLoader(FiltersActionType.GET))
      dispatch(setFilters(action.meta.resource, action.payload))
      dispatch(getDocuments())
      break

    case FiltersActionType.UPDATE:
      // when selecting a lead stage, we want to filter out now-unavailable statuses as well.
      // This was originally assigned to an Azure ticket but redone as part of PD-601 to reflect
      // current filter handling
      const { key, resource } = action
      if (resource === V3LeadsCategory && key === 'Stage') {
        // For all intents and purposes, we basically just want to re-do what happens when calling
        // GET for filters on V3 leads, just with a re-evaluation of the stage enums.
        handleV3Leads(getState())
        break
      }

      dispatch(updateDocumentList())
      break
  }
}
