import { Action } from 'redux'
import { ApiErrorPayload } from './api'

export enum TranslationsActionType {
  GENERATE_UI_REPORT = '[translations] Generate UI Report',
  GENERATE_SCHEMA_REPORT = '[translations] Generate Schema Report',
  GENERATE_ERROR_REPORT = '[translations] Generate Error Report',
  GENERATE_UI_REPORT_ERROR = '[translations] Generate UI Report API_ERROR',
  GENERATE_UI_REPORT_SUCCESS = '[translations] Generate UI Report API_SUCCESS',
  GENERATE_SCHEMA_REPORT_ERROR = '[translations] Generate Schema Report API_ERROR',
  GENERATE_SCHEMA_REPORT_SUCCESS = '[translations] Generate Schema Report API_SUCCESS',
  GENERATE_ERROR_REPORT_ERROR = '[translations] Generate Error Report API_ERROR',
  GENERATE_ERROR_REPORT_SUCCESS = '[translations] Generate Error Report API_SUCCESS',
}

type TranslationsAction = Action<TranslationsActionType>

type GENERATE_REPORT_ERROR =
  | TranslationsActionType.GENERATE_UI_REPORT_ERROR
  | TranslationsActionType.GENERATE_SCHEMA_REPORT_ERROR
  | TranslationsActionType.GENERATE_ERROR_REPORT_ERROR
type GENERATE_REPORT_SUCCESS =
  | TranslationsActionType.GENERATE_UI_REPORT_SUCCESS
  | TranslationsActionType.GENERATE_SCHEMA_REPORT_SUCCESS
  | TranslationsActionType.GENERATE_ERROR_REPORT_SUCCESS

type ApiSuccessAction = TranslationsAction & {
  type: GENERATE_REPORT_SUCCESS
  payload: string
  meta: {
    feature: string
  }
}

type ApiErrorAction = TranslationsAction & {
  type: GENERATE_REPORT_ERROR
  payload: ApiErrorPayload
}

export type GenerateUiReportAction = TranslationsAction & {
  type: TranslationsActionType.GENERATE_UI_REPORT
}

export const generateUiReport = (): GenerateUiReportAction => ({
  type: TranslationsActionType.GENERATE_UI_REPORT,
})

export type GenerateSchemaReportAction = TranslationsAction & {
  type: TranslationsActionType.GENERATE_SCHEMA_REPORT
}

export const generateSchemaReport = (): GenerateSchemaReportAction => ({
  type: TranslationsActionType.GENERATE_SCHEMA_REPORT,
})

export type GenerateErrorReportAction = TranslationsAction & {
  type: TranslationsActionType.GENERATE_ERROR_REPORT
}

export const generateErrorReport = (): GenerateErrorReportAction => ({
  type: TranslationsActionType.GENERATE_ERROR_REPORT,
})

export type GenerateReportAction = GenerateUiReportAction | GenerateSchemaReportAction | GenerateErrorReportAction

export type AnyTranslationsAction = ApiErrorAction | ApiSuccessAction | GenerateReportAction
