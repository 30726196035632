// This is duplicative but there seems to be a technical difference
// between "default" and "system" semantics, so the two getters
// are split.
import { V3Organization } from '../types'

const defaultID = '00000000-0000-0000-0000-000000000000'
export function getV3DefaultOwnerID(): string {
  return defaultID
}

export function getV3SystemOwnerID(): string {
  return defaultID
}

export const deduplicateV3Orgs = (a: V3Organization[], b: V3Organization[]): V3Organization[] => {
  return [...a, ...b].reduce((acc: V3Organization[], curr: V3Organization) => {
    if (acc.findIndex((act) => act.ID === curr.ID) < 0) {
      acc.push(curr)
    }
    return acc
  }, [])
}
