import { format, isToday, isYesterday, parseISO } from 'date-fns'
import { I18n } from '../selectors'

// Some objects that use the ISO parser functions could have an undefined date,
// fall back to epoch 0 if this is the case.
export function isoDateSanitize(time: string | undefined): string {
  if (!time) {
    return new Date(0).toISOString()
  }
  return time
}

// isoDateParser accepts a date is ISO string format and returns a string of the date
// in 'MM/dd/yyy, H:mm'
// https://date-fns.org/v1.28.5/docs/format
// todo: add a flag to return 'dd/mm/yy, hh:mm' for our international friends
export function isoDateParser(time: string | undefined): string {
  const sanitizedTime = isoDateSanitize(time)
  const t = parseISO(sanitizedTime)

  return format(t, 'MM/dd/yy H:mm')
}

export function isoDateParserLocalized(time: string | undefined, i18n: I18n): string {
  const sanitizedTime = isoDateSanitize(time)
  const t = parseISO(sanitizedTime)
  const template = i18n('global.dateTime.format') ?? 'MM/dd/yy H:mm'
  return format(t, template)
}

// nearRelativeDateParser accepts a date in ISO string format and an i18n selector (for translations), and returns the following:
// - 'Today | H:mm' if the time is today
// - 'Yesterday | H:mm' if the time was yesterday
// - 'MM/dd/yy | H:mm' in all other cases
// https://date-fns.org/v1.28.5/docs/format
export function nearRelativeDateParser(time: string | undefined, i18n: I18n): string {
  const sanitizedTime = isoDateSanitize(time)
  const t = parseISO(sanitizedTime)
  if (isToday(t)) {
    return `${i18n('global.dateTime.today')} | ${format(t, 'H:mm')}`
  }

  if (isYesterday(t)) {
    return `${i18n('global.dateTime.yesterday')} | ${format(t, 'H:mm')}`
  }

  return format(t, "MM/dd/yy '|' H:mm")
}
