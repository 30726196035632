/**
 * Generates a concatenated attribute string based on the given parameters.
 * Will return undefined if passed an empty based or empty / undefined key, to allow for using props that could be undefined.
 *
 * @param {string} base - Ideally the type of element, manually defined (ie: TextField == 'text-field', Autocomplete = 'autocomplete').
 * @param {string | undefined} key - The unique key for the element (ie: schema field key or custom string).
 * @param {string} [modifier] - The modifier value for an element (ie: pass 'input' if you're generating for the <input> field of a <TextField> element.
 * @returns {string | undefined} - The generated attribute string or undefined if parameters were invalid.
 */
export const genCIAttr = (base: string, key: string | undefined, modifier?: string): string | undefined => {
  if (!key || key.length === 0 || base.length === 0) {
    return
  }

  if (modifier && modifier.length > 0) {
    return `${base}-${key}-${modifier}`
  }

  return `${base}-${key}`
}
