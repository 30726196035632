import React, { createContext, Dispatch, FC, useContext, useEffect, useReducer } from 'react';
import i18nReducer, { i18nKey, i18nMap, i18nSelectorMap, i18nState, initialI18nState } from '../../reducers/i18n';
import enUS from '../../i18n/en-US.json';
import { i18nActions, i18nActionTypes } from '../../actions';
import { importLanguageJSON } from '../../middleware';
import { Enum, Option } from '../../types';
import { enumToOptionEnum } from '../../util/enums';
import * as Sentry from '@sentry/react';
const I18nStateContext = createContext<i18nState>(({} as i18nState));
const I18nDispatchContext = createContext<Dispatch<i18nActions>>(() => void 0);
type I18nProviderProps = {
  locale: string;
};
const I18nProvider: FC<I18nProviderProps> = ({
  children,
  locale
}) => {
  const [state, dispatch] = useReducer(i18nReducer, {
    ...initialI18nState,
    locale: locale
  });
  useEffect(() => {
    importLanguageJSON(state.locale).then((words: i18nMap) => {
      dispatch({
        type: i18nActionTypes.SET_TRANSLATIONS,
        translations: words
      });
    }).catch(err => {
      Sentry.captureException(err, {
        tags: {
          source: 'i18nProvider'
        }
      });
      console.warn('could not import language json', err);
    });
  }, [state.locale]);
  return <I18nStateContext.Provider value={state} data-sentry-element="unknown" data-sentry-component="I18nProvider" data-sentry-source-file="i18n.tsx">
      <I18nDispatchContext.Provider value={dispatch} data-sentry-element="unknown" data-sentry-source-file="i18n.tsx">{children}</I18nDispatchContext.Provider>
    </I18nStateContext.Provider>;
};
function useI18nState() {
  const context = useContext(I18nStateContext);
  if (context === undefined) {
    throw new Error('useI18nState must be used within an I18nStateContext');
  }
  return context;
}
function useI18nDispatch() {
  const context = useContext(I18nDispatchContext);
  if (context === undefined) {
    throw new Error('useI18nDispatch must be used within an I18nDispatchContext');
  }
  return context;
}
function useI18nSelector(): i18nSelectorMap {
  const state = useI18nState();
  return (key: i18nKey): string => {
    const text = state.translations[key] ?? '';
    if (text !== '') {
      return text;
    }
    return enUS?.[key] ?? '';
  };
}
function useAvailableLocaleOptions(): Option[] {
  const state = useI18nState();
  return enumToOptionEnum(state.availableLocales);
}
export { I18nProvider, useI18nState, useI18nDispatch, useI18nSelector, useAvailableLocaleOptions };