import { TriggerType } from '../v3/type/Trigger'
import { Action, ActionType, CachedActionIDs } from '../v3/type/Action'
import { i18nKey, i18nSelectorMap } from '../reducers/i18n'
import {
  APITriggerCondition,
  APITriggerConditionDataKeys,
  Operator,
  TriggerConditionType,
  UITriggerCondition,
  ValidConditionValue,
} from '../v3/type/Condition'

export const defaultTriggerActionsByType = (t: TriggerType, actions: Action[]): Action[] => {
  switch (t) {
    case TriggerType.LeadRemindersTrigger:
      return actions.filter((act) => {
        return act.ID === CachedActionIDs.DefaultLeadReminderEmail
      })
    default:
      return [newWebhookAction()]
  }
}

export const defaultTriggerConditionsByType = (type: TriggerType, i18n: i18nSelectorMap): UITriggerCondition[] => {
  // TODO: handle conditions existing already
  switch (type) {
    case TriggerType.LeadRemindersTrigger:
      return [
        newUIConditionFromConditionType(i18n, TriggerConditionType.StatusInOrNotIn),
        newUIConditionFromConditionType(i18n, TriggerConditionType.DaysInStatus),
        newUIConditionFromConditionType(i18n, TriggerConditionType.TriggerCountLimit),
        newUIConditionFromConditionType(i18n, TriggerConditionType.DaysBetweenReminders),
        newUIConditionFromConditionType(i18n, TriggerConditionType.NotifyParentOrgs),
      ]
    default:
      return []
  }
}

const newUIConditionFromConditionType = (i18n: i18nSelectorMap, t: TriggerConditionType): UITriggerCondition => {
  return {
    required: true,
    type: t,
    dataKey: dataKeyByConditionType.get(t) ?? 'Unknown',
    i18n: i18n(`rules.detail.page.condition.${t}` as i18nKey),
    helpText: i18n(`rules.detail.page.condition.helpText.${t}` as i18nKey),
    operator: operatorByConditionType.get(t),
    value: defaultValueByConditionType.get(t),
  }
}

const dataKeyByConditionType = new Map<TriggerConditionType, APITriggerConditionDataKeys>([
  [TriggerConditionType.CreatedBeforeOrAfter, 'Data'],
  [TriggerConditionType.StatusInOrNotIn, 'Status'],
  [TriggerConditionType.DaysInStatus, 'DaysInStatus'],
  [TriggerConditionType.TriggerCountLimit, 'TriggerCount'],
  [TriggerConditionType.DaysBetweenReminders, 'DaysBetweenReminders'],
  [TriggerConditionType.NotifyParentOrgs, 'NotifyParentOrgs'],
  [TriggerConditionType.CreatedAtElapsed, 'Elapsed'],
  [TriggerConditionType.ViewCount, 'Count'],
])

const operatorByConditionType = new Map<TriggerConditionType, Operator>([
  [TriggerConditionType.StatusInOrNotIn, Operator.In],
  [TriggerConditionType.DaysInStatus, Operator.GreaterThanOrEqual],
  [TriggerConditionType.TriggerCountLimit, Operator.LessThan],
])

const defaultValueByConditionType = new Map<TriggerConditionType, ValidConditionValue>([
  [TriggerConditionType.DaysInStatus, '3'],
  [TriggerConditionType.TriggerCountLimit, 3],
  [TriggerConditionType.DaysBetweenReminders, ''],
])

const getValueByConditionType = (t: TriggerConditionType, val?: ValidConditionValue): ValidConditionValue | undefined => {
  if (val === undefined) return
  switch (t) {
    case TriggerConditionType.CreatedBeforeOrAfter:
      return String(val)
    case TriggerConditionType.StatusInOrNotIn:
      return (val as string[]).map((v) => {
        return String(v)
      })
    case TriggerConditionType.NotifyParentOrgs:
      return Boolean(val)
    case TriggerConditionType.DaysInStatus:
    case TriggerConditionType.DaysBetweenReminders:
    case TriggerConditionType.CreatedAtElapsed:
    case TriggerConditionType.ViewCount:
      const countValue = Number(val)
      return countValue
    case TriggerConditionType.TriggerCountLimit:
      return Number(val)
  }
}

export const serializeTriggerCondition = (condition: UITriggerCondition): APITriggerCondition => {
  const ret: APITriggerCondition = {
    Required: condition.required,
    Type: condition.type,
    Operator: operatorByConditionType.get(condition.type),
  }

  if (condition.value !== undefined) {
    ret[condition.dataKey] = getValueByConditionType(condition.type, condition.value)
  }

  return ret
}

export const deserializeTriggerCondition = (i18n: i18nSelectorMap, condition: APITriggerCondition): UITriggerCondition => {
  const ret: UITriggerCondition = {
    required: condition.Required,
    type: condition.Type,
    dataKey: dataKeyByConditionType.get(condition.Type) ?? 'Unknown',
    i18n: i18n(`rules.detail.page.condition.${condition.Type}` as i18nKey),
    helpText: i18n(`rules.detail.page.condition.helpText.${condition.Type}` as i18nKey),
    operator: operatorByConditionType.get(condition.Type),
  }

  if (condition[ret.dataKey] !== undefined) {
    ret.value = getValueByConditionType(condition.Type, condition[ret.dataKey])
  }

  return ret
}

export const newWebhookAction = (): Action => {
  return {
    ID: '',
    Type: ActionType.WebhookHTTPAction,
    Content: {
      Type: 'action.webhook.http',
      URL: '',
    },
    Name: '',
    Description: '',
    ExplicitAccessList: [],
    ApplyToTree: false,
  }
}

export const newEmailAction = (): Action => {
  return {
    ID: '',
    Type: ActionType.NotificationEmailAction,
    Content: {
      Type: 'action.notification.email',
      URL: '',
    },
    ExplicitAccessList: [],
    ApplyToTree: false,
  }
}

export const conditionsFromConditionDeps = (conditions: UITriggerCondition[]): UITriggerCondition[] => {
  const needsUpdate = conditions.some((c) => {
    return c.type === TriggerConditionType.TriggerCountLimit && Number(c.value) === 1
  })

  return conditions.reduce((acc: UITriggerCondition[], currVal: UITriggerCondition): UITriggerCondition[] => {
    if (currVal.type === TriggerConditionType.DaysBetweenReminders) {
      const updatedValue = needsUpdate ? '0' : currVal.value
      acc.push({
        ...currVal,
        value: updatedValue,
        forceDisabled: needsUpdate,
      })
      return acc
    }
    acc.push(currVal)
    return acc
  }, [])
}
