import { ANALYTICS, API_ERROR, API_SUCCESS, apiRequest, GET_ANALYTICS, setAnalytics, setAnalyticsError, setDocuments, setSchema } from '../actions'
import { Schema } from '../schemas'
import { analyticsTitleSelector, focusedOrgSelector } from '../selectors'
import { Middleware } from '../store'
import { V2Response } from '../types'

export const analyticsMiddleware: Middleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  const state = getState()
  const org = focusedOrgSelector(state)

  switch (action.type) {
    case GET_ANALYTICS:
      dispatch(
        apiRequest({
          url: `/organizations/${org?.trunkID}/${org?.ID}/analytics/${action.dashboardType}`,
          method: 'GET',
          feature: ANALYTICS,
        })
      )
      break

    case `${ANALYTICS} ${API_SUCCESS}`:
      // set totalCount to -1 so the AppBar Title doesn't display a count
      dispatch(
        setDocuments(({
          totalCount: -1,
        } as unknown) as V2Response)
      )
      // the AppBar needs a title for analytics
      dispatch(
        setSchema({
          label: analyticsTitleSelector(state),
        } as Schema)
      )
      dispatch(setAnalytics(action.payload))
      break

    case `${ANALYTICS} ${API_ERROR}`:
      dispatch(setAnalyticsError(action.payload))
      break
  }
}
