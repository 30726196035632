import { cloneDeep as clone } from 'lodash'
import { Resource } from '../resource'
import { Category } from '../types/Schema'

export const formatModifiedByMessage = (name: string, date: number) => {
  const d = new Date(date * 1000)
  return `Modified by ${name} | ${d.toLocaleDateString('en-US')}, ${d.toLocaleTimeString(['en-US'], {
    hour: '2-digit',
    minute: '2-digit',
  })}`
}

// duplicateSchema resets locale schema fields.
// Used when duplicating a locale to another locale within the same
// locale group, and should hide the Translations card
export const duplicateSchema = (schema: Schema): Schema => {
  const duplicate = clone(schema)
  duplicate.localeGroup = []
  duplicate.fields = duplicate.fields.map((field) => {
    if ('locale' === field.key) {
      if (field?.restrictions?.enums?.length) {
        // our new dupe will create a new locale-group
        // so none of the locale enums should be disabled
        field.restrictions.enums = field.restrictions.enums.map((enumerable) => ({
          ...enumerable,
          disabled: false,
        }))
      }
    }
    return field
  })
  return duplicate
}

// translationSchema filters locale schema enums by the provided locale.
// Used when duplicating a locale from a duplication-source locale-group into
// target locale-group, because command-center does not have locale-group info
// for target locale-group which describes target locales available for translation
// should hide the Translations card
export const translationSchema = (schema: Schema, locale: string): Schema => {
  const duplicate = duplicateSchema(schema)
  duplicate.fields = duplicate.fields.map((field) => {
    if ('locale' === field.key) {
      if (field?.restrictions?.enums?.length) {
        field.restrictions.enums = field.restrictions.enums.filter((enumerable) => enumerable.value === locale)
      }
    }
    return field
  })
  return duplicate
}

type ListConfig = {
  defaultSortDirection: string
  isDefaultSort: boolean
  isDraggable: boolean
  sortOrder: number
  type: string
}

export type FilterConfig = {
  defaultValues: string[]
  isFilter: boolean
  presentation: string
  sortOrder: number
  singleSelect: boolean
  additionalOptions: Enum[] | null
}

export type Locale = {
  value: string
  translatedID: string
  requiresTitle: boolean
  hasTranslation: boolean
}

export type LocaleTranslation = {
  locale: string
  localeID: string
  localeDescription: string
  translation?: Resource
  duplicatedFromTranslation?: Resource
  isPrimary: boolean
}

type Modification = {
  path: string[]
  position: number
  modified: number
  modifiedBy: string
  action: string
  previousValue: unknown
  modifiedValue: unknown
  modifications: Modification[]
}

export type SetInstruction = {
  from: string
  to: string
}

export type AutoComplete = {
  match: string
  resource: string
  set?: SetInstruction[]
  scope?: unknown[]
  textField: string
  valueField: string
}

export type Section = {
  ID?: string
  internalOnly: boolean
  label: string
  help: string
  fields: string[][]
  sections?: Section[]
}

// TODO: the Field type probably needs fixing at this point, there are some
// fields that have `omitempty` on the platform but are required here
export type Field = {
  allowTQL: boolean
  autoComplete?: AutoComplete
  blockOnContentConfiguration: boolean
  dataSource: string
  defaultValue: string
  embeddedTqlAllowed: boolean
  expandable: boolean
  field?: string
  fields: Field[] | null
  filterConfig: FilterConfig
  goTags: unknown
  help: string
  importPath: string
  importedResource: string
  inUse: boolean
  isFilter: boolean
  isImmutable: boolean
  join?: unknown
  key: string
  label: string
  listConfig: ListConfig
  modification: Modification | null
  overRides: unknown
  presentation: string
  presentationOptions?: any
  primitive: string
  restrictions?: Restrictions
  search?: unknown
  showOnList: boolean
  userCanEdit: boolean
}

export enum FieldPrimitive {
  String = 'string',
  StringList = 'string_list',
  Object = 'object',
  JoinString = 'joinString',
}

export type Restrictions = {
  email: boolean
  enums?: Enum[]
  enumsFrom?: unknown
  max: number
  min: number
  referenceCollection: string
  required: boolean
  step?: number
}

export type Enum = {
  disabled: boolean
  label: string
  value: string
}

export type Schema = {
  allowTQL: boolean
  availableLocales: Locale[]
  canCreate: boolean
  category?: string
  categories: Category[]
  collection: string
  config: unknown
  dataSource: string
  defaultValue: string
  embeddedTqlAllowed: boolean
  enabled: boolean
  exportConfig: {
    isAllowed: boolean
    allowRealTimeDownload: boolean
    excludedFields: string[]
  }
  fields: Field[]
  filterConfig?: any
  goTags: any
  headerResource: string
  help: string
  icon: string
  importPath: string
  importedResource: string
  inUse: boolean
  isDuplicable: boolean
  isExportable: boolean
  isFilter: boolean
  isImmutable: boolean
  isHeader: boolean
  isLinkable: boolean
  isReadOnly: boolean
  key: string
  label: string
  listConfig?: ListConfig
  localeGroup: LocaleTranslation[]
  many: boolean
  maxFacetHits?: number
  maxValuesPerFacet?: number
  modification?: any
  modifiedCount: number
  overRides?: any
  position?: number
  presentation: string
  primitive: string
  privateSearch: boolean
  publicSearch: boolean
  resource: string
  restrictions: any
  sections?: Section[]
  shouldModerate: boolean
  shouldSyndicate: boolean
  showOnList: boolean
  sideEffects: any[]
  singular: string
  sortOrder: number
  standardSelectors: unknown[]
  statusIsContentConfigurable: boolean
  userCanRead: boolean
  userCanCreate: boolean
  userCanDelete: boolean
  userCanDuplicate: boolean
  userCanEdit: boolean
}
