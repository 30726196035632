export enum RouteActionType {
  CategorySet = '[route] Category Set',
  CategoryUnset = '[route] Category Unset',
}

export type CategorySetAction = {
  type: RouteActionType.CategorySet
  category: string
}

export const setCategory = (category: string): CategorySetAction => ({
  type: RouteActionType.CategorySet,
  category,
})

export type CategoryUnsetAction = {
  type: RouteActionType.CategoryUnset
}

export const unsetCategory = (): CategoryUnsetAction => ({
  type: RouteActionType.CategoryUnset,
})

export type AnyRouteAction = CategorySetAction | CategoryUnsetAction
