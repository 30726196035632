import { compose, defaultTo, isEmpty } from 'ramda';
import React, { Component } from 'react';
import { isEqual } from '../../lib';
import { Field, FieldPrimitive, formatModifiedByMessage } from '../../schemas';
import { Option } from '../../types';
import FieldMessage from '../FieldMessage/FieldMessage';
import Options from '../Options/Options';
import './Select.scss';
import { genCIAttr } from '../../util';
const EMPTY_VALUE_STRING = '-';
export const defaultIfEmpty = compose(defaultTo(EMPTY_VALUE_STRING), (value: any) => isEmpty(value) ? null : value);

// This is a very basic implementation with nearly all items set to
// optional - the props are messy in actual usage but relying on
// just `this.props` in the code is annoying!
type SelectProps = {
  className?: string;
  required?: boolean;
  value: Option[] | undefined;
  multiple?: boolean;
  disabled?: boolean;
  label?: string;
  field?: Field;
  readOnly?: boolean;
  placeholder?: string;
  icon?: string;
  hideLabel?: boolean;
  error?: string;
  options: Option[];
  modifiedBy?: string;
  modifiedAt?: number;
  focusedFilter?: string;
  dataCIKey: string | undefined;
  onChange: (value: Option[]) => void;
  onClose?: (label: string) => void;
};
type SelectState = {
  selected: Option[];
  showOptions: boolean;
};
export default class Select extends Component<SelectProps, SelectState> {
  static getDerivedStateFromProps = (nextProps: SelectProps, prevState: SelectState) => {
    if (!isEqual(prevState.selected, nextProps.value)) {
      return {
        ...prevState,
        selected: nextProps.value ?? []
      };
    }
    return null;
  };
  constructor(props: SelectProps) {
    super(props);
    let showOptions = false;
    if (props.focusedFilter && props.focusedFilter === props.label) {
      showOptions = true;
    }
    this.state = {
      selected: this.props.value ?? [],
      showOptions: showOptions
    };
  }
  componentDidMount() {
    document.addEventListener('click', this.handleOffClick);
    document.addEventListener('keydown', this.handleOffClick);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleOffClick);
    document.removeEventListener('keydown', this.handleOffClick);
  }
  component: any;
  handleOffClick = (event: any) => {
    if (this.state.showOptions && this.component && !this.component.contains(event.target)) {
      this.setState({
        showOptions: false
      });
      // set focusedFilter to empty!
      this.close(event);
    }
  };
  handleFieldClick = (event: any) => {
    event.stopPropagation();
    if (!this.props.disabled) {
      this.setState({
        showOptions: true
      });
    }
  };
  field: any;
  close = (event?: any) => {
    // set focusedFilter to empty!
    if (this.props.onClose && this.props.label) {
      this.props.onClose(this.props.label);
    }
  };
  handleFieldKeyDown = (event: any) => {
    event.stopPropagation();
    if (event.key === 'Enter' || event.key === ' ') {
      if (!this.props.disabled) {
        this.setState({
          showOptions: !this.state.showOptions
        });
        // set focusedFilter to empty!
        this.close(event);
      }
    } else if (event.key === 'Escape') {
      this.setState({
        showOptions: false
      });
      this.field.focus();
      // set focusedFilter to empty!
      this.close(event);
    }
  };
  handleOptionSelect = (value: Option[]) => {
    const shouldStayOpen = this.props.multiple || false;
    this.props.onChange(value);
    this.setState({
      selected: value,
      showOptions: shouldStayOpen
    });
    this.field.focus();
    if (!shouldStayOpen) {
      this.close();
    }
  };
  renderIcon = (icon: string | undefined) => {
    if (!icon) return null;
    return <div className={'icon ' + icon} />;
  };
  getFieldKey(): string | undefined {
    const fieldKey = this.props.field?.key;
    if (!fieldKey || fieldKey.length === 0) return undefined;
    return fieldKey;
  }
  renderField = (fieldID: any) => {
    let selectedText = '';
    if (this.state.selected.length > 0) {
      selectedText = this.state.selected.map((selection: any) => selection.label).join(', ');
    }
    if (!this.props.readOnly) {
      let textClass = 'text';
      if (!selectedText && this.props.placeholder) {
        textClass += ' placeholder';
        selectedText = this.props.placeholder;
      }
      return <div className="field" id={fieldID} data-ci={genCIAttr('select', this.props.dataCIKey)} onClick={this.handleFieldClick} onKeyDown={this.handleFieldKeyDown} ref={field => {
        this.field = field;
      }} role="button" tabIndex={0}>
          {this.renderIcon(this.props.icon)}
          <div className={textClass}>{selectedText}</div>
          <div className="arrow" />
          {this.renderOptions()}
        </div>;
    }

    // if the field is readOnly and the selectedText is an empty string (undefined, null, ''), then it needs to be set to the determined default
    selectedText = defaultIfEmpty(selectedText);
    return <div className="text" id={fieldID} data-ci={genCIAttr('select', this.props.dataCIKey, 'readOnly')}>
        {selectedText}
      </div>;
  };
  renderLabel = (label: any) => {
    const fieldKey = this.getFieldKey();
    const fieldID = fieldKey || 'field-ID-' + Date.now();
    if (!label) {
      return this.renderField(fieldID);
    }
    return <label htmlFor={fieldID}>
        {this.props.hideLabel ? null : label}
        {this.renderField(fieldID)}
      </label>;
  };
  renderMessage = () => {
    if (this.props.error) {
      return <FieldMessage error={this.props.error} />;
    }
    return null;
  };
  renderOptions = () => {
    if (this.props.options && this.props.options.length && !this.props.readOnly && this.state.showOptions) {
      return <Options field={this.props.field} multiple={this.props.multiple} onSelect={this.handleOptionSelect} options={this.props.options} selected={this.state.selected} dataCIKey={this.props.dataCIKey} />;
    }
    return null;
  };
  renderModerationMessage = () => {
    const {
      modifiedBy,
      modifiedAt
    } = this.props;
    if (!modifiedBy || !modifiedAt) {
      return null;
    }
    return <span data-ci="moderation-message">{formatModifiedByMessage(modifiedBy, modifiedAt)}</span>;
  };
  render = () => {
    let classList = 'Select';
    if (this.props.className) {
      classList += ` ${this.props.className}`;
    }
    if (this.props.error) {
      classList += ' has-error';
    }
    if (this.props.disabled) {
      classList += ' is-disabled';
    }
    if (this.props.required) {
      classList += ' is-required';
    }
    // isModified is used to show modified fields in Draft/Pending records so they can be found and approved. - JamesS
    if (this.props.modifiedBy && this.props.modifiedAt) {
      classList += ' is-modified';
    }
    return <div data-ci={genCIAttr('select-wrapper', this.props.dataCIKey)} className={classList} ref={component => {
      this.component = component;
    }}>
        {this.renderModerationMessage()}
        {this.renderLabel(this.props.label)}
        {this.renderMessage()}
      </div>;
  };
}