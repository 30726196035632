import { FlagActionTypes, setFlags } from '../actions'
import config from '../config'
import defaultFlags from '../flags'
import { calculateFlags } from '../flags/flags'
import { userSelector } from '../selectors'
import { Middleware } from '../store'

export const flagsMiddleware: Middleware = ({ getState, dispatch }) => (next) => (action) => {
  next(action)

  switch (action.type) {
    case FlagActionTypes.CALCULATE_FLAGS:
      const state = getState()
      const user = userSelector(state)
      // This variable is checked at runtime
      const environment = config.environment!
      const flags = calculateFlags({ user, environment }, defaultFlags)
      dispatch(setFlags(flags))
      break
  }
}
