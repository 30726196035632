export const EXPORT = '[export]'
export const EXPORT_DOCS = '[export] docs'

interface ExportDocsAction {
  type: typeof EXPORT_DOCS
}

export const exportDocs = (): ExportDocsAction => ({
  type: EXPORT_DOCS,
})
