import {
  API_ERROR,
  API_SUCCESS,
  apiRequest,
  clearPasswordResetError,
  loadDefaultPage,
  PASSWORD,
  SAVE_PASSWORD,
  setPasswordResetError,
} from '../actions'
import { Middleware } from '../store'
import { defaultSchemaSelector } from '../selectors'

export const passwordMiddleware: Middleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  const state = getState()

  switch (action.type) {
    case SAVE_PASSWORD:
      dispatch(
        apiRequest({
          url: '/credentials/change',
          method: 'POST',
          body: action.payload,
          feature: PASSWORD,
        })
      )
      break

    case `${PASSWORD} ${API_SUCCESS}`:
      dispatch(clearPasswordResetError())
      dispatch(loadDefaultPage(defaultSchemaSelector(state)))
      break

    case `${PASSWORD} ${API_ERROR}`:
      dispatch(setPasswordResetError(action?.payload?._message ?? ''))
      break
  }
}
