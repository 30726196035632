import { i18nActions, i18nActionTypes } from '../actions/i18n'
import enUS from '../i18n/en-US.json'
import { setLocalStorage } from '../util'
import config from '../config'
import { Enum } from '../types'

export type i18nMap = typeof enUS
export type i18nKey = keyof i18nMap

export type i18nState = {
  locale: string
  translations: i18nMap
  availableLocales: Enum[]
}

export const initialI18nState = {
  locale: 'en-US',
  translations: enUS,
  availableLocales: [
    {
      label: 'Deutsch',
      value: 'de-DE',
      disabled: false,
    },
    {
      label: 'English',
      value: 'en-US',
      disabled: false,
    },
    {
      label: 'Español',
      value: 'es-ES',
      disabled: false,
    },
    {
      label: 'Français',
      value: 'fr-FR',
      disabled: false,
    },
    {
      label: 'Italiano',
      value: 'it-IT',
      disabled: false,
    },
  ],
}

export type i18nSelectorMap = (key: i18nKey) => string

const i18nReducer = (state: i18nState = initialI18nState, action: i18nActions): i18nState => {
  switch (action.type) {
    case i18nActionTypes.SET_LOCALE:
      setLocalStorage(config.languageKey, action.locale)
      return {
        ...state,
        locale: action.locale,
      }
    case i18nActionTypes.SET_TRANSLATIONS:
      return {
        ...state,
        translations: action.translations,
      }
    default:
      return state
  }
}

export default i18nReducer
