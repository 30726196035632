import { cloneDeep, get as getPath, set as setPath } from 'lodash'
import { allPass, compose, curry, dropLast, lensPath, merge, pathOr, propOr, reduce, set, view } from 'ramda'
import { Field } from '.'

const notIn = (list: any) => (presentationKey: any) => {
  // if the key has a falsey value, then it's not in the "blocked" list
  if (!presentationKey) return true
  if (!Array.isArray(list)) return true
  return (list || []).indexOf(presentationKey) < 0
}

const isMultiSetField = allPass([
  compose((v: any) => v.length, pathOr('', ['autoComplete', 'match'])),
  compose(notIn(['reference', 'references']), propOr('', 'presentation')),
])

// $FlowFixMe
const setFieldValue = curry((path: Array<any>, fieldSchema: Field, value: any, doc: any) => {
  // some fields require that their siblings be set with a new value (autocomplete fields)
  if (isMultiSetField(fieldSchema) && path[0] !== 'tagIDs') {
    // the parent path needs to be used so that we're setting the siblings of the field being edited
    // and not the children of the edited field.
    const parentPath = dropLast(1, path)
    const mapInstructions = fieldSchema?.autoComplete?.set ?? []
    // get the 'old' value (from 'doc')
    const oldDoc: any = view(lensPath(parentPath), doc)
    // get the "new" values from the "value" parameter and the "from" attribute in the "mapInstructions"
    const mappedFields = reduce(
      (acc: any, curr: any) => {
        acc[curr.to] = value[curr.from]
        return acc
      },
      {},
      mapInstructions
    )
    const newDoc = merge(oldDoc, mappedFields)
    return set(lensPath(parentPath), newDoc, doc)
  }

  // apply autoComplete setInstructions to the value
  if (fieldSchema?.autoComplete?.set?.length) {
    // setPath doesn't work w/out cloning?
    value = cloneDeep(value)
    // apply autoComplete setInstructions
    fieldSchema.autoComplete.set.forEach((setInstruction) => setPath(value, setInstruction.to, getPath(value, setInstruction.from)))
  }

  // setting single property
  return set(lensPath(path), value, doc)
})

export default setFieldValue
