import { createMuiTheme, Theme } from '@material-ui/core/styles'
import rootScss from '../components/root.module.scss'
import darkRootScss from '../components/root_drk.module.scss'
import { UserSettings } from '../types'

const spacer = 8

const spacing = (num1: number, num2?: number): string => {
  if (num2) {
    return `${spacer * num1}px ${spacer * num2}px`
  }
  return `${spacer * num1}px`
}

// Dumb thing. Injecting Sass variables when importing root.scss works fine in webpack,
// but exports obj is undefined in jest tests. This function allows us to fall back to #fff in tests
const getRootScssFn = (scss: Record<string, string>) => (varName: string): string => {
  const value = scss[varName]
  if (value === undefined) {
    console.warn('Tried to get a root.scss variable named ' + varName + " that doesn't exist. Defaulting to #fff")
    return '#fff'
  }
  return value
}

/**
 * New Theme variables can be added below to either add new colors or new properties that can be used
 * like makeStyles(theme => { ..., value: { cssProp: theme.palette.newProp } })
 */
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    architectDrawer: string
    listItemOver: string
    tableBandedBackground: string
  }

  interface PaletteOptions {
    architectDrawer: string
    listItemOver: string
    tableBandedBackground: string
  }
}
const theme = function (getRootScss: (what: string) => string) {
  return createMuiTheme({
    spacing,
    palette: {
      primary: {
        main: getRootScss('primary'),
        light: getRootScss('listHover'),
      },
      secondary: {
        main: getRootScss('secondary'),
      },
      error: {
        main: getRootScss('error'),
        light: getRootScss('error'),
      },
      text: {
        primary: getRootScss('primaryTextColor'),
        secondary: getRootScss('secondaryTextColor'),
        disabled: getRootScss('fieldDisabledText'),
      },
      background: {
        paper: getRootScss('appBgColor'),
        default: getRootScss('appBgColor'),
      },
      divider: getRootScss('borderColor'),
      architectDrawer: getRootScss('navBgColor'),
      listItemOver: getRootScss('ui_90'),
      tableBandedBackground: getRootScss('tableBandedBackground'),
    },
    typography: {
      fontFamily: ['Europa'].join(','),
    },
    props: {
      MuiIconButton: {
        color: 'inherit',
      },
    },
    overrides: {
      MuiList: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      MuiInputBase: {
        root: {
          border: getRootScss('border1pxSolid'),
          borderRadius: 4,
          cursor: 'inherit',
          padding: spacing(0.5, 1),
          backgroundColor: getRootScss('fieldBgColor'),
          '&.Mui-focused': {
            borderColor: getRootScss('fieldFocus'),
          },
          '&.MuiInput-underline:before': {
            borderBottom: 'none',
          },
          '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
          },
          '&.MuiInput-underline:after': {
            borderBottom: 'none',
          },
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: getRootScss('primary'),
          '&:hover': {
            borderColor: getRootScss('primary'),
          },
        },
        input: {
          padding: spacing(1),
        },
      },
      MuiTable: {
        root: {
          borderSpacing: '0 3px',
        },
      },
      MuiTableRow: {
        root: {
          borderBottom: getRootScss('border1pxSolid'),
          '&$selected': {
            backgroundColor: getRootScss('tableBandedBackground'),
          },
        },
      },
      MuiTableCell: {
        root: {
          color: getRootScss('secondaryTextColor'),
          borderBottom: getRootScss('border1pxSolid'),
          paddingTop: '6px',
          paddingBottom: '6px',
        },
      },
      MuiTablePagination: {
        root: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
      MuiSelect: {
        select: {
          background: 'none',
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiButton: {
        root: {
          textTransform: 'initial',
          '&.Mui-disabled': {
            color: getRootScss('fieldDisabledText'),
            backgroundColor: getRootScss('fieldDisabledBackground'),
          },
        },
        contained: {
          '&.Mui-disabled': {
            color: getRootScss('fieldDisabledText'),
            backgroundColor: getRootScss('fieldDisabledBackground'),
          },
        },
      },
      MuiFormLabel: {
        asterisk: {
          display: 'none',
        },
        root: {
          fontSize: '1.1rem',
          '&.Mui-required::before': {
            content: '"* "',
            color: getRootScss('error'),
          },
        },
      },
    },
  })
}

export const getTheme = (userSettings: UserSettings) => {
  switch (userSettings.theme) {
    case 'dark':
      return getRootScssFn(darkRootScss) //dark theme
    case 'light':
    default:
      return getRootScssFn(rootScss) //light theme
  }
}
export default function (t?: string): Theme {
  switch (t) {
    case 'dark':
      return theme(getRootScssFn(darkRootScss)) //dark theme
    case 'light':
    default:
      return theme(getRootScssFn(rootScss)) //light theme
  }
}
