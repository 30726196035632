import { AppState } from '../store'
import { Trigger, TriggerKeys, TriggerType, TriggerUIErrors } from '../v3/type/Trigger'
import { Action, ActionType } from '../v3/type/Action'
import { focusedOrgSelector } from './session'
import { scopeSelector } from './scope'
import { getV3DefaultOwnerID, IDFromV2 } from '../util'
import { Enum, Option, OptionType, Scope, V3Organization } from '../types'
import { selectActiveV3Orgs, V3OrganizationsSelector } from './V3Organizations'
import { CheckboxOption } from '../v3/component/CheckboxGroup/CheckboxGroup'
import { UITriggerCondition } from '../v3/type/Condition'
import { selecti18n } from './i18n'
import { selectLeadStatusEnums } from './contentConfig'

export const triggerSelector = (state: AppState): Trigger => {
  return state.triggers.trigger
}

export const selectInitialTrigger = (state: AppState): Trigger => {
  return state.triggers.initialTrigger
}

export const triggerScopeSelector = (state: AppState): Scope => {
  return state.triggers.scope
}

export const triggerErrorSelector = (state: AppState): string | undefined => {
  return state.triggers.triggerError
}

export const selectTriggerEditorErrors = (state: AppState): TriggerUIErrors => {
  return state.triggers.editorErrors
}

export const selectTriggerEditorErrorsCount = (state: AppState): number => {
  const errors = selectTriggerEditorErrors(state)
  let count = 0
  for (const value of Object.values(errors)) {
    if (value) {
      count++
    }
  }
  return count
}

export const selectTriggerFieldHasError = (state: AppState, field: TriggerKeys): string | undefined => {
  const errors = selectTriggerEditorErrors(state)
  return errors[field]
}

export const editorErrorSelector = (state: AppState): TriggerUIErrors => {
  return state.triggers.editorErrors
}

export const cachedActionsSelector = (state: AppState): Action[] => {
  return state.triggers.cachedActions
}

export const selectDefaultTriggerOwner = (state: AppState): string => {
  const focusedOrg = focusedOrgSelector(state)
  const scope = scopeSelector(state)
  switch (true) {
    case scope === Scope.PlatformSystem:
      return getV3DefaultOwnerID()
    case focusedOrg && focusedOrg.ID !== '':
      return IDFromV2(focusedOrg!.ID)
    default:
      return ''
  }
}

export const selectTriggerOrgOptions = (state: AppState): Option[] => {
  const scope = triggerScopeSelector(state)
  if (scope === Scope.PlatformSystem) {
    return [
      {
        type: OptionType.Enum,
        label: selecti18n(state, 'rules.detail.list.ruleOwner.default'),
        value: getV3DefaultOwnerID(),
      },
    ]
  }
  const v3Orgs = selectActiveV3Orgs(state)
  return v3Orgs.reduce((acc: Option[], org: V3Organization) => {
    if (org.ID === '') {
      return acc
    }
    acc.push({
      type: OptionType.Enum,
      label: org.Name ?? '',
      value: org.ID,
    })
    return acc
  }, [])
}

export const selectTriggerOwnerName = (state: AppState, trigger: Trigger): string => {
  if (trigger.OwnerID === getV3DefaultOwnerID()) {
    return selecti18n(state, 'rules.detail.list.ruleOwner.default')
  }

  const v3Orgs = V3OrganizationsSelector(state)
  const ownerOrg = v3Orgs.find((org) => org.ID === trigger.OwnerID)
  if (!ownerOrg || !ownerOrg.Name) {
    return ''
  }

  return ownerOrg.Name
}

export const selectTriggerRuleTypeOptions = (state: AppState): Option[] => {
  return [
    {
      type: OptionType.Enum,
      label: selecti18n(state, 'rules.detail.page.ruleTypes.lead.created'),
      value: TriggerType.LeadCreatedTrigger,
    },
    {
      type: OptionType.Enum,
      label: selecti18n(state, 'rules.detail.page.ruleTypes.lead.modified'),
      value: TriggerType.LeadModifiedTrigger,
    },
    {
      type: OptionType.Enum,
      label: selecti18n(state, 'rules.detail.page.ruleTypes.lead.reminders'),
      value: TriggerType.LeadRemindersTrigger,
    },
  ]
}

export const selectHumanizedTriggerRuleType = (state: AppState, trigger: Trigger): string => {
  switch (trigger.Type) {
    case TriggerType.LeadCreatedTrigger:
      return selecti18n(state, 'rules.detail.page.ruleTypes.lead.created')
    case TriggerType.LeadModifiedTrigger:
      return selecti18n(state, 'rules.detail.page.ruleTypes.lead.modified')
    case TriggerType.LeadRemindersTrigger:
      return selecti18n(state, 'rules.detail.page.ruleTypes.lead.reminders')
    default:
      return trigger.Type
  }
}

export const selectTriggerStatusOptions = (state: AppState): Option[] => {
  const active = selecti18n(state, 'leads.status.enum.active.label')
  const inactive = selecti18n(state, 'rules.detail.page.status.inactive')
  return [
    {
      type: OptionType.Enum,
      label: active,
      value: 'true',
    },
    {
      type: OptionType.Enum,
      label: inactive,
      value: 'false',
    },
  ]
}

export const selectLeadStatusesAsCheckboxOptions = (state: AppState, condition: UITriggerCondition) => {
  const leadStatusEnums = selectLeadStatusEnums(state, false)
  return leadStatusEnums.map(
    (e: Enum): CheckboxOption => {
      let selected = false
      if (condition) {
        const selectedOptions = condition.value as string[]
        if (selectedOptions && selectedOptions.indexOf(e.value) > -1) {
          selected = true
        }
      }

      return {
        label: e.label,
        value: e.value,
        disabled: e.disabled,
        selected: selected,
      }
    }
  )
}

export const triggerTitleSelector = (state: AppState): string => {
  const trigger = triggerSelector(state)
  if (trigger.Name && trigger.Name !== '') {
    return trigger.Name
  }

  const triggerScope = triggerScopeSelector(state)
  if (triggerScope === Scope.PlatformSystem) {
    return selecti18n(state, 'nav.labels.rules.platformSystem')
  }

  return selecti18n(state, 'nav.labels.rules.customOrganization')
}

// TODO: these values need to be localized
export const selectActionOptions = (state: AppState): Option[] => {
  return [
    {
      type: OptionType.Enum,
      label: 'Webhook',
      value: ActionType.WebhookHTTPAction,
    },
    //TODO: bring back when we are working on email actions
    //{
    //  type: OptionType.Enum,
    //  label: 'Email',
    //  value: ActionType.NotificationEmailAction,
    //},
  ]
}

export const selectTriggerCountLimitOptions = (state: AppState): Option[] => {
  return Array.from(Array(10).keys()).reduce((acc: Option[], currVal: number): Option[] => {
    if (currVal !== 0) {
      acc.push({
        type: OptionType.Enum,
        label: currVal.toString(),
        value: currVal.toString(),
      })
    }
    return acc
  }, [])
}
