import { find, path } from 'ramda'
import { getListableFields } from '../schemas/listableFields'
import {
  focusedOrgSelector,
  limitSelector,
  moduleSelector,
  searchTextSelector,
  selectAPIFilters,
  selectFilters,
  skipSelector,
  sortSelector,
  v3LeadSortSelector,
} from '../selectors'
import { scopeSelector } from '../selectors/scope'
import { AppState } from '../store'
import { APIFilter, DateOption, DateOptionRangeType, EnumOption, FilterField, isEnumOption, Option, Scope } from '../types'
import * as Sentry from '@sentry/react'

const SINGLE_CONTEXT_URL = '/organizations/:trunkID/:organizationID/:resource'
const MULTI_CONTEXT_URL = '/:resource'

export const buildFilters = (state: AppState) => {
  return selectAPIFilters(state).reduce((obj: any, filter: APIFilter) => {
    const defaultFilter: string[] = path(['field', 'filterConfig', 'defaultValues'], filter) || []
    const filterValue = defaultFilter.join(',')
    const filterObj = {
      [filter.fieldPath.join('.')]: filterValue.length ? filterValue : null,
    }
    obj = {
      ...obj,
      ...(filterValue.length ? filterObj : {}),
    }
    return obj
  }, {})
}

const replacePathVariables = (pattern: any, params: any) => {
  const regex = /:\w+/g
  const matches = pattern.match(regex)
  return (
    (matches || []).reduce((acc: any, key: any) => {
      const value = (params || {})[key.slice(1)] || key
      return acc.replace(key, value)
    }, pattern) || pattern
  )
}
const addSort = (state: AppState) => {
  const module = moduleSelector(state)
  if (!module) {
    return {}
  }
  const schema = (module.schemas || [])[0]
  const sort = sortSelector(state)
  if (sort.column && sort.hasOwnProperty('direction')) {
    return {
      sort: `${sort.direction}${sort.column}`,
    }
  }
  // @ts-ignore strange ramda linting err
  const listableFields: any = getListableFields(schema, {})
  const defaultListConfig = find((v: any): any => path(['field', 'listConfig', 'isDefaultSort'], v), listableFields)
  const defaultDirection = path(['field', 'listConfig', 'defaultSortDirection'], defaultListConfig)
  if (defaultListConfig && defaultDirection) {
    return {
      sort: `${defaultDirection === 'descending' ? '-' : ''}${defaultListConfig.key}`,
    }
  }
  return {}
}

export const addV3Sort = (state: AppState) => {
  const sort = v3LeadSortSelector(state)

  if (sort.column) {
    return {
      _order: `${sort.column} ${sort.direction === '-' ? 'DESC' : 'ASC'}`,
    }
  }
}

const buildParams = (state: AppState) => {
  const focusedOrg = focusedOrgSelector(state)
  return {
    ...focusedOrg,
    organizationID: focusedOrg?.ID ?? '',
  }
}

const addOrg = (state: AppState) => {
  const module = moduleSelector(state)
  if (!module) {
    return {}
  }
  const scope = scopeSelector(state)
  if (scope !== Scope.All && !module.noMultiContext) {
    const focusedOrg = focusedOrgSelector(state)
    return {
      organizationID: focusedOrg?.ID ?? '',
      includeDescendants: scope === Scope.IncludeDescendants,
    }
  }
  return {}
}

const addSearch = (state: AppState) => {
  const searchText = searchTextSelector(state)
  if (searchText) {
    return {
      prefix: searchText,
    }
  }
  return {}
}

const createURL = (state: AppState) => {
  const module = moduleSelector(state)
  if (!module) return
  // Some category is laying around and causing this to crash - wrapping it a try/catch for debug
  try {
    const endpoint = module.noMultiContext ? SINGLE_CONTEXT_URL : MULTI_CONTEXT_URL
    const params = buildParams(state)
    const targetSchema = (module.schemas || [])[0]
    return replacePathVariables(endpoint, {
      ...params,
      resource: targetSchema?.resource,
    })
  } catch (e) {
    Sentry.captureException(e, {
      tags: {
        source: 'urlBuilder',
      },
      contexts: {
        module: module,
      },
    })
    console.error(`caught error with multicontext check for module:`, module, e)
  }
}

export const addAppliedFilters = (state: AppState) => {
  const appliedFilters = selectFilters(state)

  return appliedFilters.reduce((acc: Record<string, string | number>, curr: FilterField) => {
    if (!curr.selected || curr.selected.length === 0) {
      return acc
    }

    // A date range filter is two "options" as separate 'fields" in the UI, but
    // in reality its one field - so cover both option types and return.
    if (curr.presentation === 'dateRange') {
      const selected = curr.selected as DateOption[]
      selected.forEach((sel) => {
        if (sel.range === DateOptionRangeType.From) {
          acc['audit.createdGreaterThan'] = sel.value
        }
        if (sel.range === DateOptionRangeType.To) {
          acc['audit.createdLessThan'] = sel.value
        }
      })
      return acc
    }

    // A simple string array reduction is all that is needed for select dropdowns.
    if (curr.presentation === 'select') {
      const selected = curr.selected as EnumOption[]
      acc[curr.key] = selected
        .reduce((valAcc: string[], valCurr: Option) => {
          if (isEnumOption(valCurr)) {
            valAcc.push(valCurr.value)
            return valAcc
          }
          return valAcc
        }, [])
        .join(',')
      return acc
    }
    return acc
  }, {})
}

const urlBuilder = (state: AppState): any => {
  const url = createURL(state)
  if (!url) return
  const params = {
    ...buildFilters(state),
    ...addAppliedFilters(state),
    ...addSearch(state),
    limit: limitSelector(state),
    skip: skipSelector(state),
    ...addOrg(state),
    ...addSort(state),
  }
  return {
    url,
    params,
  }
}

export default urlBuilder
