import {
  apiRequest,
  DeleteTriggerDetailAction,
  DeleteTriggerErrorAction,
  GetTriggerDetailAction,
  GetTriggerDetailSuccessAction,
  GetTriggerListAction,
  GetTriggerListSuccessAction,
  hideLoader,
  hideModal,
  loadTriggerDetail,
  loadTriggersList,
  SaveTriggerDetailAction,
  SaveTriggerDetailSuccessAction,
  setDocuments,
  setSchema,
  setTrigger,
  setTriggerEditorError,
  setTriggerError,
  setTriggers,
  showLoader,
  TriggerActionType,
  unsetTriggerEditorError,
  UpdateTriggerAction,
} from '../actions'
import { Schema } from 'schemas'
import config from '../config'
import { Middleware } from '../store'
import { Scope, V2Response } from '../types'
import { focusedOrgSelector, i18n, selecti18n } from '../selectors'
import { getV3DefaultOwnerID, IDFromV2 } from '../util'
import { Query } from 'redux-first-router'
import { ResourceTypeType, Trigger, TriggerKeys, TriggerType } from '../v3/type/Trigger'
import { Action, CachedActionIDs } from '../v3/type/Action'
import { cachedActionsSelector, triggerScopeSelector, triggerSelector, triggerTitleSelector } from '../selectors/triggers'
import { APITriggerCondition, UITriggerCondition } from '../v3/type/Condition'
import { conditionsFromConditionDeps, defaultTriggerActionsByType, deserializeTriggerCondition, serializeTriggerCondition } from '../util/triggers'

export const triggersMiddleware: Middleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  const validateTriggerField = (field: string, value: any): string | undefined => {
    // TODO: see about using enums here that are already ready
    let message: string | undefined

    switch (field) {
      case 'Name':
        if (value.trim().length <= 2) {
          message = selecti18n(getState(), `rules.detail.page.validateField`)
        }
        break
      case 'Conditions.Status': {
        const val = (value as UITriggerCondition).value
        if (!val || (val as string[]).length === 0) {
          message = selecti18n(getState(), `rules.detail.page.condition.error.message.Status`)
        }
        break
      }
      case 'Conditions.DaysInStatus': {
        const val = Number((value as UITriggerCondition).value)
        if (isNaN(val) || val < 1) {
          message = selecti18n(getState(), `rules.detail.page.condition.error.message.DaysInStatus`)
        }
        break
      }
      case 'Conditions.DaysBetweenReminders': {
        const val = Number((value as UITriggerCondition).value)
        if (isNaN(val)) {
          message = selecti18n(getState(), `rules.detail.page.condition.error.message.DaysBetweenReminders`)
        }
        const trigger = triggerSelector(getState())
        if (trigger.Conditions) {
          const conditions = trigger.Conditions as UITriggerCondition[]
          conditions.forEach((c) => {
            if (c.dataKey === 'TriggerCount') {
              const cVal = Number(c.value)
              const triggerCountAboveTwo = !isNaN(cVal) && cVal >= 2
              const daysBetweenReminder = val > 14 || val < 1
              if (triggerCountAboveTwo && daysBetweenReminder) {
                message = selecti18n(getState(), `rules.detail.page.condition.error.message.DaysBetweenReminders`)
              }
            }
          })
        }
        break
      }
      case 'Conditions.TriggerCount':
        const val = Number((value as UITriggerCondition).value)
        if (isNaN(val)) {
          message = selecti18n(getState(), `rules.detail.page.validateField`)
        }
        const trigger = triggerSelector(getState())
        if (trigger.Conditions) {
          const conditions = trigger.Conditions as UITriggerCondition[]
          conditions.forEach((c) => {
            if (c.dataKey === 'DaysBetweenReminders') {
              const cVal = Number(c.value)
              const triggerCountAboveTwo = !isNaN(val) && val >= 2
              const daysBetweenReminder = cVal > 14 || cVal < 1
              let secondaryMessage: string | undefined
              if (triggerCountAboveTwo && daysBetweenReminder) {
                secondaryMessage = selecti18n(getState(), `rules.detail.page.condition.error.message.DaysBetweenReminders`)
              }
              dispatch(setTriggerEditorError('Conditions.DaysBetweenReminders', secondaryMessage))
            }
          })
        }
        break
      case 'Actions.Content.URL': // TODO: validate https?
        const action = value as Action
        if (!action.Content || !action.Content.URL || action.Content.URL?.trim().length <= 2) {
          message = selecti18n(getState(), `rules.detail.page.validateField`)
        }
        break
      case 'Conditions.NotifyParentOrgs':
      case 'Description':
      case 'Actions':
      case 'Conditions':
      case 'Type':
      case 'Status':
      case 'EffectiveDate':
      case 'Actions.Description':
      case 'Actions.Name':
      case 'ID':
      case 'ReadOnly':
      case 'CreatedAt':
      case 'CreatedBy':
      case 'UpdatedAt':
      case 'UpdatedBy':
      case 'OwnerID':
      case 'Executions':
      case 'LastProcessed':
      case 'ResourceType':
      case 'Cronjob':
        // these field don't need validation
        break
      default:
        console.warn('unknown trigger field encountered while validating:', field)
    }

    return message
  }

  const cacheActions = () => {
    const state = getState()
    Object.values(CachedActionIDs).forEach((id: string) => {
      const action = cachedActionsSelector(state).find((act) => {
        return act.ID === id
      })
      if (!action) {
        dispatch(
          apiRequest({
            url: `${config.v3Api}/action/${id}`,
            method: 'GET',
            feature: TriggerActionType.GET_CACHED_ACTIONS,
            scope: Scope.PlatformSystem,
            asOwnerOverride: getV3DefaultOwnerID(),
          })
        )
      }
    })
  }

  switch (action.type) {
    case TriggerActionType.LIST_GET: {
      const state = getState()
      const { location } = state
      const { query } = location
      const act = action as GetTriggerListAction
      dispatch(showLoader(action.type))
      cacheActions()
      dispatch(
        apiRequest({
          url: `${config.v3Api}/trigger`,
          method: 'GET',
          params: query,
          feature: TriggerActionType.LIST_GET,
          scope: act.payload.scope,
        })
      )
      break
    }

    case TriggerActionType.LIST_GET_SUCCESS:
      const act = action as GetTriggerListSuccessAction
      dispatch(
        setDocuments(({
          totalCount: act.payload.length,
        } as unknown) as V2Response)
      )
      dispatch(setTriggers(act.payload))
      dispatch(setTriggerError(undefined))
      dispatch(hideLoader(TriggerActionType.LIST_GET))
      break

    case TriggerActionType.DETAIL_GET: {
      const act = action as GetTriggerDetailAction
      const state = getState()
      const query = state.location.query ?? ({} as Query)
      query._preload = 'Actions'

      dispatch(showLoader(act.type))
      cacheActions()

      const org = focusedOrgSelector(state)

      if (act.payload.id === 'new') {
        let ownerID = IDFromV2(org!.ID)
        if (act.payload.scope === Scope.PlatformSystem) {
          ownerID = getV3DefaultOwnerID()
        }
        const trigger = {
          ResourceType: ResourceTypeType.LeadsCreated,
          Name: '',
          Type: TriggerType.LeadCreatedTrigger,
          Status: true,
          EffectiveDate: new Date().toISOString(),
          OwnerID: ownerID,
          Actions: defaultTriggerActionsByType(TriggerType.LeadCreatedTrigger, []),
        } as Trigger
        dispatch({ type: TriggerActionType.DETAIL_GET_SUCCESS, payload: [trigger] })
      } else {
        dispatch(
          apiRequest({
            url: `${config.v3Api}/trigger/${act.payload.id}`,
            method: 'GET',
            params: query,
            feature: TriggerActionType.DETAIL_GET,
            scope: act.payload.scope,
          })
        )
      }
      break
    }

    case TriggerActionType.DETAIL_GET_SUCCESS: {
      const act = action as GetTriggerDetailSuccessAction
      const payload = act.payload[0]

      if (payload.Conditions) {
        const deserialized: UITriggerCondition[] = (payload.Conditions as APITriggerCondition[]).map((cond: APITriggerCondition) => {
          return deserializeTriggerCondition(i18n(getState()), cond)
        })

        payload.Conditions = conditionsFromConditionDeps(deserialized)
      }

      dispatch(setTrigger(payload, true))
      dispatch(setTriggerError(undefined))
      dispatch(unsetTriggerEditorError())
      // Don't display a count
      dispatch(
        setDocuments(({
          totalCount: -1,
        } as unknown) as V2Response)
      )
      dispatch(
        setSchema({
          resource: 'trigger',
          label: triggerTitleSelector(getState()),
        } as Schema)
      )
      dispatch(hideLoader(TriggerActionType.DETAIL_GET))
      break
    }

    case TriggerActionType.SAVE_TRIGGER: {
      dispatch(showLoader(action.type))
      const state = getState()
      let trigger = (action as SaveTriggerDetailAction).payload
      let hasError = false

      for (const [key, value] of Object.entries(trigger)) {
        switch (key) {
          case 'Actions': {
            if (trigger.Type === TriggerType.LeadRemindersTrigger) break
            const values = trigger[key] as Action[] | undefined
            if (!values) break
            for (let i = 0; i < values.length; i++) {
              const fieldErr = validateTriggerField('Actions.Content.URL', values[i])
              if (fieldErr) {
                hasError = true
              }
              dispatch(setTriggerEditorError('Actions.Content.URL', fieldErr))
            }
            break
          }
          case 'Conditions': {
            const values = trigger[key] as UITriggerCondition[] | undefined
            if (!values) break
            for (let i = 0; i < values.length; i++) {
              const condition = values[i]
              const fieldErr = validateTriggerField(`Conditions.${condition.dataKey}`, condition)
              if (fieldErr) {
                hasError = true
              }
              dispatch(setTriggerEditorError(`Conditions.${condition.dataKey}` as TriggerKeys, fieldErr))
            }
            break
          }
          default:
            const fieldErr = validateTriggerField(key, value)
            if (fieldErr) {
              hasError = true
            }
            dispatch(setTriggerEditorError(key as TriggerKeys, fieldErr))
        }
      }

      if (hasError) {
        dispatch(hideLoader(action.type))
        break
      }

      if (trigger.Conditions) {
        const serialized: APITriggerCondition[] = (trigger.Conditions as UITriggerCondition[]).map((cond: UITriggerCondition) => {
          return serializeTriggerCondition(cond)
        })
        trigger = {
          ...trigger,
          Conditions: serialized,
        }
      }

      const createTrigger = !trigger.ID || trigger.ID === ''

      dispatch(
        apiRequest({
          url: createTrigger ? `${config.v3Api}/trigger` : `${config.v3Api}/trigger/${trigger.ID}`,
          method: createTrigger ? 'POST' : 'PUT',
          feature: action.type,
          scope: triggerScopeSelector(state),
          body: trigger,
        })
      )

      break
    }

    case TriggerActionType.SAVE_TRIGGER_SUCCESS:
      const state = getState()
      let updatedTrigger = (action as SaveTriggerDetailSuccessAction).payload
      if (updatedTrigger.Conditions) {
        const deserialized: UITriggerCondition[] = (updatedTrigger.Conditions as APITriggerCondition[]).map((cond: APITriggerCondition) => {
          return deserializeTriggerCondition(i18n(getState()), cond)
        })
        updatedTrigger = {
          ...updatedTrigger,
          Conditions: deserialized,
        }
      }
      const stateTrigger = triggerSelector(state)

      dispatch(setTrigger(updatedTrigger))
      dispatch(hideLoader(TriggerActionType.SAVE_TRIGGER))
      // If the trigger in state does not have an ID - it was a new trigger and we need to redirect and reload.
      if (!stateTrigger.ID || stateTrigger.ID === '') {
        dispatch(loadTriggerDetail(triggerScopeSelector(state), updatedTrigger.ID))
      }
      break

    case TriggerActionType.DELETE_TRIGGER: {
      const state = getState()
      const trigger = (action as DeleteTriggerDetailAction).payload

      dispatch(showLoader(action.type))
      dispatch(hideModal())
      dispatch(
        apiRequest({
          url: `${config.v3Api}/trigger/${trigger.ID}`,
          method: 'DELETE',
          feature: action.type,
          body: trigger,
          scope: triggerScopeSelector(state),
        })
      )
      break
    }

    case TriggerActionType.DELETE_TRIGGER_SUCCESS: {
      dispatch(setTriggerError(undefined))
      dispatch(loadTriggersList(triggerScopeSelector(getState())))
      dispatch(hideLoader(TriggerActionType.DELETE_TRIGGER))
      break
    }

    case TriggerActionType.DELETE_TRIGGER_ERROR:
      // Note: this will trigger the general error screen - its very hard to actually trigger a deletion error in the first place.
      dispatch(setTriggerError((action as DeleteTriggerErrorAction).payload.message))
      dispatch(hideLoader(TriggerActionType.DELETE_TRIGGER))
      break

    case TriggerActionType.UPDATE_TRIGGER: {
      const state = getState()
      const { field, value } = (action as UpdateTriggerAction).payload

      const fieldErr = validateTriggerField(field, value)
      dispatch(setTriggerEditorError(field, fieldErr))

      const wip = triggerSelector(state)
      let updatedValue = value
      let fieldKey = field

      const primaryField = field.substring(0, field.indexOf('.'))
      switch (primaryField) {
        case 'Conditions':
          const conditions = wip.Conditions as UITriggerCondition[]
          const conditionValue = value as UITriggerCondition

          updatedValue = conditions.map((condition: UITriggerCondition) => {
            if (condition.type === conditionValue.type) {
              return value
            }
            return condition
          })
          updatedValue = conditionsFromConditionDeps(updatedValue)
          fieldKey = primaryField
          break
        case 'Actions':
          updatedValue = [value]
          fieldKey = primaryField
          break
      }

      dispatch(
        setTrigger({
          ...wip,
          [fieldKey]: updatedValue,
        })
      )
      break
    }
  }
}
