import { RendererError } from '../types'

const formatRenderError = (error: RendererError): string => {
  const msg = error?.Message ?? 'Could not render page'

  const html = `
  <html>
    <style>
      html, body {
        margin: 0;
        padding: 0;
        font-family: Roboto, Arial;
      }
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .title {
        font-size: 2rem;
        font-weight: bold;
        margin: 8px;
      }
      #message {
        color: #b30000;
        background-color: #ffffc7;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        padding: 15px 25px;
        margin: 25px;
      }
      #hidden-text {
        position: absolute;
        top: -100px;
      }
      #copy {
        background-color: #1D6FBF;
        cursor: pointer;
        color: white;
        border: none;
        border-radius: 4px;
        font-weight: 500;
        line-height: 1.75;
        padding: 6px 16px;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
      }
      #copy:hover {
        background-color: rgb(20, 77, 133);
      }
    </style>
    <script>
      function copyToClipboard() {
        var copyText = document.getElementById('hidden-text');
        copyText.select();
        document.execCommand('copy');
        var btn = document.getElementById('copy');
        btn.innerText = 'Copied!';
        setTimeout(() => {
          btn.innerText = 'Copy Error';
        }, 3500);
      }
    </script>
  </html>
  <body>
    <div class="container">
      <h2 class="title">Rendering Error</h2>
      <p id="message">
        ${msg}
      </p>
      <button id="copy" onclick="copyToClipboard()">Copy Error</button>
      <input type="text" value="${msg}" id="hidden-text">
    </div>
  </body>
  `
  return btoa(html)
}

export default formatRenderError
