/**
 * Types for Autocomplete/select options
 */
import { ReferenceFieldValueDoc } from './ReferenceField'
import { Organization } from './Organization'
import { Scope } from './API'

export enum OptionType {
  Enum,
  Organization,
  ReferenceField,
  Date,
  Scope,
}

export type Option = EnumOption | OrganizationOption | ReferenceFieldOption | DateOption | ScopeOption

export const isOption = (opt: any): opt is Option => {
  return isEnumOption(opt) || isOrganizationOption(opt) || isReferenceFieldOption(opt) || isDateOption(opt) || isScopeOption(opt)
}

type baseOption = {
  label: string
  disabled?: boolean
  value: string
}
// EnumOption is just the platform Enum with `disabled` optional
export type EnumOption = baseOption & {
  type: OptionType.Enum
}

export const isEnumOption = (opt: any): opt is EnumOption => {
  return opt.type !== undefined && opt.type === OptionType.Enum
}

// OrganizationOption is used generally when an Autocomplete select field is being used for Organizations (ie. changing
// the focused org).
// The "Scope" filter still uses an EnumOption.
export type OrganizationOption = Omit<baseOption, 'value'> & {
  type: OptionType.Organization
  secondaryLabel: string
  value: Organization
}

export const isOrganizationOption = (opt: any): opt is OrganizationOption => {
  return opt.type !== undefined && opt.type === OptionType.Organization
}

// ReferenceFieldOption uses a ReferenceFieldValue for `value`, when using autocomplete / reference selects.
export type ReferenceFieldOption = Omit<baseOption, 'value'> & {
  type: OptionType.ReferenceField
  value: ReferenceFieldValueDoc
}

export const isReferenceFieldOption = (opt: any): opt is ReferenceFieldOption => {
  return opt.type !== undefined && opt.type === OptionType.ReferenceField
}

export enum DateOptionRangeType {
  From,
  To,
}

// DateOption uses a Unix timestamp for the value and tracks whether the date contained
// is for the "from" or the "to" part of a date range.
export type DateOption = Omit<baseOption, 'value'> & {
  type: OptionType.Date
  value: number
  range: DateOptionRangeType
}

export const isDateOption = (opt: any): opt is DateOption => {
  return opt.type !== undefined && opt.type === OptionType.Date
}

// ScopeOption uses a Scape for `value`, when using the OrganizationScope select.
export type ScopeOption = Omit<baseOption, 'value'> & {
  type: OptionType.Scope
  value: Scope
}

export const isScopeOption = (opt: any): opt is ScopeOption => {
  return opt.type !== undefined && opt.type === OptionType.Scope
}
