import { Trigger, TriggerKeys } from '../v3/type/Trigger'
import { Action } from '../v3/type/Action'
import { Scope } from '../types'

export enum TriggerActionType {
  // Detail CRUD
  DETAIL_GET = '[trigger] Detail Get',
  DETAIL_GET_ERROR = '[trigger] Detail Get API_ERROR',
  DETAIL_GET_SUCCESS = '[trigger] Detail Get API_SUCCESS',
  SAVE_TRIGGER = '[trigger] Detail Save',
  SAVE_TRIGGER_ERROR = '[trigger] Detail Save API_ERROR',
  SAVE_TRIGGER_SUCCESS = '[trigger] Detail Save API_SUCCESS',
  DELETE_TRIGGER = '[trigger] Delete',
  DELETE_TRIGGER_ERROR = '[trigger] Delete API_ERROR',
  DELETE_TRIGGER_SUCCESS = '[trigger] Delete API_SUCCESS',
  // List CRUD
  LIST_GET = '[triggers] List Get',
  LIST_GET_ERROR = '[triggers] List Get API_ERROR',
  LIST_GET_SUCCESS = '[triggers] List Get API_SUCCESS',
  // Cached actions CRUD
  GET_CACHED_ACTIONS = '[triggers] Get Cached Actions',
  GET_CACHED_ACTIONS_SUCCESS = '[triggers] Get Cached Actions API_SUCCESS',
  GET_CACHED_ACTIONS_ERROR = '[triggers] Get Cached Actions API_ERROR',
  // State updates
  SET_TRIGGER = '[trigger] Set',
  SET_TRIGGERS = '[triggers] Set',
  SET_SCOPE = '[triggers] Set Scope',
  UPDATE_TRIGGER = '[trigger] Update State',
  SET_TRIGGER_ERROR = '[triggers] Set Error',
  SET_EDITOR_ERROR = '[trigger] Set Editor Error',
  UNSET_EDITOR_ERROR = '[trigger] Unset Editor Error',
}

// GET Detail Page action
export type GetTriggerDetailAction = {
  type: TriggerActionType.DETAIL_GET
  payload: {
    id: string
    scope: Scope
  }
}

export const getTriggerDetail = (id: string, scope: Scope): GetTriggerDetailAction => ({
  type: TriggerActionType.DETAIL_GET,
  payload: {
    id: id,
    scope: scope,
  },
})

export type GetTriggerDetailErrorAction = {
  type: TriggerActionType.DETAIL_GET_ERROR
  payload: {
    message: string
  }
}

export type GetTriggerDetailSuccessAction = {
  type: TriggerActionType.DETAIL_GET_SUCCESS
  payload: Trigger[]
}

export type UpdateTriggerAction = {
  type: TriggerActionType.UPDATE_TRIGGER
  payload: {
    field: TriggerKeys
    value: any
    skipValidation?: boolean
  }
}

export const updateTrigger = (field: TriggerKeys, value: unknown, skipValidation?: boolean): UpdateTriggerAction => ({
  type: TriggerActionType.UPDATE_TRIGGER,
  payload: {
    field: field,
    value: value,
    skipValidation: skipValidation,
  },
})

export type SaveTriggerDetailAction = {
  type: TriggerActionType.SAVE_TRIGGER
  payload: Trigger
}

export const saveTrigger = (trigger: Trigger): SaveTriggerDetailAction => ({
  type: TriggerActionType.SAVE_TRIGGER,
  payload: trigger,
})

export type SaveTriggerDetailSuccessAction = {
  type: TriggerActionType.SAVE_TRIGGER_SUCCESS
  payload: Trigger
}

// GET List action
export type GetTriggerListAction = {
  type: TriggerActionType.LIST_GET
  payload: {
    scope: Scope
  }
}

export const getTriggerList = (scope: Scope): GetTriggerListAction => ({
  type: TriggerActionType.LIST_GET,
  payload: {
    scope: scope,
  },
})

export type GetTriggerListErrorAction = {
  type: TriggerActionType.LIST_GET_ERROR
  payload: {
    message: string
  }
}

export type GetTriggerListSuccessAction = {
  type: TriggerActionType.LIST_GET_SUCCESS
  payload: Trigger[]
}

export type SetTriggerAction = {
  type: TriggerActionType.SET_TRIGGER
  payload: {
    trigger: Trigger
    initialLoad: boolean
  }
}

export const setTrigger = (trigger: Trigger, initialLoad?: boolean): SetTriggerAction => ({
  type: TriggerActionType.SET_TRIGGER,
  payload: {
    trigger: trigger,
    initialLoad: !!initialLoad,
  },
})

export type SetTriggersAction = {
  type: TriggerActionType.SET_TRIGGERS
  payload: {
    triggers: Trigger[]
  }
}
export const setTriggers = (triggers: Trigger[]): SetTriggersAction => ({
  type: TriggerActionType.SET_TRIGGERS,
  payload: {
    triggers: triggers,
  },
})

// DELETE Detail Page action
export type DeleteTriggerDetailAction = {
  type: TriggerActionType.DELETE_TRIGGER
  payload: Trigger
}

export const deleteTriggerDetail = (trigger: Trigger): DeleteTriggerDetailAction => ({
  type: TriggerActionType.DELETE_TRIGGER,
  payload: trigger,
})

export type DeleteTriggerErrorAction = {
  type: TriggerActionType.DELETE_TRIGGER_ERROR
  payload: {
    message: string
  }
}

export type DeleteTriggerSuccessAction = {
  type: TriggerActionType.DELETE_TRIGGER_SUCCESS
}

export type SetTriggerScopeAction = {
  type: TriggerActionType.SET_SCOPE
  payload: Scope
}

export const setTriggerScope = (scope: Scope): SetTriggerScopeAction => ({
  type: TriggerActionType.SET_SCOPE,
  payload: scope,
})

export type SetTriggerErrorAction = {
  type: TriggerActionType.SET_TRIGGER_ERROR
  payload?: string
}

export const setTriggerError = (error?: string): SetTriggerErrorAction => ({
  type: TriggerActionType.SET_TRIGGER_ERROR,
  payload: error,
})

export type SetTriggerEditorErrorAction = {
  type: TriggerActionType.SET_EDITOR_ERROR
  payload: {
    field: TriggerKeys
    error: string | undefined
  }
}

export const setTriggerEditorError = (field: TriggerKeys, error: string | undefined): SetTriggerEditorErrorAction => ({
  type: TriggerActionType.SET_EDITOR_ERROR,
  payload: {
    field: field,
    error: error,
  },
})

export type UnsetTriggerEditorErrorAction = {
  type: TriggerActionType.UNSET_EDITOR_ERROR
}

export const unsetTriggerEditorError = (): UnsetTriggerEditorErrorAction => ({
  type: TriggerActionType.UNSET_EDITOR_ERROR,
})

export type GetActionSuccessAction = {
  type: TriggerActionType.GET_CACHED_ACTIONS_SUCCESS
  payload: Action
}

export type TriggerActions =
  | GetTriggerDetailAction
  | GetTriggerDetailSuccessAction
  | GetTriggerDetailErrorAction
  | SetTriggerAction
  | SetTriggersAction
  | SaveTriggerDetailAction
  | SaveTriggerDetailSuccessAction
  | GetTriggerListAction
  | GetTriggerListSuccessAction
  | GetTriggerListErrorAction
  | DeleteTriggerDetailAction
  | SetTriggerScopeAction
  | SetTriggerErrorAction
  | SetTriggerEditorErrorAction
  | UnsetTriggerEditorErrorAction
  | GetActionSuccessAction
  | UpdateTriggerAction
  | DeleteTriggerSuccessAction
  | DeleteTriggerErrorAction
