export const ORGS = '[orgs]'
export const SET_ORGS = `${ORGS} Set`
export const GET_ORGS = `${ORGS} Fetch`

export const getOrgsByName = (name: any): any => ({
  type: GET_ORGS,
  payload: name,
})

export const setOrgs = (orgs: any): any => ({
  type: SET_ORGS,
  payload: orgs,
})
