import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../Button/Button';
import './OneClick.scss';
import { useI18nSelector } from '../../v3/context/i18n';
type OneClickProps = {
  leadID?: string;
  traceID?: string;
};
const OneClickError = (props: OneClickProps) => {
  const history = useHistory();
  const i18n = useI18nSelector();
  const hasLeadID = !!props.leadID;
  return <div className="OneClick" data-sentry-component="OneClickError" data-sentry-source-file="OneClickError.tsx">
      <div className="Content">
        <div className="form">
          <div className="logo" />
          <span className="message">{i18n('leads.oca.error.message')}</span>

          {hasLeadID && <div className="buttons">
              <Button className="view-lead"
          // ?from param is for clickstream tracking
          onClick={() => history.push(`/v3/leads/${props.leadID}?from=ocaError`)} variant="contained" color="primary" disableElevation>
                {i18n('leads.oca.button.label')}
              </Button>
            </div>}
        </div>
      </div>
    </div>;
};
export default OneClickError;