import { Theme, withStyles, WithStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { Option, OptionType } from '../../types/Option';
import './Options.scss';
import { Field, FieldPrimitive } from '../../schemas';
import { genCIAttr } from '../../util';
const styles = (_theme: Theme) => ({
  option: {
    '& .option--disabled': {
      opacity: '0.5',
      'pointer-events': 'none'
    }
  },
  secondaryText: {
    fontSize: '0.75rem'
  }
});
export type OptionsProps = WithStyles<typeof styles> & {
  options?: Option[];
  multiple?: boolean;
  onSelect?: (value: Option[]) => void;
  selected: Option[];
  dataCIKey: string | undefined;
  field?: Field;
};
class Options extends Component<OptionsProps> {
  isIdentical = (option1: Option, option2: Option) => {
    return option1.label === option2.label && option1.value === option2.value;
  };
  findOptionIndex = (option: Option, array: Option[]) => {
    for (let i = 0; i < array.length; i++) {
      if (this.isIdentical(option, array[i])) {
        return i;
      }
    }
    return -1;
  };
  handleOptionClick = (option: Option) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    this.selectOption(option);
  };
  handleOptionKeyDown = (option: Option) => (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== 'Escape') {
      event.stopPropagation();
      if (event.key === 'Enter' || event.key === ' ') {
        this.selectOption(option);
      }
    }
  };
  selectOption = (option: Option) => {
    if (!this.props.onSelect || option.disabled) return;
    switch (this.props.multiple) {
      case true:
        const selected = (this.props.selected || []).slice(0);
        const selectedValues: Option[] = [];
        const selectionIndex = this.findOptionIndex(option, selected);
        if (selectionIndex === -1) {
          selected.push(option);
        } else {
          selected.splice(selectionIndex, 1);
        }
        for (const selection of selected) {
          if (selection.value) {
            selectedValues.push(selection);
          }
        }
        this.props.onSelect(selectedValues);
        break;
      default:
        // For AB#5018, the ability to reset a select with a null value was necessary for validation - SM 11/17/20
        // Sending an option with an empty value string suffices now - SM 05/22/24
        this.props.onSelect([option]);
    }
  };
  render = () => {
    if (this.props.options) {
      const {
        classes
      } = this.props;
      const optionItems = this.props.options.map((option, index) => {
        const optionKey = 'option-' + index;
        const selected = this.props.selected || [];
        let optionClass = 'option';
        if (this.props.multiple && Array.isArray(selected) && this.findOptionIndex(option, selected) > -1) {
          optionClass += ' is-selected';
        }
        if (option.disabled) {
          optionClass += ' option--disabled';
        }
        let label;
        let dataCI;
        if (option.type === OptionType.Organization) {
          label = <div>
              <div>{option.label}</div>
              <div className={classes ? classes.secondaryText : undefined}>{option.secondaryLabel}</div>
            </div>;
          dataCI = option.label;
        } else {
          label = option.label;
          dataCI = option.label;
        }
        return <div className={optionClass} data-ci={genCIAttr('options', this.props.dataCIKey, dataCI)} key={optionKey} onClick={this.handleOptionClick(option)} onKeyDown={this.handleOptionKeyDown(option)} role="button" tabIndex={0}>
            <div className="label">{label}</div>
          </div>;
      });
      return <div className={`Options ${classes.option}`} data-ci={genCIAttr('options', this.props.dataCIKey)}>
          {optionItems}
        </div>;
    }
    return null;
  };
}
export default withStyles(styles)(Options);