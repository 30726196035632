import { i18nMap } from '../reducers/i18n'

export enum i18nActionTypes {
  SET_LOCALE = '[i18n] Set Locale',
  SET_TRANSLATIONS = '[i18n] Set Translations',
}

export type SetLocaleAction = {
  type: i18nActionTypes.SET_LOCALE
  locale: string
}

export const setLocale = (locale: string): SetLocaleAction => ({
  type: i18nActionTypes.SET_LOCALE,
  locale: locale,
})

export type SetTranslationsAction = {
  type: i18nActionTypes.SET_TRANSLATIONS
  translations: i18nMap
}

export type i18nActions = SetLocaleAction | SetTranslationsAction
