import 'ace-builds/src-min-noconflict/ace';
import 'ace-builds/src-min-noconflict/mode-json';
import 'ace-builds/src-min-noconflict/theme-xcode';
import { find } from 'lodash';
import React from 'react';
import AceEditor, { IAceEditorProps, IAnnotation, IEditorProps } from 'react-ace';
const defaultStyle: React.CSSProperties = {
  color: '#222A30',
  margin: '13px 12px 13px 12px',
  width: '100%'
};
type Props = IAceEditorProps & {
  disabled?: boolean;
  onBlur?: (value?: string) => void;
  onError?: (err: string, value?: string) => void;
  readOnly?: boolean;
  requireValidJSON?: boolean;
  style?: React.CSSProperties;
};
type State = {
  error?: string;
  value: string;
};
class JSONEditor extends React.Component<Props> {
  editor!: IEditorProps;
  state: State = {
    value: ''
  };
  focus = () => {
    if (this.editor) {
      this.editor.focus();
    }
  };
  componentDidMount() {
    const {
      value
    } = this.props;
    this.setState({
      value
    });
  }
  onLoad = (editor: IEditorProps) => {
    this.editor = editor;
  };
  onChange = (value: string, _event?: any) => {
    const {
      requireValidJSON = true,
      onChange
    } = this.props;
    this.setState({
      value
    });
    if (!requireValidJSON && onChange) {
      onChange(value);
    }
  };
  onValidate = (annotations: IAnnotation[]) => {
    const {
      onChange,
      onError
    } = this.props;
    const {
      value
    } = this.state;
    if (annotations.length) {
      const firstErr = find(annotations, annotation => 'error' === annotation.type);
      if (firstErr) {
        const error = `Invalid JSON on line ${firstErr.row}:  ${firstErr.text}`;
        this.setState({
          ...this.state,
          error
        });
        if (onError) {
          onError(error);
        }
        return;
      }
    }
    this.setState({
      ...this.state,
      error: undefined
    });
    if (onChange) {
      onChange(value);
    }
  };
  onBlur = () => {
    const {
      onBlur
    } = this.props;
    const {
      value
    } = this.state;
    if (onBlur) {
      onBlur(value);
    }
  };
  render() {
    const {
      disabled,
      fontSize = 13,
      name,
      placeholder,
      readOnly,
      requireValidJSON,
      style,
      onBlur,
      onFocus
    } = this.props;
    const {
      value
    } = this.state;
    const styles = {
      ...defaultStyle,
      ...style
    };
    return <AceEditor style={styles} placeholder={placeholder} mode="json" fontSize={fontSize} theme="xcode" name={name} value={value} onLoad={this.onLoad} onBlur={onBlur} onFocus={onFocus} onChange={this.onChange} showPrintMargin={false} showGutter={true} maxLines={Infinity} highlightActiveLine={false} onValidate={this.onValidate} setOptions={{
      useWorker: requireValidJSON,
      readOnly: readOnly || disabled,
      showLineNumbers: true,
      tabSize: 2,
      highlightSelectedWord: true
      // ace cursor position is incorrect when
      // not using a monospace font
      //fontFamily: "'Open Sans', 'PowerChord', sans-serif",
    }} data-sentry-element="AceEditor" data-sentry-component="JSONEditor" data-sentry-source-file="JSONEditor.tsx" />;
  }
}
export default JSONEditor;