import _ from 'lodash'
import { FORGOT_PASSWORD, LOGIN_PAGE, PAGE, RESET_PASSWORD } from '../actions/location'
import { AppState } from '../store'

const publicPages = [FORGOT_PASSWORD, LOGIN_PAGE, RESET_PASSWORD]

export const pageSelector = (state: AppState): any => {
  return state && state.page
}

export const isPublicPage = (page: string): boolean => {
  return _.includes(publicPages, page)
}

export const isPageAction = (actionType: string): boolean => {
  return actionType.indexOf(PAGE) === 0
}
