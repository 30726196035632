import { APIFilter, FilterField, FilterFieldKeys, isDateOption, StoredFilters, StoredFiltersForOrg, StoredFiltersForResource } from '../types'
import { Field, Schema } from '../schemas'
import { getLocalStorage, setLocalStorage } from './localStorage'
import config from '../config'

// getStoredFilters handles getting the stored filters.
// If a filter version isn't present or doesn't match - it will return blank filters for the app to populate.
// For the purposes of this versioning, all calls to get filters from localstorage should use this function.
export const getStoredFilters = (): StoredFilters => {
  const filterVersion: string | null = getLocalStorage(config.filterVersionKey)
  if (!filterVersion || filterVersion !== config.filterVersionCurrent) {
    setLocalStorage(config.filterVersionKey, config.filterVersionCurrent)
    return {}
  }

  const filtersFromLocalStorage: StoredFilters | null = getLocalStorage(config.filtersKey)
  if (!filtersFromLocalStorage) return {}
  return filtersFromLocalStorage
}

export const getStoredResourceFiltersForOrg = (orgID: string, resource: string): StoredFiltersForResource => {
  const filtersFromLocalStorage = getStoredFilters()
  const orgFilters: StoredFiltersForOrg | undefined = filtersFromLocalStorage[orgID]
  if (!orgFilters) return {}
  const resourceFilters: StoredFiltersForResource | undefined = orgFilters[resource]
  if (!resourceFilters) return {}
  return resourceFilters
}

export const setStoredResourceFiltersForOrg = (orgID: string, resource: string, filters: FilterField[]) => {
  const filtersFromLocalStorage: StoredFilters = getLocalStorage(config.filtersKey) ?? {}
  const orgFilters: StoredFiltersForOrg = filtersFromLocalStorage[orgID] ?? {}
  const localStorageFilters: StoredFiltersForResource = {}
  filters.forEach((filter) => {
    // Don't both storing disabled options
    localStorageFilters[filter.key] = filter.selected.filter((opt) => opt.disabled === undefined || !opt.disabled)
  })
  orgFilters[resource] = localStorageFilters
  filtersFromLocalStorage[orgID] = orgFilters
  setLocalStorage(config.filtersKey, filtersFromLocalStorage)
}

// resetDateFilters remove any existing DateOptions that are stored in local storage.
// This should be called on new sessions - we always want users to start a session with the default date options.
export const resetDateFilters = () => {
  const filtersFromLocalStorage = getStoredFilters()
  if (!filtersFromLocalStorage) return

  for (const [_, orgFilters] of Object.entries(filtersFromLocalStorage)) {
    for (const [_, resourceFilters] of Object.entries(orgFilters)) {
      for (const [fieldKey, options] of Object.entries(resourceFilters)) {
        const isDate = options.some((opt) => isDateOption(opt))
        if (isDate) {
          delete resourceFilters[fieldKey]
        }
      }
    }
  }
  setLocalStorage(config.filtersKey, filtersFromLocalStorage)
}

export const getDefaultFilterDateRange = (): [number, number] => {
  const today = new Date()
  today.setHours(23, 59, 59, 0)

  const oneMonthAgo = new Date()
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
  oneMonthAgo.setHours(0, 0, 0, 0)

  return [today.getTime() / 1000, oneMonthAgo.getTime() / 1000]
}

export const getV3LeadAPIFiltersFromMockSchema = (schema: Schema): APIFilter[] => {
  return schema.fields.reduce((acc: APIFilter[], curr: Field): APIFilter[] => {
    acc.push({
      field: curr,
      fieldPath: [curr.key as FilterFieldKeys],
    })
    return acc
  }, [])
}
