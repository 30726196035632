export const PASSWORD = '[password]'
export const SAVE_PASSWORD = `${PASSWORD} Save`

export const savePassword = (original: string, password: string, passwordConfirm: string): any => ({
  type: SAVE_PASSWORD,
  payload: {
    original,
    password,
    passwordConfirm,
  },
})
