import { find, isEmpty, isNil, isObject, reduce } from 'lodash'
import { DocumentState } from 'reducers/document'
import { createSelector } from 'reselect'
import { EditorMode } from '../actions'
import { Resource } from '../resource'
import { Field, Schema } from '../schemas'
import { primaryLocaleSelector } from './settings'
import { AppState } from '../store'
import { fieldSchemaSelector, pendingApprovalSelector, schemaSelector } from './schema'
import { i18nSelectorMap } from '../reducers/i18n'

export const wipDocumentSelector = (state: AppState): Resource => (state?.document?.wip ?? {}) as Resource

export const originalDocumentSelector = (state: AppState): Resource => (state?.document?.original ?? {}) as Resource

export const unsavedChangesSelector = (state: { document?: DocumentState }): boolean => {
  const original = state?.document?.original ?? {}
  const wip = state?.document?.wip ?? {}
  return JSON.stringify(original) !== JSON.stringify(wip)
}

export const documentErrorSelector = (state: AppState): any => state?.document?.errors ?? undefined

export const canBeDuplicatedSelector = (state: AppState, schema?: Schema): boolean => {
  if (!schema) {
    schema = schemaSelector(state)
  }
  if (!schema.isDuplicable) {
    return false
  }
  if (!schema.userCanDuplicate) {
    return false
  }

  // This was formerly a selector, but it was imported cyclically and only used here so we'll just access state directly instead.
  const allowDuplication = state.settings?.resourceSettings?.allowDuplication ?? false
  if (!allowDuplication) {
    return false
  }
  const document = originalDocumentSelector(state)
  const primaryLocale = primaryLocaleSelector(state)
  if (primaryLocale !== document.locale) {
    return false
  }
  if (!document.ID) {
    return false
  }
  return true
}

/**
 * Get the name of a resource type, or an error title if there was an error while loading a resource
 */
export const documentPageTitleSelector = (state: AppState, i18n: i18nSelectorMap): string | undefined => {
  if (state?.schema?.label) {
    return state.schema.label
  }

  const error = documentErrorSelector(state)
  if (!error) {
    return undefined
  }

  switch (error._code) {
    case 'ErrNotFound':
      return i18n('editor.messages.loadErrorTitleNotFound')

    default:
      return i18n('editor.messages.loadErrorTitle')
  }
}

export const fieldSelector = (fieldKey: string) =>
  createSelector(
    (state: AppState) => state?.document?.wip?.[fieldKey] ?? null,
    fieldSchemaSelector,
    (fieldValue: any, fieldSchema: any) => {
      return {
        value: fieldValue,
        schema: fieldSchema,
      }
    }
  )

export const documentWipIDSelector = (state: AppState) => state?.document?.wip?.ID ?? ''

export const fieldValueSelector = (state: AppState, fieldKey: string) => state?.document?.wip?.[fieldKey] ?? null

export const fieldErrorSelector = (state: AppState, fieldKey: string) => {
  const errs = state?.document?.errors
  if (!errs) {
    return undefined
  }

  // display err for fieldKey
  const fieldErr = errs?.[fieldKey]
  if (fieldErr) {
    return fieldErr
  }

  const fieldSchema = find(state.schema.fields, (field: Field) => field.key === fieldKey)

  if (fieldSchema) {
    // display errs for "hidden" fields which
    // are set by an autoComplete setInstruction
    const setInstructions = fieldSchema?.autoComplete?.set
    if (setInstructions) {
      const setInstructionErr = find(errs, (_err, key) => find(setInstructions, (setInstruction) => setInstruction.to === key))

      if (setInstructionErr) {
        return setInstructionErr
      }
    }
  }

  return undefined
}

export const documentActionSelector = (state: AppState): string[] => {
  const schema = schemaSelector(state)
  const pendingApproval = pendingApprovalSelector(state)
  const document = wipDocumentSelector(state)
  const canBeDuplicated = canBeDuplicatedSelector(state, schema)
  const actions: string[] = []

  if (canBeDuplicated) {
    actions.push(EditorMode.DUPLICATE)
  }

  if (schema.isImmutable) {
    actions.push('cancel')
  } else if (schema.shouldModerate) {
    actions.push('cancel', 'draft', 'publish')
  } else if (pendingApproval) {
    actions.push('cancel', 'deny', 'approve')
  } else if (document.ID === '') {
    actions.push('cancel', 'create')
  } else if (document.ID && document.ID.length) {
    actions.push('cancel', 'save')
  }

  return actions
}

export const documentErrorCountSelector = (state: AppState): number => {
  // We need to account for undefined key values. This happens when document
  // errors are cleared

  const errs = omitNullish(documentErrorSelector(state))
  return getErrCount(errs)
}

// getErrCount counts all fields recursively
function getErrCount(errs: any): number {
  if (!errs) {
    return 0
  }

  return reduce(
    errs,
    (sum, value) => {
      if (isObject(value)) {
        return sum + getErrCount(value)
      }
      return sum + 1
    },
    0
  )
}

// omitNullish recursively omits nullish fields
// because validation assigns successfully validated fields
// on the errors object with an undefined value
// and can include nested fields
function omitNullish(value: any): any | undefined {
  if (isNil(value) || isEmpty(value)) {
    return undefined
  }

  if (isObject(value)) {
    const omitted = reduce(
      value,
      (sum: any, value: any, key: string) => {
        if (isObject(value)) {
          const omitted = omitNullish(value)
          if (!!omitted) {
            sum[key] = omitted
          }
        } else if (!isNil(value)) {
          sum[key] = value
        }
        return sum
      },
      {}
    )

    if (isEmpty(omitted)) {
      return undefined
    }
    return omitted
  }
  return value
}
