import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import './index.scss';
import AppRoot from 'components/AppRoot/AppRoot';
import * as Sentry from '@sentry/react';
import config from './config';
import * as process from 'process';

// Mui's ThemeProvider is unnecessarily cranky about Sentry annotating it and throws an error in the console.
// This error causes false positives in tests, at a min, and could trigger other false positives if we ever monitor
// console errors.
// This workaround suppresses that error log while passing others through to the original console.
const consoleErr = console.error;
console.error = (...data: any[]) => {
  if (!data.some(en => en === 'The following props are not supported: `data-sentry-element`, `data-sentry-component`, `data-sentry-source-file`. Please remove them.')) {
    consoleErr(...data);
  }
};
Sentry.init({
  dsn: 'https://1e4f10f3d9174107a477683dbe1b0740@sentry.io/1523390',
  debug: config.debugSentry,
  environment: config.environment,
  // See SENTRY_RELEASE file
  release: process.env.SENTRY_RELEASE,
  denyUrls: ['localhost'],
  beforeSend(event) {
    // denyURLs can fail in weird ways, this is a more generic test
    if (event.request?.url?.includes('localhost')) {
      console.debug('Sentry event blocked from sending:', event);
      return null;
    }
    return event;
  }
});
if (window.top.location !== window.self.location) {
  console.warn('this app is not intended to be used within an iframe');
} else if (process.env.REACT_APP_RUN_IN_STRICT_MODE === 'true') {
  console.warn('app is running in strict mode');
  ReactDOM.render(<React.StrictMode>
      <AppRoot />
    </React.StrictMode>, document.getElementById('root'));
} else {
  ReactDOM.render(<AppRoot />, document.getElementById('root'));
}