import { DateOptionRangeType, FilterField, isDateOption, isEnumOption, Option } from 'types'
import { AppState } from '../store'
import { getDefaultFilterDateRange } from '../util'
import { LEADS_LIST, LIST_PAGE } from '../actions'
import { categorySelector, selectLocationType, selectPrevCategory, selectPrevLocationType } from './location'

export const selectFilters = (state: AppState): FilterField[] => {
  return state.filters.filters
}

export const selectAPIFilters = (state: AppState) => {
  return state.filters.apiFilters
}

export const focusedFilterSelector = (state: AppState) => {
  return state.filters.focusedFilter
}

export const shouldUpdateFilters = (state: AppState): boolean => {
  // Do not retrieve filters unless on a list page that supports filters
  const locationType = selectLocationType(state)
  if (locationType !== LEADS_LIST && locationType !== LIST_PAGE) {
    return false
  }

  // Should update if we don't have filters yet (Generally - first load)
  if (selectFilters(state).length === 0) {
    return true
  }

  const currentCategory = categorySelector(state)

  // Don't update if current category matches the category stored in state upon list page load
  if (currentCategory === state.route.category) {
    return false
  }

  // Should update if current category does not match the previous category in the location reducer
  if (currentCategory !== selectPrevCategory(state)) {
    return true
  }

  // Should update if the location type does not match the previous one
  if (locationType !== selectPrevLocationType(state)) {
    return true
  }

  return false
}

export const readyForV3Filters = (state: AppState): boolean => {
  return state.page === 'LeadList' && state.v3Leads.loaded.leadTypes && state.v3Leads.loaded.contentConfigs
}

export const selectFilterOptionsByKey = (state: AppState, key: string, useDefaults: boolean): Option[] => {
  const filters = selectFilters(state)
  for (const filter of filters) {
    if (filter.key === key) {
      if (useDefaults) {
        return filter.defaultSelected
      }
      return filter.selected
    }
  }
  return []
}

export const selectFilterOptionStringsByKey = (state: AppState, key: string, useDefaults: boolean): string[] => {
  return selectFilterOptionsByKey(state, key, useDefaults).reduce((acc: string[], curr: Option) => {
    if (isEnumOption(curr)) {
      acc.push(curr.value)
    }
    return acc
  }, [])
}

export const selectFilterOptionBoolsByKey = (state: AppState, key: string, useDefaults: boolean): boolean[] => {
  return selectFilterOptionsByKey(state, key, useDefaults).map((opt) => opt.value === 'true')
}

export const selectDateRangeFilterOptionValuesByKey = (state: AppState, key: string): [number, number] => {
  let [lessThan, greaterThan] = getDefaultFilterDateRange()
  const created = selectFilterOptionsByKey(state, key, false)
  created.forEach((cr) => {
    if (isDateOption(cr)) {
      if (cr.range === DateOptionRangeType.From) {
        greaterThan = cr.value
      }
      if (cr.range === DateOptionRangeType.To) {
        lessThan = cr.value
      }
    }
  })

  return [lessThan, greaterThan]
}

// The *Clean selectors checks the selected filter options against their defaults, and returns
// undefined if the two options match.
// This is to support V3 leads queries. Use with caution elsewhere.
// PD-601: migrated from `selectedStagesSelector` and `selectedStatusesSelector`
// Original comment:
//    Everything is selected by default, but that won't include results that aren't in the enum list.
//    So, if everything is selected, we should pass empty array to the backend to actually return everything

export const selectSelectedFilterOptionStringsByKeyClean = (state: AppState, key: string): string[] | undefined => {
  const selectedOptions = selectFilterOptionStringsByKey(state, key, false)
  const defaultOptions = selectFilterOptionStringsByKey(state, key, true)

  if (selectedOptions.length !== defaultOptions.length) {
    return selectedOptions
  }

  for (const opt of selectedOptions) {
    if (!defaultOptions.some((def) => def === opt)) {
      return selectedOptions
    }
  }

  return
}

export const selectSelectedFilterOptionBoolsByKeyClean = (state: AppState, key: string): boolean[] | undefined => {
  const selectedOptions = selectFilterOptionBoolsByKey(state, key, false)
  const defaultOptions = selectFilterOptionBoolsByKey(state, key, true)

  if (selectedOptions.length !== defaultOptions.length) {
    return selectedOptions
  }

  for (const opt of selectedOptions) {
    if (!defaultOptions.some((def) => def === opt)) {
      return selectedOptions
    }
  }

  return
}
