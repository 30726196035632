import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import TextField from '../../components/TextField/TextField';
import config from '../../config';
import Button from '../Button/Button';
import './Login.scss';
import { useI18nSelector } from '../../v3/context/i18n';
import { removeLocalStorage } from '../../util';
export interface ResetPasswordProps {
  token: string;
}
const ResetPassword = (props: ResetPasswordProps) => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const i18n = useI18nSelector();
  const handleKeyDown = (event: React.KeyboardEvent<Element>) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };
  const handleMoreHelp = () => {
    window.open('https://powerchord.atlassian.net/servicedesk/customer/kb/view/723222633', '_blank');
  };
  const handleSubmit = (event: React.SyntheticEvent) => {
    if (event) {
      event.preventDefault();
    }
    axios({
      url: `${config.api}/credentials/reset`,
      method: 'POST',
      data: {
        password,
        passwordConfirm,
        token: props.token
      }
    }).then((_res: AxiosResponse) => {
      setSent(true);
      removeLocalStorage(config.sessionKey); // prevent routing issues when resetting password while an active session exists
    }).catch((err: AxiosError<{
      _message: string;
    }>) => {
      setError(true);
      setErrorMessage(err?.response?.data?._message ?? err.message);
    });
  };
  const labels = {
    resetIntro: i18n('auth.reset.introduction'),
    passwordConfirm: i18n('auth.passwordConfirmation'),
    password: i18n('auth.passwordLabel'),
    resetSuccessful: i18n('auth.reset.successful'),
    ctaLoginHere: i18n('auth.cta.reset.login'),
    moreHelp: i18n('auth.moreHelp'),
    submit: i18n('auth.cta.resetPassword')
  };
  return <div className="LoginScreen" data-sentry-component="ResetPassword" data-sentry-source-file="ResetPassword.tsx">
      <div className="Login">
        {sent ? <div className="form">
            <div className="logo" />
            {labels.resetSuccessful ? <p className="message">{labels.resetSuccessful}</p> : null}
            <div className="button">
              <Button className="submit" variant="contained" color="primary" id="submit" onClick={() =>
          // Using react-router's push() causes a state problem here, so just reload the whole page
          window.location.href = '/login'}>
                {labels.ctaLoginHere}
              </Button>
            </div>
          </div> : <div className="form">
            <div className="logo" />
            {error ? <p className="message error">{errorMessage}</p> : labels.resetIntro ? <p className="message">{labels.resetIntro}</p> : null}
            <TextField label={labels.password} onInput={v => setPassword(v)} onKeyDown={handleKeyDown} type="password" />
            <TextField label={labels.passwordConfirm} onInput={v => setPasswordConfirm(v)} onKeyDown={handleKeyDown} type="password" />
            <div className="buttons">
              <Button className="moreHelp" onClick={handleMoreHelp}>
                {labels.moreHelp}
              </Button>
              <Button className="submit" variant="contained" color="primary" id="submit" onClick={handleSubmit}>
                {labels.submit}
              </Button>
            </div>
          </div>}
        <div className="greeting" />
      </div>
    </div>;
};
export default ResetPassword;