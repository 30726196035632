import { Environment } from '../config'

type FlagConfig = {
  users?: Array<string>
  environments?: Array<Environment>
  domains?: Array<string>
}

type Config = boolean | FlagConfig

export type FlagConfigs = Record<string, Config>

export type CalculateFlagsState = {
  environment: Environment
  user?: {
    email: string
  }
}

export const calculateFlags = (state: CalculateFlagsState, config: FlagConfigs): Record<string, boolean> => {
  const { user, environment } = state
  const ret: Record<string, boolean> = {}

  for (const [key, value] of Object.entries(config)) {
    if (typeof value === 'boolean') {
      ret[key] = value
      continue
    }

    let matches = 0
    ret[key] = false
    if (value.environments) {
      if (value.environments.indexOf(environment) > -1) {
        matches++
      }
    }

    if (user) {
      if (value.users && value.users.indexOf(user.email) > -1) {
        matches++
      }

      if (value.domains) {
        const matchingDomains = value.domains.filter((domain) => {
          return user.email.endsWith(domain)
        })
        if (matchingDomains.length > 0) {
          matches++
        }
      }
    }

    const doesMatch = matches === Object.keys(value).length
    ret[key] = doesMatch
  }

  return ret
}
