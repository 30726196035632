export const C_SCHEMAS = '[createable schemas]'
export const GET_C_SCHEMAS = '[createable schemas] Fetch'
export const SET_C_SCHEMAS = '[createable schemas] Set'

interface SetCreateableSchemasAction {
  type: typeof SET_C_SCHEMAS
  payload: any
}

export const setCreateableSchemas = (cSchemas: any): SetCreateableSchemasAction => ({
  type: SET_C_SCHEMAS,
  payload: cSchemas,
})

interface GetCreatableSchemasAction {
  type: typeof GET_C_SCHEMAS
  payload: string
}

export const getCreateableSchemas = (resource: string): GetCreatableSchemasAction => ({
  type: GET_C_SCHEMAS,
  payload: resource,
})
