import { AppState } from '../store'
import { Component, Page, Section, Site } from '../types'
import { ArchitectHTML } from '../types/Architect'

export const pagesSelector = (state: AppState): Page[] => {
  return state?.architect?.pages || []
}

export const architectPageSelector = (state: AppState): Page => {
  const pages = state?.architect?.pages || []
  const defaultPage = pages[0]?.ID ?? ''
  const pageID = state?.architect?.page ?? defaultPage
  if (pageID) {
    const page = pages.find((p: any) => p.ID === pageID)
    return page || ({} as Page)
  }
  return {} as Page
}

export const htmlSelector = (state: AppState): ArchitectHTML => {
  return state?.architect?.html ?? ''
}

export const sectionsSelector = (state: AppState): Section[] => {
  const page = architectPageSelector(state)
  return page?.sections || []
}

export const siteSelector = (state: AppState): Site => {
  return state?.architect?.site ?? {}
}

// https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
const b64DecodeUnicode = (str: string): string => {
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}

export const formSelector = (state: AppState): string => {
  return b64DecodeUnicode(state?.architect?.form ?? '')
}

export const mobileViewportSelector = (state: AppState): boolean => {
  return state?.architect?.mobileViewport ?? false
}

export const componentsSelector = (state: AppState): Component[] => {
  return state?.architect?.components ?? []
}

export const componentNameSelector = (state: AppState) => (HID: string): string => {
  return state?.architect?.componentMap.get(HID)?.name ?? ''
}
