import React, { useEffect, useState } from 'react';
import { toast, ToastProps } from 'react-toastify';
import * as serviceWorker from '../../serviceWorker';
import Button from '../Button/Button';
import { useI18nSelector } from '../../v3/context/i18n';
export const RefreshToast = () => {
  const i18n = useI18nSelector();
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker>();
  useEffect(() => {
    // on initial load in production, register our service worker
    if (process.env.NODE_ENV === 'production') {
      /**
       * ESCAPE HATCH: if issues arise with service worker, replace next line with `serviceWorker.unregister()` to
       * not load/register the service worker
       */
      serviceWorker.register({
        onUpdate: onServiceWorkerUpdate
      });
    }
  }, []);
  useEffect(() => {
    if (updateAvailable) {
      // show notification
      toast(i18n('global.status.updateAvailable'), {
        toastId: 'refresh-toast',
        closeOnClick: false,
        autoClose: false,
        position: 'top-center',
        closeButton: toastButton
      });
    }
  }, [updateAvailable]);
  function toastButton({
    closeToast
  }: ToastProps) {
    return <Button variant="contained" color="primary" onClick={() => {
      closeToast();
      updateServiceWorker();
    }} data-sentry-element="Button" data-sentry-component="toastButton" data-sentry-source-file="RefreshToast.tsx">
        Refresh
      </Button>;
  }
  function onServiceWorkerUpdate({
    waiting
  }: ServiceWorkerRegistration) {
    if (waiting) {
      setWaitingWorker(waiting);
      setUpdateAvailable(true);
    }
  }
  function updateServiceWorker() {
    if (!waitingWorker) {
      console.info('NO WAITING WORKER');
      return;
    }

    // the message defined here should match expectations in the service worker message listener
    waitingWorker.postMessage({
      type: 'SKIP_WAITING'
    });
    setUpdateAvailable(false);
    window.location.reload();
  }
  return null;
};