import { ContentConfig } from '../types/ContentConfig'
import { i18nSelectorMap } from '../reducers/i18n'
import { Enum } from '../types'

export const deduplicateContentConfigs = (a: ContentConfig[], b: ContentConfig[]): ContentConfig[] => {
  return [...a, ...b].reduce((acc: ContentConfig[], curr: ContentConfig) => {
    if (acc.findIndex((act) => act.field === curr.field) < 0) {
      acc.push(curr)
    }
    return acc
  }, [])
}

// getLeadStatusEnums provides a set of Lead 'Status' enums
// if the organization has 'Status' enums set in their content configuration, their enums will be returned
// otherwise, the predefined default is returned
// The parameter `trimDefaults` controls whether the full list of defaults is returned (for filter dropdowns, etc.)
// or only a subset (for actual Lead status fields, etc.).
export const getLeadStatusEnums = (i18n: i18nSelectorMap, contentConfigs: ContentConfig[], trimDefaults: boolean): Enum[] => {
  const configuredEnums = contentConfigs.find((c) => c.field === 'status')
  if (configuredEnums && configuredEnums.enums) return configuredEnums.enums

  const defaults = [
    {
      label: i18n('leads.status.enum.open.label'),
      value: 'open',
      disabled: false,
    },
    {
      label: i18n('leads.status.enum.contacted.label'),
      value: 'contacted',
      disabled: false,
    },
    {
      label: i18n('leads.status.enum.inProgress.label'),
      value: 'inProgress',
      disabled: false,
    },
    {
      label: i18n('leads.status.enum.onHold.label'),
      value: 'onHold',
      disabled: false,
    },
    {
      label: i18n('leads.status.enum.closedWon.label'),
      value: 'closedWon',
      disabled: false,
    },
    {
      label: i18n('leads.status.enum.closedNotInterested.label'),
      value: 'closedNotInterested',
      disabled: false,
    },
    {
      label: i18n('leads.status.enum.closedBoughtUsed.label'),
      value: 'closedBoughtUsed',
      disabled: false,
    },
    {
      label: i18n('leads.status.enum.closedBoughtOtherBrand.label'),
      value: 'closedBoughtOtherBrand',
      disabled: false,
    },
  ]

  if (trimDefaults) return defaults

  return defaults.concat([
    {
      // Triggered by email/SMS opt out - zero contact requested
      label: i18n('leads.status.enum.closedOptOut.label'),
      value: 'closedOptOut',
      disabled: false,
    },
    {
      // Lead Record has been opened in Command Center Desktop or Mobile
      label: i18n('leads.status.enum.engaged.label'),
      value: 'engaged',
      disabled: false,
    },
    {
      // Follow-Up functionality (TBD)
      label: i18n('leads.status.enum.followUp.label'),
      value: 'followUp',
      disabled: false,
    },
    {
      // Triggered by rules - Lead not lost, not won - no status change
      label: i18n('leads.status.enum.nurture.label'),
      value: 'nurture',
      disabled: false,
    },
    {
      label: i18n('leads.status.enum.active.label'),
      value: 'active',
      disabled: false,
    },
    {
      label: i18n('leads.status.enum.closed.label'),
      value: 'closed',
      disabled: false,
    },
    {
      label: i18n('leads.status.enum.demoed.label'),
      value: 'demoed',
      disabled: false,
    },
  ])
}

// Currently, we don't *actually* support custom stage enums, so this is just a
// wrapper for the default.
export const getLeadStageEnums = (i18n: i18nSelectorMap): Enum[] => {
  return getLocalizedDefaultLeadStageOptions(i18n)
}

const getLocalizedDefaultLeadStageOptions = (i18n: i18nSelectorMap): Enum[] => {
  return [
    {
      label: i18n('leads.stage.enum.lead.label'),
      value: 'lead',
      disabled: false,
    },
    {
      label: i18n('leads.stage.enum.opportunity.label'),
      value: 'opportunity',
      disabled: false,
    },
    {
      label: i18n('leads.stage.enum.converted.label'),
      value: 'converted',
      disabled: false,
    },
    {
      label: i18n('leads.stage.enum.other.label'),
      value: 'other',
      disabled: false,
    },
  ]
}
