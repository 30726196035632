import { API_SUCCESS, apiRequest, C_SCHEMAS, GET_C_SCHEMAS, setCreateableSchemas } from '../actions'
import { AppState, Middleware } from '../store'

export const createableSchemasMiddleware: Middleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  switch (action.type) {
    case GET_C_SCHEMAS:
      const state: AppState = getState()
      const org = state.session.organization
      dispatch(
        apiRequest({
          url: `/organizations/${org.trunkID}/${org.ID}/${action.payload}/add`,
          method: 'GET',
          feature: C_SCHEMAS,
        })
      )
      break

    case `${C_SCHEMAS} ${API_SUCCESS}`:
      dispatch(setCreateableSchemas(action.payload.resources))
      break
  }
}
