import { always, any, complement, compose, filter, flatten, is, length, lt, map, not, path, pathOr, prop, reduce, sort, when } from 'ramda'

export const LIST_PAGE = 'listPage'
export const NESTED_LIST = 'nestedList'

const asArray = when(compose(not, is(Array)), always([]))
const arrayHasLength = compose(lt(0), length, asArray)
const isEmptyArray = complement(arrayHasLength)
const hasNestedFields = compose(arrayHasLength, (v: any) => prop('fields')(v))
const hasEmptyFields = compose(isEmptyArray, (v: any) => prop('fields')(v))

const getListConfig = (field: any) => pathOr({}, ['listConfig'], field)
const getSortOrder = (field: any) => path(['listConfig', 'sortOrder'], field)
const sortBySortOrder = (a: any, b: any) => (a.sortOrder && a.sortOrder ? a.sortOrder - b.sortOrder : 0)

const filterByListType = (listType: any) =>
  function filterField(field: any): any {
    if (!field) return false
    // if there is a `fields` property on the field, follow it.
    if (hasNestedFields(field)) return any(filterField, field.fields)
    const listConfig: any = getListConfig(field)
    return !!(listConfig && listConfig.type === listType)
  }

const showOnList = (v: any) => filterByListType(LIST_PAGE)(v)
const showOnNested = filterByListType(NESTED_LIST)

const buildEnums = (field: Object) => {
  const enums = pathOr([], ['restrictions', 'enums'], field) || []
  if (!enums.length) {
    return
  }

  return reduce(
    (acc: any, curr: any) => {
      acc[curr.value] = curr.label
      return acc
    },
    {},
    enums
  )
}

const getListFields = (predicateFn: (a: any) => boolean, ignoreNested: boolean) =>
  compose(
    sort(sortBySortOrder),
    flatten,
    reduce((acc: any, curr: any) => {
      if (Array.isArray(curr)) return acc.concat(curr)
      return acc.concat([curr])
    }, []),
    filter(Boolean),
    map((field: any) => {
      if (hasEmptyFields(field))
        return {
          key: field.key,
          label: field.label,
          enums: buildEnums(field),
          presentation: field.presentation,
          sortOrder: getSortOrder(field),
          field,
        }

      if (ignoreNested) return null

      const nested = field.fields.filter(predicateFn)
      if (!nested.length) return null
      return nested.map((f: any) => {
        if (hasEmptyFields(f))
          return {
            key: `${field.key}.${f.key}`,
            label: f.label,
            sortOrder: getSortOrder(f),
            field: f,
          }

        const doubleNested = f.fields.filter(predicateFn)
        if (!doubleNested.length) return null
        return doubleNested.map((dnf: any) => ({
          key: `${field.key}.${f.key}.${dnf.key}`,
          label: dnf.label,
          sortOrder: getSortOrder(dnf),
          field: dnf,
        }))
      })
    }),
    (v: any) => compose(filter(predicateFn), pathOr([], ['fields']))(v)
  )

export const getListableFields = getListFields(showOnList, false)
export const getListableNestedFields = getListFields(showOnNested, true)
