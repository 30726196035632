import { ThemeProvider } from '@material-ui/core';
import { LoadingStateless } from 'components/Loading/Loading';
import OneClickError from 'components/OneClickAction/OneClickError';
import OneClickSuccess from 'components/OneClickAction/OneClickSuccess';
import { parse } from 'query-string';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Settings } from 'types';
import config from '../../config';
import { SessionState } from '../../reducers/session';
import theme from '../../theme';
import { getLocalStorage, prefersDarkMode, trackEvent, trackPageView } from '../../util';
import ForgotPassword from '../Login/ForgotPassword';
import ResetPassword from '../Login/ResetPassword';
import { RootLogin } from '../Login/RootLogin';
import { I18nProvider } from '../../v3/context/i18n';
import { fetchPlatformSettings } from '../../request/fetchPlatformSettings';
const MobileRoot = lazy(() => import('../Mobile/App'));
const V1Root = lazy(() => import('../AppV1/Wrapper'));

/**
 * The AppRoot is the entry-point for Command Center
 * It handles top-level routing, session fetching, and redirection
 */
export default function AppRoot() {
  let session: SessionState | null = getLocalStorage(config.sessionKey);
  if (!session) {
    session = ({} as SessionState);
  }
  let locale: string | null = getLocalStorage(config.languageKey);
  if (!locale) {
    locale = 'en-US';
  }

  // grab the user's preferred theme from the session in localstorage
  let userTheme = session?.user?.theme;
  if (userTheme === undefined) {
    // if user's theme is not set, check if there is a browser preference for dark mode
    userTheme = prefersDarkMode() ? 'dark' : 'light';
  }
  return <ThemeProvider theme={theme(userTheme)} data-sentry-element="ThemeProvider" data-sentry-component="AppRoot" data-sentry-source-file="AppRoot.tsx">
      <I18nProvider locale={locale} data-sentry-element="I18nProvider" data-sentry-source-file="AppRoot.tsx">
        {/* forceRefresh=true will force the window to refresh on navigation, which allows the app to reload if user
          is authenticated while navigating to /login (forces app refresh to load app and other routers) */}
        <BrowserRouter forceRefresh={true} data-sentry-element="BrowserRouter" data-sentry-source-file="AppRoot.tsx">
          <Switch data-sentry-element="Switch" data-sentry-source-file="AppRoot.tsx">
            <Route path="/forgot-password" data-sentry-element="Route" data-sentry-source-file="AppRoot.tsx">
              <ForgotPassword data-sentry-element="ForgotPassword" data-sentry-source-file="AppRoot.tsx" />
              <PageChangeTracker data-sentry-element="PageChangeTracker" data-sentry-source-file="AppRoot.tsx" />
            </Route>

            <Route path="/my-account/reset-password" data-sentry-element="Route" data-sentry-source-file="AppRoot.tsx">
              <ResetPasswordRoute data-sentry-element="ResetPasswordRoute" data-sentry-source-file="AppRoot.tsx" />
              <PageChangeTracker data-sentry-element="PageChangeTracker" data-sentry-source-file="AppRoot.tsx" />
            </Route>

            <Route path="/login" data-sentry-element="Route" data-sentry-source-file="AppRoot.tsx">
              <LoginRoute session={session} data-sentry-element="LoginRoute" data-sentry-source-file="AppRoot.tsx" />
              <PageChangeTracker data-sentry-element="PageChangeTracker" data-sentry-source-file="AppRoot.tsx" />
            </Route>

            <Route exact path="/oca-success/lead/:id" data-sentry-element="Route" data-sentry-source-file="AppRoot.tsx">
              <OneClickActionSuccessRoute data-sentry-element="OneClickActionSuccessRoute" data-sentry-source-file="AppRoot.tsx" />
              <PageChangeTracker data-sentry-element="PageChangeTracker" data-sentry-source-file="AppRoot.tsx" />
            </Route>

            <Route exact path="/oca-error" data-sentry-element="Route" data-sentry-source-file="AppRoot.tsx">
              <OneClickActionErrorRoute data-sentry-element="OneClickActionErrorRoute" data-sentry-source-file="AppRoot.tsx" />
              <PageChangeTracker data-sentry-element="PageChangeTracker" data-sentry-source-file="AppRoot.tsx" />
            </Route>

            <Route data-sentry-element="Route" data-sentry-source-file="AppRoot.tsx">
              <DefaultAppRoute session={session} locale={locale} data-sentry-element="DefaultAppRoute" data-sentry-source-file="AppRoot.tsx" />
            </Route>
          </Switch>
        </BrowserRouter>
      </I18nProvider>
      <ToastContainer data-sentry-element="ToastContainer" data-sentry-source-file="AppRoot.tsx" />
    </ThemeProvider>;
}

// Exists so that page loads for routes defined by react-router can trigger pageview events in PC Tracking
// Legacy redux-first-router routed page views are tracked automatically in the App component
function PageChangeTracker() {
  const location = useLocation();
  useEffect(() => {
    trackPageView();
  }, [location]);
  return null;
}
function ResetPasswordRoute() {
  const params: {
    token?: string;
  } = parse(window.location.search);
  if (!params.token) {
    throw new Error('No ?token param was supplied for password reset');
  }
  return <ResetPassword token={params.token} data-sentry-element="ResetPassword" data-sentry-component="ResetPasswordRoute" data-sentry-source-file="AppRoot.tsx" />;
}
function LoginRoute(props: {
  session: SessionState;
}) {
  // If authorized, redirect to root '/'. the respective routers, redux-router or react-router depending on app view, will redirect accordingly
  if (props.session.authorizationToken) {
    return <Redirect to="/" />;
  }
  return <RootLogin data-sentry-element="RootLogin" data-sentry-component="LoginRoute" data-sentry-source-file="AppRoot.tsx" />;
}
function OneClickActionSuccessRoute() {
  const {
    id
  } = useParams<{
    id: string;
  }>();
  trackEvent('leadOCASuccess', {
    ocaMeta: {
      leadID: id,
      ...parse(window.location.search)
    }
  });
  return <OneClickSuccess leadID={id} data-sentry-element="OneClickSuccess" data-sentry-component="OneClickActionSuccessRoute" data-sentry-source-file="AppRoot.tsx" />;
}
function OneClickActionErrorRoute() {
  const queryParams = (parse(window.location.search) as {
    leadID?: string;
    traceID?: string;
  });
  trackEvent('leadOCAError', {
    ocaMeta: queryParams
  });
  return <OneClickError leadID={queryParams.leadID} traceID={queryParams.traceID} data-sentry-element="OneClickError" data-sentry-component="OneClickActionErrorRoute" data-sentry-source-file="AppRoot.tsx" />;
}

// Installed via <script> tag in index.html
declare const pendo: {
  initialize: (settings: {}) => void;
};
function DefaultAppRoute(props: {
  session: SessionState;
  locale: string;
}) {
  const [platformSettings, setPlatformSettings] = useState<Settings>();
  const width = window.innerWidth;
  const location = useLocation();
  if (!props.session.user) {
    return <Redirect to={{
      pathname: '/login',
      state: {
        from: location
      }
    }} />;
  }

  // Pendo install docs: https://app.eu.pendo.io/setup?setupKey=eyJTdWJzY3JpcHRpb25OYW1lIjoiUG93ZXJDaG9yZCIsIkhtYWMiOiJ6ZDI3TzFhaUdiT3AxN2x1YWdVZjJ0SnRYRWlIR3JTV3V6dXYwVDFwVWRJPSJ9&os=web
  pendo.initialize({
    visitor: {
      id: props.session.user.ID,
      email: props.session.user.email,
      full_name: props.session.user.fullName
    },
    account: {
      id: props.session.organization.ID,
      name: props.session.organization.name
    }
  });
  if (platformSettings) {
    const shouldShowMobile = width < 600 && platformSettings?.enableV3;
    const subRoot = shouldShowMobile ? <MobileRoot session={props.session} platformSettings={platformSettings} /> : <V1Root session={props.session} platformSettings={platformSettings} />;
    return <Suspense fallback={<LoadingStateless />}>{subRoot}</Suspense>;
  } else {
    fetchPlatformSettings(props.session).then(s => setPlatformSettings(s));
    return <LoadingStateless />;
  }
}