import { isObject, uniqueId } from 'lodash';
import React, { Component } from 'react';
import { formatModifiedByMessage } from '../../schemas';
import FieldMessage from '../FieldMessage/FieldMessage';
import JSONEditor from '../JSONEditor/JSONEditor';
import './TextField.scss';
import { genCIAttr } from '../../util';
const TextJSON = 'textjson';
type onClickHandler = (event: React.MouseEvent) => void;
type Props = {
  className?: string;
  clearIcon?: any;
  defaultValue?: string;
  disabled?: boolean;
  error?: any;
  fieldKey?: string;
  id?: string;
  label?: string;
  leftIcon?: any;
  maxLength?: number;
  modifiedAt?: number;
  modifiedBy?: string;
  name?: string;
  onChange?: (value: string) => void;
  onClick?: (event: React.MouseEvent) => void;
  onInput?: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onLeftIconClick?: (event: React.MouseEvent) => void;
  onRightIconClick?: (event: React.MouseEvent) => void;
  placeholder?: string;
  presentationOptions?: any;
  readOnly?: boolean;
  required?: boolean;
  rightIcon?: any;
  symbol?: any;
  testID?: string;
  type?: string;
  value?: string;
};
type State = {
  error?: any;
  isControlled: boolean;
  isFocused: boolean;
  value: string;
};
export default class Text extends Component<Props, State> {
  state: State;
  input = React.createRef<HTMLInputElement>();
  uniqueFieldId = '';
  jsonEditor = React.createRef<JSONEditor>();
  static getDerivedStateFromProps = ({
    value: propsValue
  }: Props, nextState: State) => {
    // only update state if isControlled and state value != props value
    const {
      isControlled,
      value: stateValue
    } = nextState;
    if (isControlled && propsValue !== stateValue) {
      return {
        ...nextState,
        value: propsValue
      };
    }
    return null;
  };
  constructor(props: Props) {
    super(props);
    const {
      defaultValue = '',
      fieldKey,
      value
    } = props;
    this.state = {
      isControlled: typeof value !== undefined,
      isFocused: false,
      value: value || defaultValue
    };
    const key = fieldKey || 'field-ID';
    this.uniqueFieldId = uniqueId(`${key}`);
  }
  handleInputBlur = () => {
    if (this.input.current) {
      this.onBlur(this.input.current.value);
    }
  };
  handleInputFocus = () => {
    this.setState({
      ...this.state,
      isFocused: true
    });
  };
  handleTextInput = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState({
      ...this.state,
      value
    });
    this.onValue(value);
  };
  handleJSONBlur = () => this.onBlur(this.state.value);
  handleJSONInput = (value: string, _event: any) => {
    this.setState({
      ...this.state,
      value,
      error: undefined
    }, () => this.onValue(value));
  };
  handleJSONErr = (error: string, _value?: string) => {
    this.setState({
      ...this.state,
      error
    });
  };
  focusJSONEditor = () => {
    if (this.jsonEditor.current) {
      this.setState({
        ...this.state,
        isFocused: true
      });
      this.jsonEditor.current.focus();
    }
  };
  onBlur = (value: string) => {
    const {
      onChange
    } = this.props;
    this.setState({
      ...this.state,
      isFocused: false
    });
    if (onChange) {
      onChange(value);
    }
  };
  onValue = (value: string) => {
    const {
      onInput
    } = this.props;
    if (onInput) {
      onInput(value);
    }
  };
  renderField = () => {
    const {
      type
    } = this.props;
    switch (type) {
      case TextJSON:
        return this.renderJSONInput();
      default:
        return this.renderTextInput();
    }
  };
  renderJSONInput = () => {
    const {
      disabled,
      name,
      placeholder = '',
      presentationOptions = {},
      readOnly
    } = this.props;
    const {
      value
    } = this.state;
    const {
      requireValidJSON
    } = presentationOptions;
    return <div className="field">
        <JSONEditor name={name} value={value} placeholder={placeholder} disabled={disabled || readOnly} ref={this.jsonEditor} requireValidJSON={requireValidJSON} onBlur={this.handleJSONBlur} onFocus={this.handleInputFocus} onChange={this.handleJSONInput} onError={this.handleJSONErr} />
      </div>;
  };
  renderTextInput = () => {
    const {
      fieldKey,
      clearIcon,
      defaultValue,
      disabled,
      leftIcon,
      maxLength,
      name,
      placeholder = '',
      readOnly,
      rightIcon,
      symbol,
      testID,
      type = 'text',
      onClick,
      onKeyDown,
      onLeftIconClick,
      onRightIconClick
    } = this.props;
    const {
      isControlled
    } = this.state;
    let {
      value
    } = this.state;
    if (isObject(value)) {
      value = JSON.stringify(value);
    }
    let dataCI = testID;
    if (!testID && fieldKey && fieldKey.length > 0) {
      dataCI = fieldKey;
    }
    return <div className="field">
        {this.renderIcon(leftIcon, clearIcon === 'left', onLeftIconClick)}
        {this.renderSymbol(symbol)}
        <input className="text" defaultValue={isControlled ? defaultValue : undefined} disabled={disabled || readOnly} id={this.uniqueFieldId} maxLength={maxLength} name={name} onBlur={this.handleInputBlur} onClick={onClick} onChange={this.handleTextInput} onFocus={this.handleInputFocus} onKeyDown={onKeyDown} placeholder={placeholder} ref={this.input} type={type} value={value} data-ci={genCIAttr('text-field', dataCI, 'input')} />
        {this.renderIcon(rightIcon, clearIcon === 'right', onRightIconClick)}
      </div>;
  };
  renderIcon = (icon: string, clearInput: boolean, onClick?: onClickHandler) => {
    if (icon) {
      if (onClick) {
        const handleClick: onClickHandler = event => {
          if (clearInput) {
            if (this.state.isControlled) {
              this.setState({
                ...this.state,
                value: ''
              }, () => onClick(event));
            } else {
              if (this.input.current) {
                this.input.current.value = '';
              }
              onClick(event);
            }
          }
        };
        return <div className={'icon ' + icon} onClick={handleClick} role="button" tabIndex={0} />;
      }
      return <div className={'icon ' + icon} />;
    }
    return undefined;
  };
  renderText = () => {
    const {
      label,
      type
    } = this.props;
    if (!label) {
      return this.renderField();
    }

    // react-ace does not provide an id for the label htmlFor attribute
    const focusJSONEditor = TextJSON === type ? this.focusJSONEditor : undefined;
    return <label htmlFor={this.uniqueFieldId} onClick={focusJSONEditor}>
        {label}
        {this.renderField()}
      </label>;
  };
  renderMessage = () => {
    const error = this.props.error || this.state.error;
    if (error) {
      return <FieldMessage error={error} />;
    }
    return undefined;
  };
  renderSymbol = (symbol: string) => {
    if (symbol) {
      return <div className="symbol">{symbol}</div>;
    }
    return undefined;
  };
  renderModerationMessage = () => {
    const {
      modifiedBy,
      modifiedAt = 0
    } = this.props;
    if (!modifiedBy || !modifiedAt) {
      return undefined;
    }
    return <span data-ci="moderation-message">{formatModifiedByMessage(modifiedBy, modifiedAt)}</span>;
  };
  render() {
    const {
      className,
      label,
      modifiedAt,
      modifiedBy,
      placeholder,
      required,
      fieldKey
    } = this.props;
    let classList = 'TextField';
    if (className) {
      classList += ` ${className}`;
    }
    if (this.props.error) {
      classList += ' has-error';
    } else if (this.state.error) {
      classList += ' has-error';
    }
    if (this.props.disabled) {
      classList += ' is-disabled';
    }
    if (this.state.isFocused) {
      classList += ' is-focused';
    }
    if (required) {
      classList += ' is-required';
    }
    // isModified is used to show modified fields in Draft/Pending records so they can be found and approved. - JamesS
    if (modifiedBy && modifiedAt) {
      classList += ' is-modified';
    }
    return <div className={classList} data-ci={genCIAttr('text-field', fieldKey)} data-sentry-component="Text" data-sentry-source-file="TextField.tsx">
        {this.renderModerationMessage()}
        {this.renderText()}
        {this.renderMessage()}
      </div>;
  }
}