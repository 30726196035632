import { pathOr } from 'ramda'
import {
  AnyDocumentAction,
  AnyPaginationAction,
  apiRequest,
  ApiRequestAction,
  clearDocument,
  DocumentActionType,
  FieldActionType,
  generateSlug,
  getDocument,
  hideModal,
  HideModalAction,
  loadEditor,
  LoadEditorAction,
  loadList,
  LoadListAction,
  ModalType,
  resetPagination,
  setDocument,
  setDuplicate,
  setError,
  SetFieldAction,
  setSchema,
  SetSchemaAction,
  setSettings,
  setTranslate,
  showModalV2,
  ShowModalV2Action,
  updateDocument,
  validateField,
  ValidateFieldAction,
} from '../actions'
import { getSlugableField, hasSlug } from '../resource'
import { duplicateResource, duplicateWithLocaleID } from '../resource/duplicate'
import { duplicateSchema, translationSchema } from '../schemas'
import setFieldValue from '../schemas/setFieldValue'
import {
  categorySelector,
  documentIDSelector,
  focusedOrgSelector,
  i18n as i18nSelector,
  resourceSelector,
  schemaSelector,
  userSelector,
  wipDocumentSelector,
} from '../selectors'
import { AppState, Middleware } from '../store'
import * as Sentry from '@sentry/react'

const isNewDoc = (state: any): boolean => {
  const { wip = {} } = state.document
  return !wip.ID || wip.ID === ''
}

const isActiveOrgCheck = (state: any): boolean => {
  const resource = resourceSelector(state)
  if (resource !== 'organizations') {
    return false
  }
  const { wip = {} } = state.document
  return wip.status === 'inactive'
}

const isReactivateOrgCheck = (state: any): boolean => {
  const resource = resourceSelector(state)
  if (resource !== 'organizations') {
    return false
  }
  const { wip = {}, original = {} } = state.document
  return original.status === 'inactive' && wip.status === 'active'
}

const isPlatformSetting = (state: AppState): boolean => {
  return resourceSelector(state) === 'platformSettings'
}

type HandleActions = SetFieldAction | AnyDocumentAction
type DispatchActions =
  | HandleActions
  | ApiRequestAction
  | ValidateFieldAction
  | ShowModalV2Action
  | HideModalAction
  | LoadEditorAction
  | LoadListAction
  | AnyPaginationAction
  | SetSchemaAction

export const documentMiddleware: Middleware<HandleActions, DispatchActions> = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  const state = getState()
  const user = userSelector(state)
  const resource = resourceSelector(state)
  const org = focusedOrgSelector(state)
  const category = categorySelector(state)
  const doc = wipDocumentSelector(state)
  const schema = schemaSelector(state)

  switch (action.type) {
    case FieldActionType.SET:
      const { fieldSchema, field, value } = action.payload
      const oldWip = pathOr({}, ['document', 'wip'], state)
      const newWip = setFieldValue([field], fieldSchema, value, oldWip)
      next(updateDocument(newWip))
      dispatch(validateField(state.schema.resource, field, value))
      break

    case DocumentActionType.CLEAR: {
      dispatch(resetPagination())
      break
    }

    case DocumentActionType.DELETE_ERROR:
    case DocumentActionType.DENY_CHANGES_ERROR:
    case DocumentActionType.DRAFT_ERROR:
    case DocumentActionType.GET_ERROR:
    case DocumentActionType.PUBLISH_CHANGES_ERROR:
    case DocumentActionType.SAVE_ERROR:
    case DocumentActionType.SAVE_USER_ERROR:
    case DocumentActionType.SUBMIT_CHANGES_ERROR:
    case DocumentActionType.GENERATE_SLUG_ERROR:
      dispatch(setError(action?.payload?._inputs ?? action.payload))
      break

    case DocumentActionType.CLOSE_TRANSLATOR: {
      dispatch(loadEditor(category, resource, state.location.payload.id))
      break
    }

    case DocumentActionType.DELETE:
      dispatch(
        apiRequest({
          url: `/${resource}/${action.id}`,
          method: 'DELETE',
          feature: DocumentActionType.DELETE,
        })
      )
      break

    case DocumentActionType.DELETE_SUCCESS:
      dispatch(loadList(categorySelector(state)))
      dispatch(hideModal())
      dispatch(clearDocument())
      break

    case DocumentActionType.DENY_CHANGES:
      dispatch(
        apiRequest({
          url: `/moderations/${documentIDSelector(state)}?moderationDeny=${encodeURIComponent(action.denialReason)}`,
          method: 'POST',
          feature: DocumentActionType.DENY_CHANGES,
        })
      )
      break

    case DocumentActionType.DENY_CHANGES_SUCCESS:
      dispatch(loadList(category))
      break

    case DocumentActionType.DRAFT: {
      const url = doc.ID ? `/${schema.resource}/${doc.ID}` : `/${schema.resource}`
      dispatch(
        apiRequest({
          url,
          method: doc.ID ? 'PATCH' : 'PUT',
          body: doc,
          feature: DocumentActionType.DRAFT,
          params: {
            moderate: 'draft',
          },
        })
      )
      break
    }

    case DocumentActionType.DRAFT_SUCCESS:
      next(setDocument(action.payload.data))
      next(setSchema(action.payload.schema))
      break

    case DocumentActionType.GENERATE_SLUG:
      dispatch(
        apiRequest({
          url: `/organizations/${org?.trunkID}/${org?.ID}/${resource}/slug`,
          method: 'GET',
          feature: DocumentActionType.GENERATE_SLUG,
          params: {
            slug: action.slug,
            name: action.name,
          },
        })
      )
      break

    case DocumentActionType.GET: {
      const { id, duplicatedFrom } = action
      const { localeID } = state.location.payload
      if (user && (resource || id === 'self')) {
        if (id === 'new') {
          dispatch(
            apiRequest({
              url: `/organizations/${org?.trunkID}/${org?.ID}/${resource}/new`,
              method: 'GET',
              feature: DocumentActionType.GET,
              params: {
                localeID,
                duplicatedFrom,
              },
            })
          )
        } else if (id === 'self') {
          dispatch(
            apiRequest({
              url: `/self/${user.ID}`,
              method: 'GET',
              feature: DocumentActionType.GET,
            })
          )
        } else {
          dispatch(
            apiRequest({
              url: `/organizations/${org?.trunkID || org?.ID}/${org?.ID}/${resource}/${id}`,
              method: 'GET',
              feature: DocumentActionType.GET,
              resource,
            })
          )
        }
      } else {
        // TODO: prevent non-auth related calls until
        // session has been established, wait to
        // handle route actions until session is
        // established
        setTimeout(() => {
          dispatch(getDocument(id))
        }, 250)
      }
      break
    }

    case DocumentActionType.GET_SUCCESS: {
      const { data, schema } = action.payload

      dispatch(setSchema(schema))

      if (state.document.duplicate) {
        dispatch(setDuplicate(data))
      } else if (state.document.translate) {
        dispatch(setTranslate(data))
      } else {
        dispatch(setDocument(data))
      }
      break
    }

    case DocumentActionType.LOAD_TRANSLATOR: {
      const { wip } = state.document
      const { id } = state.location.payload
      if (!wip || wip.ID !== id) {
        dispatch(getDocument(id))
      }
      break
    }

    case DocumentActionType.PUBLISH_CHANGES:
      dispatch(
        apiRequest({
          url: `/moderations/${documentIDSelector(state)}`,
          method: 'POST',
          body: doc,
          feature: DocumentActionType.PUBLISH_CHANGES,
          params: {
            moderationApprove: 'true',
          },
        })
      )
      break

    case DocumentActionType.PUBLISH_CHANGES_SUCCESS:
      next(setDocument(action.payload.data))
      dispatch(loadList(category))
      break

    case DocumentActionType.SAVE:
      const i18n = i18nSelector(state)
      const saveDoc = () => {
        dispatch(
          apiRequest({
            url: `/organizations/${org?.trunkID || org?.ID}/${org?.ID}/${resource}/${doc.ID}`,
            method: doc.ID ? 'PATCH' : 'PUT',
            body: doc,
            feature: DocumentActionType.SAVE,
          })
        )
      }
      if (user && user.ID === doc.ID) {
        dispatch(
          apiRequest({
            url: `/self/${user.ID}`,
            method: 'PATCH',
            body: doc,
            feature: DocumentActionType.SAVE_USER,
          })
        )
      } else if (isNewDoc(state)) {
        saveDoc()
      } else if (isActiveOrgCheck(state)) {
        dispatch(
          showModalV2(
            {
              title: i18n('organization.inactive.title'),
              body: i18n('organization.inactive.message'),
              type: ModalType.Confirm,
            },
            () => {
              dispatch(hideModal())
              saveDoc()
            }
          )
        )
      } else if (isReactivateOrgCheck(state)) {
        dispatch(
          showModalV2(
            {
              title: i18n('organization.reactivate.title'),
              body: i18n('organization.reactivate.message'),
              type: ModalType.Confirm,
            },
            () => {
              dispatch(hideModal())
              saveDoc()
            }
          )
        )
      } else {
        saveDoc()
      }
      break

    case DocumentActionType.SAVE_SUCCESS: {
      const { data, ID, schema } = action.payload

      // schema isn't always returns (see user creation response)
      if (schema) {
        dispatch(setSchema(schema))
      }

      dispatch(setDocument(data))

      if (doc.ID === '') {
        dispatch(loadEditor(category, resource, ID || data?.ID))
      } else if (!!data?.ID && doc.ID !== data.ID) {
        // redirect to saved document id, such as instances where
        // a save returns a newly created form instead of mutating an existing form
        dispatch(loadEditor(category, resource, data.ID))
      } else {
        // update the store if updating platformSettings
        if (isPlatformSetting(state)) {
          next(setSettings(data))
        }
      }
      break
    }

    case DocumentActionType.SAVE_USER_SUCCESS:
      next(setDocument(action.payload.data))
      break

    // When is this the case?
    case DocumentActionType.SET_DUPLICATE: {
      const { duplicatedFrom } = action
      const { id } = state.location.payload
      if (!duplicatedFrom) {
        dispatch(getDocument('new', id !== 'new' ? id : undefined))
      } else {
        try {
          if (hasSlug(duplicatedFrom)) {
            dispatch(generateSlug(duplicatedFrom.slug, getSlugableField(duplicatedFrom)))
          }
          dispatch(setSchema(duplicateSchema(schema)))
          const dupe = duplicateResource(duplicatedFrom, schema)
          dispatch(setDocument(dupe))
        } catch (err) {
          // recover from duplicate error by returning to editor of source doc
          Sentry.captureException(err, {
            tags: {
              source: 'DocumentsMiddleware',
            },
          })
          console.error(err)
          dispatch(loadEditor(category, resource, id))
        }
      }
      break
    }

    case DocumentActionType.SET_TRANSLATE: {
      const { translatedFrom } = action
      const { id, locale, localeID } = state.location.payload
      if (!translatedFrom) {
        // Still have to hit new, to get the schema permissions correct
        // otherwise it does not obey the isImmutable b/c you should
        // be able to edit the slug even if you are duplicating from
        // existing.
        dispatch(getDocument('new', id !== 'new' ? id : undefined))
      } else {
        try {
          if (hasSlug(translatedFrom)) {
            dispatch(generateSlug(translatedFrom.slug, getSlugableField(translatedFrom)))
          }
          const dupe = duplicateWithLocaleID(translatedFrom, schema)
          if (locale) {
            dupe.locale = locale
          }
          if (localeID) {
            dupe.localeID = localeID
            if (localeID !== translatedFrom.localeID) {
              // translating from a duplication-source locale
              dispatch(setSchema(translationSchema(schema, locale)))
            }
          }
          dispatch(setDocument(dupe))
        } catch (err) {
          // recover from duplicate error by returning to editor of source doc
          Sentry.captureException(err, {
            tags: {
              source: 'DocumentsMiddleware',
            },
          })
          console.error(err)
          dispatch(loadEditor(category, resource, id))
        }
      }
      break
    }

    case DocumentActionType.SUBMIT_CHANGES:
      dispatch(
        apiRequest({
          url: `/${schema.resource}/${doc.ID}`,
          method: doc.ID ? 'PATCH' : 'PUT',
          body: doc,
          feature: DocumentActionType.SUBMIT_CHANGES,
          params: {
            moderate: 'publish',
          },
        })
      )
      break

    case DocumentActionType.SUBMIT_CHANGES_SUCCESS:
      next(setDocument(action.payload.data))
      dispatch(loadList(category))
      break
  }
}
