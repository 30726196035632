import { AnyAction, Middleware, MiddlewareAPI } from 'redux'
import { apiRequest, getV3OrgHierarchy, loadDefaultPage, setSettings, SETTINGS, SettingsActionTypes, getFilters } from '../actions'
import { defaultSchemaSelector, focusedOrgSelector } from '../selectors'

export const settingsMiddleware: Middleware = ({ dispatch, getState }: MiddlewareAPI) => (next: Function) => (action: AnyAction) => {
  next(action)

  switch (action.type) {
    case SettingsActionTypes.GET_SETTINGS:
      const org = focusedOrgSelector(getState())
      if (org) {
        dispatch(
          apiRequest({
            method: 'GET',
            url: `/organizations/${org.trunkID || org.ID}/${org.ID}/platformSettings`,
            feature: SETTINGS,
          })
        )
      }
      break

    case SettingsActionTypes.SET_SETTINGS: {
      if (action?.payload?.enableV3) {
        dispatch(getV3OrgHierarchy())
      }
      break
    }

    case SettingsActionTypes.GET_SETTINGS_SUCCESS:
      if (action?.payload?.data?.length) {
        dispatch(setSettings(action.payload.data[0]))
        // load the default list page since lead view switching relies on up-to-date platformSettings
        dispatch(loadDefaultPage(defaultSchemaSelector(getState())))
      }
      break
  }
}
