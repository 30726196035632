import { HttpMethod, RendererError } from '../types'
import { ArchitectActionType } from './architect'

export const RENDERER_REQUEST = 'RENDERER_REQUEST'
export const RENDERER_SUCCESS = 'RENDERER_SUCCESS'
export const RENDERER_ERROR = 'RENDERER_ERROR'

type RendererRequestProps = {
  feature: ArchitectActionType
  body?: RequestBody
  method: HttpMethod
  url: string
  params?: {
    [key: string]: string | undefined
  }
  responseType?: string
}

type RequestBody = {
  [key: string]: string
}

export type RendererRequestAction = {
  type: string
  payload: RequestBody | undefined
  meta: Omit<RendererRequestProps, 'body'>
}

export const rendererRequest = ({ feature, body, method, url, params, responseType }: RendererRequestProps): RendererRequestAction => ({
  type: `${feature} ${RENDERER_REQUEST}`,
  payload: body,
  meta: {
    method,
    url,
    feature,
    params,
    responseType,
  },
})

type RendererData = {
  diagnostics: string[]
  html: string
}

export type RendererSuccessAction = {
  type: string
  payload: RendererData
  meta: {
    feature: string
  }
}

export const rendererSuccess = (data: RendererData, feature: ArchitectActionType): RendererSuccessAction => ({
  type: `${feature} ${RENDERER_SUCCESS}`,
  payload: data,
  meta: {
    feature,
  },
})

export type RendererErrorAction = {
  type: string
  payload: RendererError
  meta: {
    feature: string
  }
}

export const rendererError = (error: RendererError, feature: ArchitectActionType): RendererErrorAction => ({
  type: `${feature} ${RENDERER_ERROR}`,
  payload: error,
  meta: {
    feature,
  },
})

export type AnyRendererAction = RendererSuccessAction | RendererRequestAction | RendererErrorAction
