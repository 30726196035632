export const MEMBERSHIPS = '[memberships]'
export const SET_MEMBERSHIPS = '[memberships] Set'
export const GET_MEMBERSHIPS = '[memberships] Fetch'

interface SetMembershipsAction {
  type: typeof SET_MEMBERSHIPS
  payload: any[]
}

export const setMemberships = (memberships: any[]): SetMembershipsAction => ({
  type: SET_MEMBERSHIPS,
  payload: memberships,
})

interface GetMembershipsAction {
  type: typeof GET_MEMBERSHIPS
  payload: string
}

export const getMemberships = (userID: string): GetMembershipsAction => ({
  type: GET_MEMBERSHIPS,
  payload: userID,
})
