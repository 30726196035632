import axios, { AxiosError, AxiosResponse } from 'axios'
import { RENDERER_REQUEST, rendererError, rendererSuccess } from '../actions'
import config from '../config'
import { authHeaderSelector } from '../selectors'
import { Middleware } from '../store'
import { RendererError } from '../types'
import * as Sentry from '@sentry/react'

export const rendererMiddleware: Middleware = (store) => (next) => (action) => {
  next(action)

  if (action.type.indexOf(RENDERER_REQUEST) > 0) {
    const { url, method, feature, params, responseType } = action.meta

    let fullUrl: string
    let data: any
    if (url.indexOf('http') === 0) {
      fullUrl = url
      data = action.payload
    } else {
      fullUrl = config.renderer + url
      data = action.payload ? JSON.stringify(action.payload) : null
    }

    axios({
      url: fullUrl,
      method,
      headers: authHeaderSelector(store.getState()),
      data,
      params,
      responseType,
    })
      .then((response: AxiosResponse) => {
        if (response.status > 299) {
          store.dispatch(rendererError(response.data, feature))
        } else {
          store.dispatch(rendererSuccess(response.data, feature))
        }
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error, {
          tags: {
            source: 'rendererMiddleware',
            statusCode: error.code,
            type: feature,
          },
          contexts: {
            error: {
              message: error.message,
            },
            request: {
              url: fullUrl,
              method: method,
            },
          },
        })
        console.error('RENDERER ERROR: ', error)
        if (error?.response?.data) {
          store.dispatch(rendererError(error.response.data, feature))
        } else {
          //
          store.dispatch(rendererError((error.response as unknown) as RendererError, feature))
        }
      })
  }
}
