import _ from 'lodash'

const normalizeGroupShape = (groups: any) =>
  (groups || []).map((group: any) => {
    const items = (group.categories || [])
      .map((category: any) => ({
        key: category.key,
        label: category.label,
        presentation: category.presentation,
        type: 'category',
        isAllowed: !!category.isAllowed,
      }))
      .map((item: any): any => {
        if (group.key === item.key || 'show' === item.presentation) {
          return null
        }
        item.category = item.key
        return item
      })
      .filter(Boolean)
    const dup = _.find(group.items, (i: any) => i.key === group.key || i.label === group.label)
    const firstKey = _.get(group, 'categories[0].key')
    const category = (dup && dup.key) || firstKey
    return {
      key: group.key,
      label: group.label,
      type: 'group',
      isAllowed: group.isAllowed || true,
      items,
      category,
      sortOrder: group.sortOrder,
    }
  })

const parseModules = (groups: any) =>
  normalizeGroupShape((groups || []).filter((group: any) => (group.categories || []).filter((category: any) => category.isAllowed).length))

export default parseModules
