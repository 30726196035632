import { Sort } from '../types'

export const updateSort = (sort: Sort, columnName: string): Sort => {
  if (sort.column === columnName) {
    return {
      ...sort,
      direction: sort.direction === '-' ? '' : '-',
    }
  } else {
    return {
      column: columnName,
      direction: '',
    }
  }
}
