import { debounce } from 'lodash'
import { AnyAction, Dispatch } from 'redux'
import { schemaSelector } from 'selectors'
import { updateDocumentList } from '../actions/documents'
import { setSkip } from '../actions/pagination'
import { AnySearchAction, SearchActionType } from '../actions/search'
import { Middleware } from '../store'
import { V3LeadsCategory } from '../schemas/v3Mocks'

const delayedRefetch = debounce((dispatch: Dispatch<AnyAction>) => dispatch(updateDocumentList()), 350)

export const searchMiddleware: Middleware<AnySearchAction> = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  switch (action.type) {
    case SearchActionType.CLEAR:
    case SearchActionType.SET:
      const schema = schemaSelector(getState())
      // PD-601 keeping the setSkip behavior that was here previously before
      // the switch statement was removed
      if (schema.resource !== V3LeadsCategory) {
        dispatch(setSkip(0))
      }
      if (action.refetch) {
        if (action.delayed) {
          delayedRefetch(dispatch)
        } else {
          dispatch(updateDocumentList())
        }
      }
      break
  }
}
