import { Doc, Lead, Note, V3User } from '.'
import { RuleEngineAction, RuleEngineTrigger } from './Rule'

type ActivityCommon = Pick<Doc, 'ID' | 'ReadOnly' | 'CreatedAt' | 'UpdatedAt'> & {
  // Field is the resource field that was changed
  Field: string
  // ResourceID is the V3 Document ID
  ResourceID: string
  // ResourceType is the V3 model name
  ResourceType: string
  // User is the user who made the change tracked by this event
  User: V3User
}

export enum ActivityType {
  ResourceCreated = 'resource-create',
  ResourceUpdate = 'resource-update',
  StatusUpdate = 'status-update',
  Impression = 'impression',
  Notification = 'notification',
  Reassignment = 'reassignment',
  Share = 'share',
  Unshare = 'unshare',
  View = 'view',
  OneClickedAction = 'one-click-action',
  NoteAdded = 'note-added',
  ActionError = 'action-error',
  ActionSuccess = 'action-success',
  LeadReminderSuccess = 'lead-reminder-success',
  LeadReminderError = 'lead-reminder-error',
  LeadReminderTrigger = 'lead-reminder-trigger',
}

export type ActivityResourceCreated = ActivityCommon & { Type: ActivityType.ResourceCreated }
export type ActivityResourceUpdated = ActivityCommon & { Type: ActivityType.ResourceUpdate; Field: string; Value: string }
export type ActivityStatusUpdated = ActivityCommon & { Type: ActivityType.StatusUpdate; Status: string }
export type ActivityImpression = ActivityCommon & { Type: ActivityType.Impression; RequestMeta: {} }
export type ActivityNotification = ActivityCommon & { Type: ActivityType.Notification; Recipients: {}[] }
export type ActivityReassignment = ActivityCommon & { Type: ActivityType.Reassignment; ReassignedFrom: string; ReassignedTo: string }
export type ActivityShare = ActivityCommon & { Type: ActivityType.Share; SharedWith: string[] }
export type ActivityUnshare = ActivityCommon & { Type: ActivityType.Unshare; UnsharedWith: string[] }
export type ActivityView = ActivityCommon & { Type: ActivityType.View; RequestMeta: {} }
export type ActivityOneClicked = ActivityCommon & { Type: ActivityType.OneClickedAction; Url: string; QueryStringAction: string; Scopes: string[] }
export type ActivityNoteAdded = ActivityCommon & { Type: ActivityType.NoteAdded; Note: Note }
export type ActivityLeadReminderSuccess = ActivityCommon & { Type: ActivityType.LeadReminderSuccess; Success: string }
export type ActivityLeadReminderError = ActivityCommon & { Type: ActivityType.LeadReminderError; Error: string }
export type ActivityLeadReminderTrigger = ActivityCommon & { Type: ActivityType.LeadReminderTrigger; Trigger: RuleEngineTrigger }
export type ActivityActionError = ActivityCommon & {
  Type: ActivityType.ActionError
  Action: RuleEngineAction
  Error: string
  Trigger: RuleEngineTrigger
}
export type ActivityActionSuccess = ActivityCommon & {
  Type: ActivityType.ActionSuccess
  Action: RuleEngineAction
  Trigger: RuleEngineTrigger
}

export type Activity =
  | ActivityResourceCreated
  | ActivityResourceUpdated
  | ActivityStatusUpdated
  | ActivityImpression
  | ActivityNotification
  | ActivityReassignment
  | ActivityShare
  | ActivityUnshare
  | ActivityView
  | ActivityOneClicked
  | ActivityNoteAdded
  | ActivityActionError
  | ActivityActionSuccess
  | ActivityLeadReminderSuccess
  | ActivityLeadReminderError
  | ActivityLeadReminderTrigger
