import React, { Component } from 'react';
import './FieldMessage.scss';
export default class FieldMessage extends Component<any, any> {
  state = {};
  render() {
    let classList = 'FieldMessage';
    let message = this.props.value || '';
    if (this.props.className) {
      classList += ` ${this.props.className}`;
    }
    if (this.props.error) {
      classList += ' is-error';
      message = this.props.error;
    }
    if (Array.isArray(message)) {
      message = message.join(', ');
    }
    if ('object' === typeof message) {
      // catch malformed messages
      message = JSON.stringify(message);
    }
    return <div className={classList} data-sentry-component="FieldMessage" data-sentry-source-file="FieldMessage.tsx">{message}</div>;
  }
}