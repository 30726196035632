import {
  ACCOUNT_PAGE,
  ANALYTICS_PAGE,
  ARCHITECT_APP,
  calculateFlags,
  clearDocument,
  clearDocuments,
  clearSearch,
  clearSort,
  EDITOR_PAGE,
  EditorMode,
  getAnalytics,
  getContentConfigs,
  getDocument,
  getDocuments,
  getFilters,
  getPages,
  getTriggerDetail,
  getTriggerList,
  getV3Lead,
  getV3Leads,
  HOME_PAGE,
  LEAD_DETAILS,
  LEADS_LIST,
  LIST_PAGE,
  loadArchitect,
  loadDefaultPage,
  loadLeadsList,
  LOGIN_PAGE,
  OLD_SB_ROUTE,
  setDuplicate,
  setRedirect,
  setSchema,
  setTranslate,
  setTriggerScope,
  TRIGGER_DETAILS,
  TRIGGERS_LIST,
} from '../actions'
import { setCategory, unsetCategory } from '../actions/route'
import { Schema } from '../schemas'
import {
  analyticsSettingsSelector,
  defaultSchemaSelector,
  focusedOrgSelector,
  isCategoryAvailableSelector,
  isPageAction,
  isPublicPage,
  isValidSession,
  readyForV3Filters,
  selecti18n,
  shouldUpdateFilters,
  siteIDSelector,
} from '../selectors'
import { Middleware } from '../store'
import { reloadToLogin } from './session'
import { triggerTitleSelector } from 'selectors/triggers'
import { selectContentConfigs } from '../selectors/contentConfig'
import { Scope } from '../types'
import { V3LeadsCategory } from '../schemas/v3Mocks'

export const pagesMiddleware: Middleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action)
  const sessionValid = isValidSession(getState())
  const publicPage = isPublicPage(action.type)

  if (isPageAction(action.type)) {
    if (!publicPage && !sessionValid) {
      next(setRedirect(action.payload))
      reloadToLogin()
      return
    }
    // unsetCategory so navigating back to a list based view
    // detects that it should go get new filters
    dispatch(unsetCategory())
  }

  switch (action.type) {
    case LOGIN_PAGE:
      dispatch(calculateFlags())
      break

    case HOME_PAGE:
      dispatch(loadDefaultPage(defaultSchemaSelector(getState())))
      break

    case LEAD_DETAILS: {
      dispatch(clearDocument())
      // set a schema label for the AppBar title
      dispatch(getV3Lead(action.payload))
      break
    }

    case LEADS_LIST: {
      dispatch(clearDocuments())
      // TODO: 90% of this can be combined with LIST_PAGE at this point.
      if (!action?.payload?.category) {
        // fix empty location.payload if this route is initial page
        dispatch(loadLeadsList(getState().location?.query))
        break
      }

      if (getState().search) {
        dispatch(clearSearch(false))
      }

      // When we are ready, one of the contentconfigs / lead types getters will
      // call loadLeadsList.
      if (!readyForV3Filters(getState())) {
        break
      }

      if (shouldUpdateFilters(getState())) {
        dispatch(getFilters())
      } else {
        dispatch(getV3Leads())
      }
      // set the category to detect changes between categories
      dispatch(setCategory(V3LeadsCategory))
      break
    }

    case LIST_PAGE: {
      dispatch(clearDocuments())
      // redirect to the default available page if the user does not have access to the requested category
      if (!isCategoryAvailableSelector(getState(), action.payload.category)) {
        // PD-601 a VERY nasty recursion loop can happen if the default schema isn't loaded, so just break out
        const schema = defaultSchemaSelector(getState())
        if (schema === '') {
          break
        }
        dispatch(loadDefaultPage(schema))
        break
      }
      dispatch(clearSort())
      if (getState().search) {
        dispatch(clearSearch(false))
      }

      if (shouldUpdateFilters(getState())) {
        // getFilters() will trigger getDocuments()
        dispatch(getFilters())
      } else {
        // always at least getDocuments()
        dispatch(getDocuments())
      }
      // set the category to detect changes between categories
      dispatch(setCategory(action.payload.category))
      break
    }

    case EDITOR_PAGE: {
      const { document } = getState()
      const wipIsCurrent = document?.wip?.ID === action.payload.id
      switch (action.payload.mode) {
        case EditorMode.DUPLICATE:
          dispatch(setDuplicate(wipIsCurrent ? document.wip : undefined))
          break

        case EditorMode.TRANSLATE:
          dispatch(setTranslate(wipIsCurrent ? document.wip : undefined))
          break

        default:
          dispatch(clearDocument())
          dispatch(getDocument(action.payload.id))
      }
      break
    }

    case ACCOUNT_PAGE:
      dispatch(clearDocuments())
      dispatch(clearDocument())
      dispatch(getDocument('self'))
      break

    case ANALYTICS_PAGE: {
      const state = getState()
      let analyticsTypeEnabled = false
      const { dashboardType } = action.payload
      const settings = analyticsSettingsSelector(state)
      const schemaIsAvailable = isCategoryAvailableSelector(state, `analytics/${dashboardType}`)

      switch (dashboardType) {
        case 'sites':
          analyticsTypeEnabled = settings?.enableSiteAnalytics
          break
        case 'leads':
          analyticsTypeEnabled = settings?.enableAnalytics
          break
        case 'campaigns':
          analyticsTypeEnabled = settings?.enableCampaignAnalytics
          break
      }

      analyticsTypeEnabled && schemaIsAvailable ? dispatch(getAnalytics(dashboardType)) : dispatch(loadDefaultPage(defaultSchemaSelector(state)))
      break
    }

    case ARCHITECT_APP:
      const siteID = siteIDSelector(getState())
      dispatch(getPages(siteID))
      break

    case OLD_SB_ROUTE:
      dispatch(loadArchitect(action.payload.siteID))
      break

    case TRIGGERS_LIST: {
      dispatch(clearDocuments())
      const state = getState()
      const { isPlatform } = state.location.payload

      let label = selecti18n(state, 'nav.labels.rules.customOrganization')
      let scope = Scope.IncludeDescendants
      if (isPlatform) {
        label = selecti18n(state, 'nav.labels.rules.platformSystem')
        scope = Scope.PlatformSystem
      }
      dispatch(setTriggerScope(scope))
      dispatch(getTriggerList(scope))

      const configs = selectContentConfigs(state)
      if (configs.length === 0) {
        const org = focusedOrgSelector(state)
        if (org) {
          dispatch(getContentConfigs(org.trunkID))
        }
      }

      dispatch(setSchema({ resource: 'trigger', label: label } as Schema))
      break
    }

    case TRIGGER_DETAILS: {
      dispatch(clearDocument())
      const state = getState()
      const { id, isPlatform } = state.location.payload
      const configs = selectContentConfigs(state)
      if (configs.length === 0) {
        const org = focusedOrgSelector(state)
        if (org) {
          dispatch(getContentConfigs(org.trunkID))
        }
      }
      const scope = isPlatform ? Scope.PlatformSystem : Scope.IncludeDescendants
      dispatch(setSchema({ resource: 'trigger', label: triggerTitleSelector(getState()) } as Schema))
      dispatch(setTriggerScope(scope))
      dispatch(getTriggerDetail(id, scope))
      break
    }
  }
}
