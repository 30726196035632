import { cloneDeep as clone } from 'lodash'
import { Duplicator } from '../duplicate'
import { Navbar } from './model'

export const duplicator: Duplicator<Navbar> = (source, _schema) => {
  const dupe = clone<Navbar>(source)
  dupe.duplicatedFromID = source.ID
  dupe.duplicatedFromLocaleID = source.localeID
  dupe.ID = ''
  dupe.locale = undefined
  dupe.localeID = undefined
  delete dupe.audit
  return dupe
}
