import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useI18nSelector } from 'v3/context/i18n';
import { i18nKey, i18nState } from '../../reducers/i18n';
import { categorySelector } from '../../selectors/location';
import { AppState } from '../../store';
import './Loading.scss';
const useStyles = makeStyles((_theme: Theme) => createStyles({
  loader: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
export const LoadingStateless = (props: MapProps) => {
  const classes = useStyles(props);
  const i18n = useI18nSelector();
  const {
    category,
    module
  } = props;
  const title = props.title || i18n((`loading.messages.${module || category}.title` as i18nKey));
  const message = props.message || i18n((`loading.messages.${module || category}.message` as i18nKey));
  return <div className={classes.loader} data-sentry-component="LoadingStateless" data-sentry-source-file="Loading.tsx">
      <div className="loader" />
      {title || message ? <div className="message-wrapper">
          <h2 className="title">{title}</h2>
          <p className="message">{message}</p>
        </div> : null}
    </div>;
};
type MapProps = {
  title?: string;
  message?: string;
  module?: string;
  category?: string;
};
const mapProps = (state: AppState): any => ({
  category: categorySelector(state)
});
export default connect<MapProps, any, any, AppState>(mapProps)(LoadingStateless);