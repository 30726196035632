import { debounce } from 'lodash'
import { updateDocumentList } from '../actions/documents'
import { AnySortAction, SortActionType } from '../actions/sort'
import { Middleware } from '../store'

const refetch = debounce((dispatch) => dispatch(updateDocumentList()), 150)

export const sortMiddleware: Middleware<AnySortAction> = ({ dispatch }) => (next) => (action) => {
  next(action)

  switch (action.type) {
    case SortActionType.SET:
      refetch(dispatch)
      break
  }
}
