import { createBrowserHistory } from 'history'
import { anyPass, complement, compose, find, isEmpty, isNil, merge, pickBy } from 'ramda'

export function isArrayEqual(a: Array<any>, b: Array<any>): boolean {
  if (!Array.isArray(a) || !Array.isArray(b)) return false
  if (a.length !== b.length) return false
  return a.reduce((acc, curr) => {
    // if the value has been flipped to false, keep it that way
    if (!acc) return acc
    // if the current value is not in "b", then return false
    const bCurr = find((v) => isEqual(v, curr), b)
    if (!bCurr) return false
    return acc
  }, true)
}

export function isObjectEqual(a: any, b: any): boolean {
  if (typeof a !== 'object' || typeof b !== 'object') return false
  const aKeys = Object.getOwnPropertyNames(a)
  const bKeys = Object.getOwnPropertyNames(b)
  if (aKeys.length !== bKeys.length) return false
  return aKeys.reduce((acc: any, curr: any) => {
    if (!acc) return acc
    const aVal = a[curr]
    const bVal = b[curr]
    return isEqual(aVal, bVal)
  }, true)
}

export function isValueEqual(a: any, b: any): boolean {
  return a === b
}

export function isEqual(a: any, b: any): boolean {
  if (typeof a !== typeof b) return false
  if (Array.isArray(a)) return isArrayEqual(a, b)
  if (typeof a === 'object') return isObjectEqual(a, b)
  return isValueEqual(a, b)
}
const emptyOrNil = anyPass([isNil, isEmpty])
const notEmptyOrNil = complement(emptyOrNil)
export const mergeAndFilterRight = compose(pickBy(notEmptyOrNil), merge)

export const history = createBrowserHistory()

export const push = history.push

export const callIfExists = (...values: any) => (fn?: Function) => {
  if (fn && typeof fn === 'function') {
    return fn(...values)
  }
  return values[0]
}

export const uuid = () => {
  let d = new Date().getTime()
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now()
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export { default as formatLocalTime } from './formatLocalTime'
export { default as replaceVariable } from './replaceVariable'
export { default as replaceVariables } from './replaceVariables'
export { default as getFirstNestedPathString } from './getFirstNestedPathString'
