import { and, always, compose, cond, curry, find, gt, ifElse, is, length, lt, map, mapObjIndexed, not, or, pathOr, propOr, T, when } from 'ramda'

const asArray = when(compose(not, is(Array)), always([]))

const getFieldValue = curry((field: any, value: any) => {
  const enums = or(pathOr([], ['restrictions', 'enums'], field), [])
  return ifElse(
    () => gt(length(enums), 0),
    () => {
      const obj = find((e: any) => e.value === value, enums)
      return propOr(value, 'label', obj)
    },
    () => value
  )(value)
})

const digestEnumValues = (schema: any, doc: any): any => {
  return mapObjIndexed((val, key) => {
    // get the field for the current key
    const field = find((f: any) => f.key === key, or(propOr([], 'fields', schema), []))
    const getValue = getFieldValue(field)
    const hasFields = compose(lt(0), length, asArray, propOr([], 'fields'))(field)
    return cond([
      // if the field has "fields" && value is an Array => mapObjIndexed + recurse
      [(value) => and(is(Array, value), hasFields), (value) => map((v) => digestEnumValues(field, v), value)],
      // if the field has "fields" && value is object => recurse
      [(value) => and(is(Object, value), hasFields), (value) => digestEnumValues(field, value)],
      // if the field has "fields" && value is NOT object => return value
      [() => hasFields, (value) => value],
      // get digested value using field
      [T, getValue],
    ])(val)
  }, doc)
}

export const digestDocumentsForList = curry((schema: any, documents: Array<any>) =>
  compose(
    map((doc: any) => digestEnumValues(schema, doc)),
    asArray
  )(documents)
)

export default digestEnumValues
