import { AppState } from '../store'

export const flagSelector = (state: AppState, flag: string): boolean => state.flags?.[flag] ?? false

export const selectAllFlags = (state: AppState, ...flags: string[]): boolean => {
  for (const i of flags) {
    if (!flagSelector(state, i)) {
      return false
    }
  }
  return true
}

export const selectAnyFlag = (state: AppState, ...flags: string[]): boolean => {
  for (const i of flags) {
    if (flagSelector(state, i)) {
      return true
    }
  }
  return false
}
