import {
  API_ERROR,
  apiRequest,
  GetContentConfigsAction,
  GetContentConfigsSuccessAction,
  GetLeadTypesSuccessAction,
  hideLoader,
  loadLeadsList,
  reloadV3Lead,
  SaveV3LeadAction,
  setDocument,
  setDocuments,
  setSchema,
  setSchemaCategory,
  setV3ContentConfigs,
  setV3Lead,
  setV3Leads,
  setV3LeadTypes,
  showLoader,
  V3LeadActionTypes,
} from '../actions'
import config from '../config'
import {
  i18n,
  readyForV3Filters,
  selectFilterOptionStringsByKey,
  selectLeadStatusToStageSetting,
  selectLeadTypeEnums,
  selectPartialV3LeadForPatch,
  v3LeadQueryRequestConfigSelector,
} from '../selectors'
import { Middleware } from '../store'
import { Lead, V2Response } from '../types'
import { getLeadName, marshalQueriedLeadsToLeads } from '../util'
import { getV3LeadsMockSchema, V3LeadsCategory } from '../schemas/v3Mocks'
import { selectLeadStageEnums, selectLeadStatusEnums } from '../selectors/contentConfig'
import { saveAs } from 'file-saver'

export const v3LeadsMiddleware: Middleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action)

  switch (action.type) {
    // GET_LEADS fetch leads from v3 api
    case V3LeadActionTypes.GET_LEADS: {
      dispatch(showLoader(V3LeadActionTypes.GET_LEADS))
      dispatch(setSchemaCategory(V3LeadsCategory))
      dispatch(
        apiRequest({
          url: `${config.v3Api}/lead/query`,
          method: 'POST',
          feature: V3LeadActionTypes.GET_LEADS,
          body: v3LeadQueryRequestConfigSelector(getState()),
        })
      )
      break
    }

    // GET_LEAD retrieves a single lead by id from the v3 api
    case V3LeadActionTypes.GET_LEAD: {
      // It is possible to trigger GET_LEAD from a payload that is either a QueriedLead or Lead object type,
      // which use different capitalization for the ID/id field.
      let payloadID: string = action?.payload?.id
      if (!payloadID || payloadID === '') {
        payloadID = action?.payload?.ID
      }
      dispatch(showLoader(V3LeadActionTypes.GET_LEAD))
      dispatch(
        apiRequest({
          url: `${config.v3Api}/lead/${payloadID}?_related=Organization&_preload=Activities,Notes`,
          method: 'GET',
          feature: V3LeadActionTypes.GET_LEAD,
        })
      )
      break
    }

    case V3LeadActionTypes.SAVE_LEAD: {
      dispatch(showLoader(V3LeadActionTypes.SAVE_LEAD))
      const { payload } = action as SaveV3LeadAction

      const diff = selectPartialV3LeadForPatch(getState())

      dispatch(hideLoader(V3LeadActionTypes.SAVE_LEAD))

      // AB#12017 - SM 6/30/2023
      //
      // The scope of a user's filters and the PATCH request can become out of sync.
      // This scope is only saved in redux storage,
      // so any reloading of the page resets it to the default of `includeDescendants`.
      //
      // If a user does this while editing a Lead in their memberships but NOT under their focused org,
      // the Lead will not be found by the platform, because the As-Owner header will not match the Lead Owner.
      //
      // This is due to incorrect filter handling elsewhere in the app, but breaks the most here.
      // Therefore, it is assumed that if you can see the lead, you can update it, and the ownerID for the request is
      // manually overridden.
      //
      // This can likely be removed once the rest of the filter handling is fixed.

      dispatch(
        apiRequest({
          url: `${config.v3Api}/lead/${payload.ID}`,
          method: 'PATCH',
          body: diff,
          feature: V3LeadActionTypes.SAVE_LEAD,
          asOwnerOverride: payload.OwnerID,
        })
      )
      break
    }

    // handle GET_LEADS (multiple) api success
    case V3LeadActionTypes.GET_LEADS_SUCCESS: {
      const { data, total } = action.payload
      const leads: Lead[] = marshalQueriedLeadsToLeads(data)
      const state = getState()
      const schema = getV3LeadsMockSchema(
        i18n(state),
        selectLeadStatusEnums(state, false),
        selectLeadTypeEnums(state),
        selectLeadStageEnums(state),
        selectFilterOptionStringsByKey(state, 'Stage', false),
        selectLeadStatusToStageSetting(state)
      )
      dispatch(setSchema(schema))
      dispatch(setV3Leads(leads))
      dispatch(setDocuments({ totalCount: total } as V2Response))
      dispatch(hideLoader(V3LeadActionTypes.GET_LEADS))
      break
    }

    // handle GET_LEAD (single) api success
    case V3LeadActionTypes.GET_LEAD_SUCCESS: {
      dispatch(setV3Lead(action.payload))
      const state = getState()
      const schema = getV3LeadsMockSchema(
        i18n(state),
        selectLeadStatusEnums(state, false),
        selectLeadTypeEnums(state),
        selectLeadStageEnums(state),
        selectFilterOptionStringsByKey(state, 'Stage', false),
        selectLeadStatusToStageSetting(state)
      )
      dispatch(setSchema(schema))
      // The name is mocked as a document field for the AppBar title
      dispatch(
        setDocument({
          name: getLeadName(action.payload),
        })
      )
      dispatch(hideLoader(V3LeadActionTypes.GET_LEAD))
      break
    }

    case V3LeadActionTypes.SAVE_LEAD_SUCCESS: {
      dispatch(showLoader(V3LeadActionTypes.RELOAD_LEAD))
      // Workaround for AB#14128 - we need to force a reload of the lead state in order to reflect
      // changes like the Stage field, and the DetailView component expects the additional information
      // appended in the getV3Lead query, so it is easiest to just query for it again.
      if (action && action.payload && (action.payload.ID || action.payload.id)) {
        dispatch(reloadV3Lead(action.payload.ID))
      }
      dispatch(hideLoader(V3LeadActionTypes.SAVE_LEAD))
      break
    }

    case V3LeadActionTypes.RELOAD_LEAD: {
      dispatch(showLoader(V3LeadActionTypes.RELOAD_LEAD))
      dispatch(
        apiRequest({
          url: `${config.v3Api}/lead/${action.payload}?_related=Organization&_preload=Activities,Notes`,
          method: 'GET',
          feature: V3LeadActionTypes.RELOAD_LEAD,
        })
      )
      break
    }

    case V3LeadActionTypes.RELOAD_LEAD_SUCCESS: {
      dispatch(setV3Lead(action.payload))
      dispatch(hideLoader(V3LeadActionTypes.RELOAD_LEAD))
      break
    }

    case `${V3LeadActionTypes.GET_LEADS} ${API_ERROR}`: {
      dispatch(hideLoader(V3LeadsCategory))
      break
    }

    case V3LeadActionTypes.GET_LEAD_ERROR: {
      dispatch(hideLoader(V3LeadsCategory))
      break
    }

    case V3LeadActionTypes.GET_CONTENT_CONFIGS: {
      const { trunkID } = action as GetContentConfigsAction
      showLoader(V3LeadActionTypes.GET_CONTENT_CONFIGS)
      dispatch(
        apiRequest({
          url: `${config.v3Api}/organization/${trunkID}/contentconfig`,
          method: 'GET',
          feature: V3LeadActionTypes.GET_CONTENT_CONFIGS,
        })
      )
      break
    }

    case V3LeadActionTypes.GET_CONTENT_CONFIGS_SUCCESS: {
      const { payload } = action as GetContentConfigsSuccessAction
      if (!payload.fields || payload.fields.length === 0) {
        break
      }

      dispatch(setV3ContentConfigs(payload.fields))
      hideLoader(V3LeadActionTypes.GET_CONTENT_CONFIGS)
      if (readyForV3Filters(getState())) {
        dispatch(loadLeadsList())
      }
      break
    }

    case V3LeadActionTypes.GET_LEAD_TYPES: {
      showLoader(V3LeadActionTypes.GET_LEAD_TYPES)
      dispatch(
        apiRequest({
          url: `${config.v3Api}/lead/type`,
          method: 'GET',
          feature: V3LeadActionTypes.GET_LEAD_TYPES,
        })
      )
      break
    }

    case V3LeadActionTypes.GET_LEAD_TYPES_SUCCESS: {
      const { payload } = action as GetLeadTypesSuccessAction
      dispatch(setV3LeadTypes(payload || []))
      showLoader(V3LeadActionTypes.GET_LEAD_TYPES)
      if (readyForV3Filters(getState())) {
        dispatch(loadLeadsList())
      }
      break
    }

    case V3LeadActionTypes.EXPORT_LEADS: {
      dispatch(showLoader(V3LeadActionTypes.EXPORT_LEADS))
      dispatch(
        apiRequest({
          url: `${config.v3Api}/lead/export/csv`,
          method: 'POST',
          responseType: 'blob',
          feature: action.type,
          body: v3LeadQueryRequestConfigSelector(getState()),
        })
      )
      break
    }

    case V3LeadActionTypes.EXPORT_LEADS_SUCCESS: {
      const blob = new Blob([action.payload], { type: 'text/csv;charset=utf-8' })
      saveAs(blob, `leads-${new Date().getTime()}.csv`)
    }
  }
}
