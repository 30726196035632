import { AnyAction } from 'redux'

export const MENU = '[menu]'
export const SET_MENU = `${MENU} Set`

export type MenuItem = {
  key: string
  label: string
  type?: string
  isAllowed: boolean
  items?: ItemChild[]
  category: string
  presentation: string
  weblink: string
  sortOrder?: number
}

export type ItemChild = Omit<MenuItem, 'items'>

export type Menu = MenuItem[]

export const setMenu = (menu: Menu): AnyAction => ({
  type: SET_MENU,
  payload: menu,
})
