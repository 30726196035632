import { saveAs } from 'file-saver'
import { AnyTranslationsAction, apiRequest, ApiRequestAction, GenerateReportAction, TranslationsActionType } from '../actions'
import { i18nMap } from '../reducers/i18n'
import { Middleware } from '../store'
import { importLanguageJSON } from './i18n'
import { selectAvailableLocales } from '../selectors'

type HandleActions = AnyTranslationsAction
type DispatchActions = GenerateReportAction | ApiRequestAction

type GenerateReportPayload = {
  [key: string]: i18nMap
}

// getReportPayload imports all languages defined in availableLocales
const getReportPayload = async (files: string[]): Promise<GenerateReportPayload> => {
  return files.reduce(async (promise, localeCode) => {
    const payload = await promise

    try {
      payload[localeCode] = await importLanguageJSON(`${localeCode}.json`)
    } catch (err) {}

    return Promise.resolve(payload)
  }, Promise.resolve({} as GenerateReportPayload))
}

export const translationsMiddleware: Middleware<HandleActions, DispatchActions> = ({ dispatch, getState }) => (next) => (action) => {
  next(action)
  switch (action.type) {
    case TranslationsActionType.GENERATE_UI_REPORT:
      const files = selectAvailableLocales(getState()).map((language) => language.value)
      getReportPayload(files).then((payload) => {
        dispatch(
          apiRequest({
            url: `/translations/report`,
            method: 'POST',
            body: payload,
            feature: action.type,
            responseType: 'text',
          })
        )
      })

      break

    case TranslationsActionType.GENERATE_SCHEMA_REPORT:
      dispatch(
        apiRequest({
          url: `/translations/schemas`,
          method: 'GET',
          feature: action.type,
        })
      )
      break

    case TranslationsActionType.GENERATE_ERROR_REPORT:
      dispatch(
        apiRequest({
          url: `/translations/errors`,
          method: 'GET',
          feature: action.type,
        })
      )
      break

    case TranslationsActionType.GENERATE_SCHEMA_REPORT_ERROR:
    case TranslationsActionType.GENERATE_UI_REPORT_ERROR:
    case TranslationsActionType.GENERATE_ERROR_REPORT_ERROR:
      console.warn('unable to generate report', action.payload)
      break

    case TranslationsActionType.GENERATE_SCHEMA_REPORT_SUCCESS:
    case TranslationsActionType.GENERATE_UI_REPORT_SUCCESS:
    case TranslationsActionType.GENERATE_ERROR_REPORT_SUCCESS: {
      const blob = new Blob([action.payload], { type: 'text/csv;charset=utf-8' })
      let filenamePrefix
      switch (action?.meta?.feature) {
        case TranslationsActionType.GENERATE_UI_REPORT:
          filenamePrefix = 'cc'
          break
        case TranslationsActionType.GENERATE_ERROR_REPORT:
          filenamePrefix = 'api-error'
          break
        default:
          filenamePrefix = 'schema'
      }
      saveAs(blob, `${filenamePrefix}-translations-required.csv`)
      break
    }
  }
}
