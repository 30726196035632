import { Option } from 'types'
import { Field } from '../schemas'

// Types for filters when stored in local storage
export type StoredFilters = Record<string, StoredFiltersForOrg> // keyed to Organization ID
export type StoredFiltersForOrg = Record<string, StoredFiltersForResource> // keyed to resource for filter
export type StoredFiltersForResource = Record<string, Option[]> // Keyed to FilterField.key

type baseFilterField = {
  key: string
  label: string
  singleSelect: boolean
  // defaultSelected is set when the filters are initially loaded, and used when comparing if
  // a filter selection is "dirty" and should be overridden.
  defaultSelected: Option[]
  selected: Option[]
}

export type SelectFilterField = baseFilterField & {
  presentation: 'select'
  options: Option[]
}

export const isSelectFilterField = (field: any): field is SelectFilterField => {
  return field.presentation !== undefined && field.presentation === 'select'
}

export type DateFilterField = baseFilterField & {
  presentation: 'dateRange'
}

export const isDateFilterField = (field: any): field is DateFilterField => {
  return field.presentation !== undefined && field.presentation === 'dateRange'
}

export type FilterField = SelectFilterField | DateFilterField

// Filters as returned from the /filters endpoint, as as mocked for v3 Leads.
export type APIFilter = {
  fieldPath: FilterFieldKeys[]
  field: Field
}

export const validFilterFieldKeys = [
  // V2 Lead-specific
  'originFormID',
  'stage',
  'isTestLead',
  // V3 Lead-specific
  // TODO: come onnnnnn these should have been lowercased so we don't need different keys. Make them lowercase
  'TypeLabel',
  'Stage',
  'Status',
  'IsTestLead',
  'CreatedAt',
  'CreatedAtGreaterThan',
  'CreatedAtLessThan',
  // Org / Location-specific
  'isTestOrg',
  'isPrimary',
  // Sites-specific
  'category',
  // Content / General keys
  'type',
  'status',
  'isFeatured',
  'created',
  'locale',
  'audit.createdLessThan',
  'audit.createdGreaterThan',
  // The "global" scope is stored in filters strictly for usage on the list pages.
  'scope',
] as const

export type FilterFieldKeys = typeof validFilterFieldKeys[number]
